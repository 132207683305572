import { createSlice } from '@reduxjs/toolkit';

import { getEquipmentTypesDropdown } from './api';

const equipmentTypesDropdownList = {
  isLoading: true,
  error: false,
  data: false,
};

const equipmentTypesSlice = createSlice({
  name: 'equipmentTypes',
  initialState: {
    equipmentTypesDropdownList,
  },
  reducers: {
    resetEquipmentTypesDropdownList(state) {
      state.equipmentTypesDropdownList = { ...equipmentTypesDropdownList };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEquipmentTypesDropdown.pending, (state) => {
        state.equipmentTypesDropdownList.isLoading = true;
      })
      .addCase(getEquipmentTypesDropdown.fulfilled, (state, action) => {
        state.equipmentTypesDropdownList.isLoading = false;
        state.equipmentTypesDropdownList.data = action.payload;
      })
      .addCase(getEquipmentTypesDropdown.rejected, (state, action) => {
        state.equipmentTypesDropdownList.isLoading = false;
        state.equipmentTypesDropdownList.error = action.error.message;
      });
  },
});

export const { resetEquipmentTypesDropdownList } = equipmentTypesSlice.actions;

export default equipmentTypesSlice.reducer;
