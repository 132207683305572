import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce, isEmpty } from 'lodash';

import { exportToCSV, validateAddress } from '../../../constants/common';
import { TAB_STATUS } from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { getCategoryDropdownList } from '../../../store/category/api';
import { snackbarToggle } from '../../../store/CommonReducer';
import { resetDropdown } from '../../../store/company/reducer';
import { getServiceAreasDropdownList } from '../../../store/serviceAreas/api';
import { getSkillsDropdownList } from '../../../store/skills/api';
import {
  createTechnician,
  getTechnicianList,
  updateTechnician,
} from '../../../store/technician/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../../store/technician/reducer';
import { getZonesDropdownList } from '../../../store/zones/api';
import { formattedDate } from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import TabView from '../../CommonComponents/TabView';
import TechnicianForm from './TechnicianForm';

const defaultValues = {
  technician_name: '',
  technician_email: '',
  primary_contact_phone: '',
  categories: '',
};

const defaultFilters = {
  category: null,
  tags: null,
  skills: null,
  zones: null,
};

const listColumnNames = {
  technician_name: 'technician_name',
  technician_email: 'technician_email',
  primary_contact_phone: 'primary_contact_phone',
  categories: 'categories',
  created_at: 'created_at',
  edit: 'edit',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const Technician = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formMode, setFormMode] = useState('create');
  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
  } = useForm();

  const { technicianActiveList, technicianInactiveList, total, totalCount } =
    useSelector((state) => state.technician.get);

  const {
    isLoading: createLoading,
    createdTechnician,
    error,
  } = useSelector((state) => state.technician.create);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [activeTab, setActiveTab] = useState(0);
  const [technicianId, setTechnicianId] = useState('');

  const [filters, setFilters] = useState(defaultFilters);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRows, setSelectedRows] = useState(null);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const { editTechnician } = useSelector((state) => state.technician.edit);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const { categoryDropdownLoading, categoryDropdownData } = getDropdownListHook(
    {
      reducerName: 'category',
      dropdownListName: 'categoryDropdownList',
      labelName: 'display_name',
      valueName: 'name',
    }
  );

  const { skillsDropdownLoading, skillsDropdownData } = getDropdownListHook({
    reducerName: 'skills',
    dropdownListName: 'skillsDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const { serviceAreasDropdownLoading, serviceAreasDropdownData } =
    getDropdownListHook({
      reducerName: 'serviceAreas',
      dropdownListName: 'serviceAreasDropdownList',
      labelName: 'display_name',
      valueName: 'name',
    });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'technician_name',
        headerName: t('attributes.technician.technician_name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'technician_email',
        headerName: t('attributes.email'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'primary_contact_phone',
        headerName: t('attributes.technician.contact_phone'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'categories',
        headerName: t('attributes.technician.category'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.technician.created_at'),
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setTechnicianId(row.id);
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllTechnician = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getTechnicianList({
        active: activeTab === 0 ? TAB_STATUS.active : TAB_STATUS.inactive,
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        category: filters.category?.value,
        skills: filters.skills?.value,
        zones: filters.zones?.value,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    const defaultColumnVisibilityModel = listColumnNames;

    columns.forEach((column) => {
      defaultColumnVisibilityModel[column.field] = true;
    });
    setColumnVisibilityModel(defaultColumnVisibilityModel);
  }, [activeTab]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllTechnician, 500), [
    getAllTechnician,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdTechnician) {
          if (!technicianId) setActiveTab(0);
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !technicianId
                ? t('message.technician.createdSuccessfully')
                : t('message.technician.updatedSuccessfully'),
            })
          );
          setTechnicianId('');
        }
      }
    }
  }, [error, createLoading]);

  useEffect(() => {
    dispatch(getCategoryDropdownList());
    dispatch(getSkillsDropdownList());
    dispatch(getZonesDropdownList());
    dispatch(getServiceAreasDropdownList());
  }, []);

  const rows =
    activeTab === 0
      ? technicianActiveList?.map((item) => {
          const updatedItem = {
            id: item.id,
            technician_name: item.technician_name,
            technician_email: item.technician_email,
            categories: item.categories?.length
              ? item.categories
                  .map((category) => {
                    const matchingCategory = categoryDropdownData.find(
                      (cat) => cat.value === category
                    );

                    return matchingCategory ? matchingCategory.label : '';
                  })
                  .filter(Boolean)
              : '',
            primary_contact_phone: item.primary_contact_phone,
            created_at: item.created_at,
          };

          return updatedItem;
        })
      : technicianInactiveList?.map((item) => {
          const updatedItem = {
            id: item.id,
            technician_name: item.technician_name,
            technician_email: item.technician_email,
            categories: item.categories?.length
              ? item.categories.join(', ')
              : '',
            primary_contact_phone: item.primary_contact_phone,
            created_at: item.created_at,
          };

          return updatedItem;
        });

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.postCode,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.technician.contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postCode',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });

    if (hasError) return;

    const isDocUploaded = data?.otherDocument;
    let docs = [];

    if (!isEmpty(isDocUploaded)) {
      docs.push(isDocUploaded);
    }
    if (editTechnician?.documents?.length > 0) {
      const oldDocId = editTechnician?.documents?.document_uuid;

      if (oldDocId !== isDocUploaded?.document_uuid || isEmpty(isDocUploaded)) {
        docs.push({ ...editTechnician?.documents[0], is_deleted: true });
      }
    }
    const request = {
      technician_name: data.primaryContact,
      ...(!technicianId ? { technician_email: data.technician_email } : ''),
      categories: [data.category?.value || ''],
      skills: data.skill?.map((item) => item?.value),
      service_areas: data.serviceArea?.map((item) => item?.value),
      zones: data.zones?.map((item) => item?.value),
      notes: data.notes,
      primary_contact_details: {
        technician_name: data.primaryContact,
        contact_email: data.technician_email,
        contact_phone: data.contactPhone,
        address: data.address,
        state: data.state?.value,
        city: data.city?.value,
        postcode: data.postCode,
      },
      documents: docs,
      status: technicianId
        ? data.active
          ? TAB_STATUS.active
          : TAB_STATUS.inactive
        : TAB_STATUS.active,
    };

    if (!technicianId) {
      dispatch(createTechnician(request));
    } else {
      dispatch(updateTechnician({ id: data?.id, req: request }));
    }
  };

  const resetFilter = () => {
    setFilters({ category: null, skills: null, zones: null });
    setSearchText('');
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ category: null, skills: null, zones: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<EngineeringOutlinedIcon />}
      title={t('attributes.technician.technicians')}
      singularText={t('attributes.technician.technician')}
      openForm={() => {
        setOpen(true);
        setFormMode('create');
        reset(defaultValues);
      }}
      createBtnText={t('attributes.technician.add_technician')}
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onResetFilter={resetFilter}
        // After API impl need to include debounce search
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.category ||
          filters.skills ||
          filters.tags ||
          filters.zones
        }
        isActive={isFilterComponentVisible}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Category"
            options={categoryDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, category: newVal }))
            }
            value={filters?.category}
            isLoadingData={categoryDropdownLoading}
            width="190px"
          />
          <Autocomplete
            placeholder="Skills"
            options={skillsDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, skills: newVal }))
            }
            isLoadingData={skillsDropdownLoading}
            value={filters?.skills}
            width="190px"
          />
          <Autocomplete
            placeholder="Zones"
            options={zonesDropdownData}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, zones: newVal }))
            }
            value={filters?.zones}
            isLoadingData={zonesDropdownLoading}
            width="190px"
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        // Later just do what has been done in technician for the same
      />
    </>
  );

  const TechnicianWrapper = (
    <StyledMainWrapper
      title={t('attributes.technician.technicians')}
      btn={
        <>
          <CustomButton
            text={t('attributes.technician.add_technician')}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            color="secondary"
            sx={{ height: '52%' }}
            onClick={() => {
              setOpen(true);
              setFormMode('create');
              reset(defaultValues);
            }}
          />

          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.TECHNICIAN,
                  EXPORT_FILE_NAMES.TECHNICIAN
                );
              }
            }}
          />
        </>
      }
    >
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <TabView
            tabs={[
              { label: 'Active', component: renderedComponent },
              {
                label: 'Inactive',
                component: renderedComponent,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={onTabChange}
          />
        )
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setTechnicianId('');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {TechnicianWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={!technicianId ? 'Add Technician' : 'Edit Technician'}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpen(false) && setTechnicianId('');
          }}
          footerButton={
            <CustomButton
              text={createLoading ? 'Loading...' : 'Save'}
              disabled={createLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <TechnicianForm
            sethasFormValues={sethasFormValues}
            clearErrors={clearErrors}
            id={technicianId}
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            categoryList={categoryDropdownData}
            isCategoryListLoading={categoryDropdownLoading}
            zonesList={zonesDropdownData}
            isZonesListLoading={zonesDropdownLoading}
            skillsList={skillsDropdownData}
            isSkillsListLoading={skillsDropdownLoading}
            serviceAreaList={serviceAreasDropdownData}
            isServiceListLoading={serviceAreasDropdownLoading}
            watch={watch}
            trigger={trigger}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Technician;
