import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';

import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';

const CustomPaper = styled(Paper)(() => ({
  '&.MuiPaper-root': {
    boxShadow: 'none',
    backgroundColor: 'var(--light-grey) !important',
  },
}));

const CustomTableContainer = styled(TableContainer)(() => ({
  '&.MuiTableContainer-root': {
    marginTop: '16px',
    boxShadow: 'none',
    backgroundColor: 'var(--light-grey) !important',
  },
}));

const CustomTableCell = styled(TableCell)(({ keepSticky }) => ({
  '&.MuiTableCell-root': {
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--light-grey) !important',
    ...(keepSticky && {
      position: 'sticky',
      left: 0,
      /* Keeps the first column above other content */
      zIndex: 2,
      borderRight: '1px solid var(--border-color)',
      overflowX: 'hidden',
      minWidth: 350,
    }),
  },
}));

const CustomTableHead = styled(TableHead)(() => ({
  '&.MuiTableHead-root': {
    border: '1px solid var(--border-color)',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    backgroundColor: 'var(--background-color)',
    borderBottom: '1px solid var(--border-color)',
  },
}));

const CustomCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': { fontSize: '28px !important' },
}));

const CustomTextField = styled(TextField)(() => ({
  width: '3.5rem',
  borderRadius: '4px !important',
  '& .MuiOutlinedInput-input': {
    height: '30px', // Adjust height here
    padding: '0 0 0 2px',
    textAlign: 'center',
  },
  '& .Mui-disabled': {
    borderRadius: '4px !important',
    border: '1px solid var(--dark-grey)',
    background: 'var(--devider-color)',
  },
}));

/**
 * Takes initial matrix data and input data and returns the updated matrix data
 * @param {Array} initialMatrixData - The initial matrix data
 * @param {Array} inputData - The input data from the server
 * @returns {Array} The updated matrix data
 */
const getUpdatedMatrixData = (initialMatrixData, inputData = []) => {
  if (!initialMatrixData || initialMatrixData?.length === 0) return [];
  const updatedData = initialMatrixData.map((item) => {
    // Loop through each frequency entry in the current item
    Object.keys(item).forEach((key) => {
      if (key !== 'name') {
        // Skip the name key, we only want the frequencies
        const frequency = item[key];

        // Find the corresponding entry in inputData
        const match = inputData.find(
          (data) =>
            data.routine_id === frequency.routineId &&
            data.frequency_id === frequency.frequencyId
        );

        if (match) {
          // Update isChecked and amount if match is found
          frequency.isChecked = true;
          frequency.amount = parseFloat(match.price);
        } else {
          // Set isChecked to false if no match is found
          frequency.isChecked = false;
        }
      }
    });

    return item;
  });

  return updatedData;
};

const BillingContactMatrix = ({
  selectedStandard,
  matrixDataSet,
  setMatrixDataSet,
  isEdit,
}) => {
  const { t } = useTranslation();
  // Selector for frequency list
  const { data: frequencyList } = useSelector(
    (state) => state.frequency.frequencyList
  );

  // Selector for routine standard frequency list
  const { data: routineList, isLoading: routineLoading } = useSelector(
    (state) => state.routine.routineList
  );

  // Selector for billing contract by its ID
  const { data: getBillingContractByIdData } = useSelector(
    (state) => state.billingContract.getBillingContractById
  );

  // Prepare initial matrix data start
  const initialMatrixDataSet = useMemo(() => {
    if (!routineList?.data) return [];

    return routineList.data.reduce((acc, item) => {
      frequencyList?.data?.forEach(({ id, display_name }) => {
        const frequencyData = {
          routineId: item.id,
          amount: 10,
          isChecked: false,
          frequencyId: id,
          frequencyLabel: display_name,
          name: item.name,
        };

        const existing = acc.find((obj) => obj.name === item.display_name);

        if (existing) {
          existing[id] = frequencyData;
        } else {
          acc.push({
            name: item.display_name,
            [id]: frequencyData,
          });
        }
      });

      return acc;
    }, []);
  }, [routineList]);

  useEffect(() => {
    if (isEdit) {
      setMatrixDataSet(
        getUpdatedMatrixData(
          initialMatrixDataSet,
          getBillingContractByIdData?.details
        )
      );
    } else {
      setMatrixDataSet(initialMatrixDataSet);
    }
  }, [initialMatrixDataSet, getBillingContractByIdData]);
  // Prepare initial matrix data end

  // Update matrix data start
  const updateMatrixData = (matrixIndex, id, updatedData) => {
    setMatrixDataSet((prevMatrix) =>
      prevMatrix.map((obj, index) =>
        index === matrixIndex
          ? { ...obj, [id]: { ...obj[id], ...updatedData } }
          : obj
      )
    );
  };

  const debouncedUpdateMatrixData = useRef(
    debounce((matrixIndex, id, value) => {
      updateMatrixData(matrixIndex, id, {
        amount: parseFloat(value),
      });
    }, 100) // Adjust debounce time as necessary
  ).current;

  useEffect(
    () => () => {
      debouncedUpdateMatrixData.cancel();
    },
    []
  );
  // Update matrix data end

  // Render Table Head Start
  const renderTableHead = () => (
    <TableRow>
      <CustomTableCell
        rowSpan={2}
        align="center"
        sx={{ zIndex: '3 !important' }}
        keepSticky={true}
      >
        {selectedStandard?.codeName}
      </CustomTableCell>
      {frequencyList?.data?.map(({ uuid, name }) => (
        <CustomTableCell align="center" colSpan={2} key={uuid}>
          {name}
        </CustomTableCell>
      ))}
    </TableRow>
  );
  // Render Table Head End

  // Render Sub Head Start
  const renderSubHead = () => (
    <TableRow>
      {frequencyList?.data?.map(({ uuid }) => (
        <React.Fragment key={uuid}>
          <CustomTableCell align="center" style={{ minWidth: 50 }}>
            Yes/No
          </CustomTableCell>
          <CustomTableCell align="center" style={{ minWidth: 50 }}>
            Amount
          </CustomTableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
  // Render Sub Head End

  // Render Matrix Rows Start
  const renderMatrixRows = () =>
    matrixDataSet?.map(({ name }, matrixIndex) => (
      <TableRow hover role="checkbox" tabIndex={-1} key={matrixIndex}>
        <CustomTableCell keepSticky={true}>{name}</CustomTableCell>
        {frequencyList?.data?.map(({ id, uuid }) => {
          const cellData = matrixDataSet[matrixIndex][id];

          return (
            <React.Fragment key={uuid}>
              <CustomTableCell align="center">
                <CustomCheckbox
                  name={`${name}-checkbox`}
                  checked={cellData?.isChecked || false}
                  onClick={(e) =>
                    updateMatrixData(matrixIndex, id, {
                      isChecked: e.target.checked,
                    })
                  }
                  disabled={
                    !cellData ||
                    (isEdit && !getBillingContractByIdData?.is_active)
                  }
                />
              </CustomTableCell>
              <CustomTableCell align="center">
                <CustomTextField
                  type={`${!cellData || !cellData?.isChecked ? 'text' : 'number'}`}
                  name={`${name}-amount`}
                  value={
                    !cellData || !cellData?.isChecked
                      ? '-'
                      : cellData?.amount || 0
                  }
                  onChange={(e) =>
                    updateMatrixData(matrixIndex, id, {
                      amount: parseFloat(e.target.value),
                    })
                  }
                  disabled={
                    !cellData ||
                    !cellData?.isChecked ||
                    (isEdit && !getBillingContractByIdData?.is_active)
                  }
                  inputProps={{ min: 1 }}
                />
              </CustomTableCell>
            </React.Fragment>
          );
        })}
      </TableRow>
    ));
  // Render Matrix Rows End

  if (routineLoading || !matrixDataSet) {
    return (
      <Box
        sx={{
          width: '100%',
          mt: 2,
          minHeight: 'calc(100vh - 490px)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CustomCircularLoader />
      </Box>
    );
  } else if (
    !routineLoading &&
    frequencyList?.data !== false &&
    frequencyList?.total === 0 &&
    matrixDataSet?.length === 0
  ) {
    return (
      <NewNoDataPage
        customText={t('attributes.billingContract.noFrequencyForCompany')}
        filterHeight={490}
      />
    );
  }

  return (
    <CustomTableContainer
      component={CustomPaper}
      sx={{
        maxWidth: '100%',
        maxHeight: `calc(100vh - 490px)`,
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <CustomTableHead>
          {renderTableHead()}
          {renderSubHead()}
        </CustomTableHead>
        <TableBody>{renderMatrixRows()}</TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default BillingContactMatrix;
