import React from 'react';

import { Box } from '@mui/material';

import { ComingSoonImage } from '../../assets/images';

const ComingSoon = ({ height = '100%' }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height,
    }}
  >
    <img
      src={ComingSoonImage}
      alt="Coming Soon"
      style={{ width: '90px', height: '90px' }}
    />
  </Box>
);

export default ComingSoon;
