import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, Divider, styled, Typography } from '@mui/material';

import { validateAddress } from '../../constants/common';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import { getBill } from '../../store/billing/api';
import { resetBillingCreate } from '../../store/billing/reducer';
import { getPropertyCustomerList } from '../../store/property/api';
import { clearTextfields } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const BillingForm = ({
  id,
  control,
  watch,
  trigger,
  setValue,
  clearErrors,
  setServerErrors,
  reset,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  const [searchAddress, setSearchAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('post_code');

  const billingAddressLabels = (label) => t(`attributes.technician.${label}`);
  const postCodeLength = 4;

  const clearHandler = (name) => setValue(name, '');

  const cityList = getCityByStateHook(state?.value);
  const stateList = getStatesHook();

  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const typedValue = watch('searchAddress');

  useAutocompleteAddress(searchAddress);

  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      addressKey: 'address',
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'post_code',
    },
  });

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getBill({ uuid: id }))
        .then((res) => {
          const data = res?.payload?.data?.[0];

          reset({
            customer: {
              label: data?.customer_name,
              value: data?.customer_uuid,
            },
            billing_name: data?.billing_name,
            abn_number: data?.abn_number,
            contact_name: data?.contact_name,
            email: data?.email,
            phone: data?.phone,
            address: data?.address,
            city: { label: data?.city, value: data?.city },
            state: { label: data?.state, value: data?.state },
            post_code: data?.postcode,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getPropertyCustomerList());

    return () => reset();
  }, []);

  useEffect(
    () => () => {
      dispatch(resetBillingCreate());
    },
    []
  );

  useEffect(() => {
    validateAddress({
      address: address,
      city: city,
      state: state,
      postcode: postcode,
      fieldName: {
        addressKey: 'address',
        addressValue: billingAddressLabels('contact_address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'post_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
      clearErrors,
    });
  }, [addressBlur, stateBlur, cityBlur, postcodeBlur]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <FormWrapper>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.billingCard.accountDetails')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="customer"
              control={control}
              rules={{
                required: `${t('attributes.customer.customer')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.customer.customer')}
                  options={customerDropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    clearErrors('customer');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  isLoadingData={customerDropdownLoading}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />
            <Controller
              name="billing_name"
              control={control}
              rules={{
                required: `${t('attributes.billingCard.billName')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.billName')}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('billing_name');
                    clearErrors('billing_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Controller
              name="abn_number"
              control={control}
              rules={{
                validate: (value) => {
                  if (value) {
                    return value.length == 11;
                  }
                  return true;
                },
                pattern: {
                  value: /^\d{11}$/,
                  message: Validation.general.abnNumber,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.abn')}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('abn_number');
                    clearErrors('abn_number');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">
              {t('attributes.billingCard.contactDetails')}
            </Typography>
          </Box>
          <Divider />
          <FormFieldWrapper>
            <Controller
              name="contact_name"
              control={control}
              rules={{
                required: `${t('attributes.supplier.contact_name')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.supplier.contact_name')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('contact_name');
                    clearErrors('contact_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{
                required: `${t('attributes.billingCard.contact_email')} ${Validation.general.required}`,
                maxLength: {
                  value: 254,
                  message: Validation.general.email,
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: Validation.general.validEmail,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.billingCard.contact_email')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('email');
                    clearErrors('email');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                required: `${t('attributes.supplier.contact_phone')} ${Validation.general.required}`,
                pattern: {
                  value: /^\d{10}$/,
                  message: Validation.general.contactPhone,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.supplier.contact_phone')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, '');

                    onChange(numericValue);
                    trigger('phone');
                    clearErrors('phone');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                />
              )}
            />
            <Controller
              name="searchAddress"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.searchAddress')}
                  options={autocompleteAddressData || []}
                  value={value}
                  isRequired={false}
                  isLoadingData={isAutocompleteAddressLoading}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    setSearchAddress(e.target?.value);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{
                required: `${billingAddressLabels('contact_address')} ${Validation.general.required}`,
                maxLength: {
                  value: 300,
                  message: Validation.billingCard.address,
                },
              }}
              render={({
                field: { onChange, value, name, onBlur },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={billingAddressLabels('contact_address')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('address');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setAddressBlur}
                  onBlur={() => {
                    onChange(value.trim());
                    onBlur();
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
              <Controller
                name="state"
                control={control}
                rules={{
                  required: `${t('common.state')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.state')}
                    options={stateList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('state');
                      setValue('city', null);
                      clearErrors('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    setBlur={setStateBlur}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                rules={{
                  required: `${t('common.city')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('common.city')}
                    options={cityList}
                    value={value}
                    onChange={(e, newValue) => {
                      onChange(newValue);
                      trigger('city');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearTextfields(setValue, name)}
                    isRequired={true}
                    setBlur={setCityBlur}
                  />
                )}
              />
            </Box>
            <Controller
              name="post_code"
              control={control}
              rules={{
                required: `${t('attributes.postCode')} ${Validation.general.required}`,
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
                minLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
                maxLength: {
                  value: postCodeLength,
                  message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.postCode')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('post_code');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={true}
                  setBlur={setPostcodeBlur}
                />
              )}
            />
          </FormFieldWrapper>
        </CustomCard>
      </FormWrapper>
    </>
  );
};

export default BillingForm;
