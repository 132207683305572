import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { DIVIDER_COLOR, PRIMARY, WARNING } from '../../constants/Colors';
import { filterRoles } from '../../constants/Constants';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
  TECHNICIAN,
} from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getAccountManagerDropdownList } from '../../store/accountManager/api';
import {
  getDocument,
  getMe,
  updateProfile,
  uploadDocument,
} from '../../store/CommonAPI';
import { getOfficeManagementById } from '../../store/officeManagement/api';
import { getOfficeStaffById } from '../../store/officeStaff/api';
import { getSuperAdminById } from '../../store/superAdmin/api';
import { getTechnicianById } from '../../store/technician/api';
import { getCustomerById } from '../../store/users/customer/api';
import { extractInitials, formatDate } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import MainWrapper from '../CommonComponents/MainWrapper';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import UploadedDocumentView from '../CommonComponents/UploadedDocumentView';

const CommonCard = ({ title, children }) => (
  <Box
    sx={{
      borderRadius: '6px',
      border: `1px solid ${DIVIDER_COLOR}`,
    }}
  >
    <Box sx={{ padding: '16px 16px 16px 32px', background: '#EBEBEB33' }}>
      <Typography variant="body1">{title}</Typography>
    </Box>
    <Divider />
    <Box sx={{ padding: '16px' }}>{children}</Box>
  </Box>
);

const KeyValueComponent = ({
  name,
  value,
  isChip = false,
  isArray = false,
}) => (
  <Box
    sx={{
      display: 'flex',
      columnGap: '8px',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    {name && (
      <Typography
        variant="body1"
        sx={{ minWidth: '120px' }}
      >{`${name}:`}</Typography>
    )}
    {isArray && Array.isArray(value) ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
        {value.map((item, index) => (
          <Chip
            key={index}
            label={
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {item}
              </Typography>
            }
          />
        ))}
      </Box>
    ) : isChip ? (
      <Chip
        label={
          <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
            {value}
          </Typography>
        }
      />
    ) : (
      <Typography variant="body2">{value}</Typography>
    )}
  </Box>
);

const Profile = () => {
  let managers = '-';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const params = useParams();

  const [profilePic, setProfilePic] = useState(null);
  const [userRole, setUserRole] = useState(getUserRole());
  const [userDetailLoading, setUserDetailLoading] = useState(false);
  const { profileDetails, isAuthLoading } = useSelector(
    (state) => state.common
  );
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    if (profileDetails) {
      setProfileData(profileDetails);
    }
  }, [profileDetails]);

  useEffect(() => {
    if (params?.uuid) {
      setUserDetailLoading(true);
      userDetails();
    }
  }, []);

  const userDetails = () => {
    const role = params?.role;

    setUserRole(role);
    if (role === SUPER_ADMIN) {
      dispatch(getSuperAdminById(params.uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === OFFICE_STAFF) {
      dispatch(getOfficeStaffById(params.uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === OFFICE_MANAGEMENT) {
      dispatch(getOfficeManagementById(params.uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === TECHNICIAN) {
      dispatch(getTechnicianById(params.uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
    if (role === CUSTOMER) {
      dispatch(getCustomerById(params.uuid)).then((res) => {
        setProfileData(res?.payload?.data?.[0]);
        setUserDetailLoading(false);
      });
    }
  };

  const { zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { skillsDropdownData } = getDropdownListHook({
    reducerName: 'skills',
    dropdownListName: 'skillsDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { serviceAreasDropdownData } = getDropdownListHook({
    reducerName: 'serviceAreas',
    dropdownListName: 'serviceAreasDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const mapDropdownValues = (selectedValues, dropdownData) =>
    selectedValues?.map((value) => {
      const match = dropdownData.find((item) => {
        if (item.value === value) {
          return item;
        }
      });

      return match ? match.label : value;
    });

  const mappedSkills = mapDropdownValues(
    profileData?.skills,
    skillsDropdownData
  );
  const mappedServiceAreas = mapDropdownValues(
    profileData?.service_areas,
    serviceAreasDropdownData
  );
  const mappedZones = mapDropdownValues(profileData?.zones, zonesDropdownData);

  const { accountManagerDropdownData } = getDropdownListHook({
    reducerName: 'accountManager',
    dropdownListName: 'accountManagerDropdownList',
    labelName: 'name',
    valueName: 'uuid',
  });

  if (profileData?.account_manager_uuid?.length) {
    const managerData = accountManagerDropdownData?.filter((d) =>
      profileData?.account_manager_uuid?.includes(d?.value)
    );

    managers = managerData?.map((name) => name?.label)?.join(',');
  }

  const roleBaseProfile = {
    [GLOBAL_ADMIN]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
    ],
    [SUPER_ADMIN]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address || '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || '-',
          },
        ],
      },
    ],
    [CUSTOMER]: [
      {
        title: t('common.personal_information'),
        column1: [
          {
            label: t('attributes.name'),
            value: profileData?.customer_name || '-',
          },
          {
            label: t('attributes.company.abnNumber'),
            value: profileData?.customer_abn_number || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.customer.categories'),
            value: profileData?.categories?.join(',') || '-',
          },
          {
            label: t('attributes.customer.agreementSignDate'),
            value: formatDate(profileData?.agreement_signed_date) || '-',
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.technician.company_name'),
            value: profileData?.primary_contact?.contact_name || '-',
          },
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.primary_contact?.contact_phone || '-',
          },
          {
            label: t('attributes.email'),
            value: profileData?.primary_contact?.contact_email || '-',
          },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address || '-',
          },
          {
            label: t('common.state'),
            value: profileData?.state || '-',
          },
        ],
        column2: [
          {
            label: t('common.city'),
            value: profileData?.city || '-',
          },
          {
            label: t('attributes.postCode'),
            value: profileData?.postcode || '-',
          },
        ],
      },
      {
        title: t('attributes.billingCard.billingInfoText'),
        column1: [
          {
            label: t('attributes.customer.organisation'),
            value: profileData?.billing?.company_name || '-',
          },
          {
            label: t('attributes.customer.billingContactName'),
            value: profileData?.billing?.billing_contact_name || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.customer.emailTo'),
            value: profileData?.billing?.email_to || '-',
          },
        ],
      },
      {
        title: t('common.quoting_info'),
        column1: [
          {
            label: t('attributes.customer.quotingContactName'),
            value: profileData?.quoting?.quoting_contact_name || '-',
          },
          {
            label: t('attributes.customer.emailTo'),
            value: profileData?.quoting?.email_to || '-',
          },
        ],
      },
      {
        title: t('common.account_manager_info'),
        column1: [
          {
            label: t('attributes.property.accountManager'),
            value: managers || '-',
          },
        ],
      },
    ],
    [OFFICE_STAFF]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address || '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || '-',
          },
        ],
      },
    ],
    [OFFICE_MANAGEMENT]: [
      {
        title: t('common.personal_information'),
        column1: [
          { label: t('attributes.name'), value: profileData?.name || '-' },
          {
            label: t('attributes.role'),
            value:
              profileData?.role_title ||
              (params?.role && filterRoles(userRole, t)) ||
              '-',
            isChip: true,
          },
        ],
      },
      {
        title: t('attributes.office_staff.contact_information'),
        column1: [
          {
            label: t('attributes.company.cell_phone'),
            value: profileData?.cell_phone || '-',
          },
          { label: t('attributes.email'), value: profileData?.email || '-' },
        ],
      },
      {
        title: t('common.address_information'),
        column1: [
          {
            label: t('attributes.address'),
            value: profileData?.address || '-',
          },
          { label: t('common.state'), value: profileData?.state || '-' },
        ],
        column2: [
          { label: t('common.city'), value: profileData?.city || '-' },
          {
            label: t('attributes.postCode'),
            value: profileData?.zip_code || profileData?.postcode || '-',
          },
        ],
      },
    ],
    [TECHNICIAN]: [
      {
        title: t('common.general_details'),
        column1: [
          {
            label: t('common.primary_contact'),
            value: profileData?.technician_name || '-',
          },
          {
            label: t('attributes.email'),
            value: profileData?.technician_email || '-',
          },
          {
            label: t('attributes.technician.company_name'),
            value: profileData?.company_name || '-',
          },
          {
            label: t('attributes.technician.category'),
            value: profileData?.categories?.join(', ') || '-',
          },
        ],
        column2: [
          {
            label: t('attributes.technician.skills'),
            value: mappedSkills || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.service_areas'),
            value: mappedServiceAreas || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.zones'),
            value: mappedZones || '-',
            isArray: true,
          },
          {
            label: t('attributes.technician.notes'),
            value: profileData?.notes || '-',
          },
        ],
      },
      {
        title: t('attributes.technician.contact_details'),
        column1: [
          {
            label: t('attributes.technician.contact_phone'),
            value: profileData?.primary_contact_details?.contact_phone || '-',
          },
          {
            label: t('attributes.address'),
            value: profileData?.primary_contact_details?.address || '-',
          },
        ],
        column2: [
          {
            label: t('common.state'),
            value: profileData?.primary_contact_details?.state || '-',
          },
          {
            label: t('common.city'),
            value: profileData?.primary_contact_details?.city || '-',
          },
          {
            label: t('attributes.postCode'),
            value: profileData?.primary_contact_details?.postcode || '-',
          },
        ],
      },
      {
        title: t('common.attach_documnet'),
        column1: [
          {
            value: profileData?.documents?.length
              ? profileData.documents.map((doc, index) => (
                  <UploadedDocumentView
                    key={index}
                    fileName={doc.document_name || 'Document'}
                    isUploadStatusRequired={false} // Hide upload status
                    onDelete={null} // Remove delete functionality
                  />
                ))
              : t('common.no_documents_found'),
          },
        ],
      },
    ],
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = (event) => {
    const file = event.target.files?.[0];

    setProfilePic(URL.createObjectURL(file));
    dispatch(
      uploadDocument({ document_type: 'other', uploaded_by: 1, file: file })
    ).then((res) => {
      dispatch(
        updateProfile({
          uuid: profileData?.uuid,
          value: res?.payload?.data?.[0]?.uuid,
        })
      ).then((res) => dispatch(getMe()));
    });
  };

  const deleteProfileHandler = () => {
    setProfilePic(null);
    dispatch(
      updateProfile({
        uuid: profileData?.uuid,
        value: null,
      })
    ).then((res) => dispatch(getMe()));
  };

  useEffect(() => {
    if (profileData?.profile_pic_document_uuid) {
      dispatch(
        getDocument({ doc_uuid: profileData?.profile_pic_document_uuid })
      ).then((res) => {
        setProfilePic(res?.payload?.data?.[0]?.presigned_url);
      });
    } else if (!profileData?.profile_pic_document_uuid) {
      setProfilePic(null);
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData?.role_name === CUSTOMER) {
      dispatch(getAccountManagerDropdownList());
    }
  }, []);

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.USERS_MANAGEMENT}`);
  };

  return (
    <StyledMainWrapper
      title={!params?.uuid ? t('common.profile') : ''}
      style={{ overflow: 'hidden', height: '100%' }}
    >
      {params?.uuid && (
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.usersManagement.title')}
          bgcolor={DIVIDER_COLOR}
          variant="body1"
          isStep={true}
          step={filterRoles(userRole, t)}
          handleDefaultStep={handleDefaultStep}
        />
      )}
      {roleBaseProfile?.[userRole]?.length ? (
        <Box
          sx={{
            width: '100%',
            p: '16px 16px 34px 16px',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              columnGap: '16px',
              alignItems: 'center',
            }}
          >
            {isAuthLoading || userDetailLoading ? (
              <Skeleton variant="circular" width={80} height={80} />
            ) : profilePic ? (
              <Avatar
                sx={{ width: '80px', height: '80px', bgcolor: PRIMARY }}
                src={profilePic}
              />
            ) : (
              <Avatar sx={{ width: '80px', height: '80px', bgcolor: PRIMARY }}>
                {extractInitials(
                  profileData?.name ||
                    profileData?.customer_name ||
                    profileData?.technician_name
                )}
              </Avatar>
            )}
            {!params?.uuid && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
              >
                <CustomButton
                  text={t('common.upload_photo')}
                  startIcon={<UploadFileOutlinedIcon />}
                  disabled={profilePic}
                  onClick={handleButtonClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  name="profile_pic"
                  onChange={(event) => uploadImage(event)}
                  accept="image/png, image/jpg, image/jpeg"
                  style={{ display: 'none' }}
                />
                <CustomButton
                  text={t('attributes.delete')}
                  sx={{
                    color: 'white',
                    backgroundColor: WARNING,
                    ':hover': {
                      backgroundColor: !profilePic ? DIVIDER_COLOR : WARNING,
                    },
                    width: 'fit-content',
                  }}
                  startIcon={<DeleteOutlineIcon />}
                  onClick={deleteProfileHandler}
                  disabled={!profilePic}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
            }}
          >
            {isAuthLoading || userDetailLoading ? (
              <Stack spacing={1}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{ borderRadius: '6px' }}
                >
                  <div style={{ paddingTop: '150px' }} />
                </Skeleton>
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: '150px' }} />
                </Skeleton>
                <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: '150px' }} />
                </Skeleton>
              </Stack>
            ) : (
              roleBaseProfile?.[userRole]?.map((profile) => (
                <CommonCard title={profile?.title}>
                  {profile?.column1 && profile?.column2 ? (
                    <Box sx={{ display: 'flex' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                          width: '50%',
                        }}
                      >
                        {profile?.column1?.map((c1) => (
                          <KeyValueComponent
                            name={c1?.label}
                            value={c1?.value}
                            isChip={c1?.isChip}
                            isArray={c1?.isArray}
                          />
                        ))}
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                          width: '50%',
                        }}
                      >
                        {profile?.column2?.map((c2) => (
                          <KeyValueComponent
                            name={c2?.label}
                            value={c2?.value}
                            isChip={c2?.isChip}
                            isArray={c2?.isArray}
                          />
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '16px',
                      }}
                    >
                      {profile?.column1?.map((c1) => (
                        <KeyValueComponent
                          name={c1?.label}
                          value={c1?.value}
                          isChip={c1?.isChip}
                          isArray={c1?.isArray}
                        />
                      ))}
                    </Box>
                  )}
                </CommonCard>
              ))
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          Coming Soon
        </Box>
      )}
    </StyledMainWrapper>
  );
};

export default Profile;
