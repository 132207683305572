import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import {
  default as ReceiptLongOutlined,
  default as ReceiptLongOutlinedIcon,
} from '@mui/icons-material/ReceiptLongOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';

// Internal
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from './Roles';
import { AUTH_ROUTES, ROUTENAME } from './RoutesConstants';

export const permissionRoutes = {
  global_admin: {
    admin_dashboard: { isAccessible: true },
    fire_companies: { isAccessible: true },
    company_list: { isAccessible: true },
    user_management: { isAccessible: true },
    data_management: { isAccessible: true },
    global_admin_integrations: { isAccessible: true },
    global_admin_control_panel: { isAccessible: true },
    monitoring_alert: { isAccessible: true },
    profile: { isAccessible: true },
    super_admin_reporting: { isAccessible: true },
    help_support: { isAccessible: true },
    profile_detail_view: { isAccessible: true },
    import_data: { isAccessible: true },
    export_data: { isAccessible: true },
    reference_data: { isAccessible: true },
    tbc_data: { isAccessible: true },
    system_setting: { isAccessible: true },
    bell_notification: { isAccessible: true },
  },
  super_admin: {
    admin_dashboard: { isAccessible: true },
    customers: { isAccessible: true },
    name: { isAccessible: true },
    billing_contracts: { isAccessible: true },
    billing_contracts_edit: { isAccessible: true },
    super_admin_properties: { isAccessible: true },
    properties_details: { isAccessible: true },
    scheduling: { isAccessible: true },
    super_admin_routine_services: { isAccessible: true },
    service_requests: { isAccessible: true },
    schedule_board: { isAccessible: true },
    work_orders: { isAccessible: true },
    work_orders_view: { isAccessible: true },
    super_admin_users: { isAccessible: true },
    super_admin: { isAccessible: true },
    office_staffs: { isAccessible: true },
    staff_management: { isAccessible: true },
    technicians: { isAccessible: true },
    super_admin_quotes: { isAccessible: true },
    defects_quotes: { isAccessible: true },
    quotes_services: { isAccessible: true },
    warehouse: { isAccessible: true },
    products: { isAccessible: true },
    super_admin_assets: { isAccessible: true },
    super_admin_invoices: { isAccessible: true },
    super_admin_control_panel: { isAccessible: true },
    super_admin_reports: { isAccessible: true },
    super_admin_commercial: { isAccessible: true },
    super_admin_timesheet: { isAccessible: true },
    super_admin_billing_info: { isAccessible: true },
    super_admin_billing_view: { isAccessible: true },
    super_admin_commercial_invoices: { isAccessible: true },
    super_admin_commercial_purchase_order: { isAccessible: true },
    settings: { isAccessible: true },
    suppliers: { isAccessible: true },
    product_catalogue: { isAccessible: true },
    data_management: { isAccessible: true },
    import_data: { isAccessible: true },
    sample_templates: { isAccessible: true },
    export_data: { isAccessible: true },
    help_support: { isAccessible: true },
    profile: { isAccessible: true },
    master: { isAccessible: true },
    templates: { isAccessible: true },
    forms: { isAccessible: true },
    integrations: { isAccessible: true },
    super_admin_reporting: { isAccessible: true },
    routine_service_frequencies: { isAccessible: true },
    system_setting: { isAccessible: true },
    bell_notification: { isAccessible: true },
  },
  customer: {
    customer_dashboard_properties: { isAccessible: true },
    customer_dashboard: { isAccessible: true },
    customer_properties: { isAccessible: true },
    customer_quotes: { isAccessible: true },
    customer_quotes_status: { isAccessible: true },
    customer_invoices: { isAccessible: true },
    customer_invoice_status: { isAccessible: true },
    defects: { isAccessible: true },
    customer_quotes_defects_status: { isAccessible: true },
    customer_reports: { isAccessible: true },
    make_a_request: { isAccessible: true },
    customer_assets: { isAccessible: true },
    routine_activities: { isAccessible: true },
    reactive_task: { isAccessible: true },
    documents: { isAccessible: true },
    customer_defect_dashboard: { isAccessible: true },
    profile: { isAccessible: true },
    help_support: { isAccessible: true },
    system_setting: { isAccessible: true },
    bell_notification: { isAccessible: true },
  },
  office_staff: {
    admin_dashboard: { isAccessible: true },
    customers: { isAccessible: true },
    name: { isAccessible: true },
    super_admin_properties: { isAccessible: true },
    properties_details: { isAccessible: true },
    scheduling: { isAccessible: true },
    super_admin_routine_services: { isAccessible: true },
    service_requests: { isAccessible: true },
    schedule_board: { isAccessible: true },
    work_orders: { isAccessible: true },
    work_orders_view: { isAccessible: true },
    super_admin_users: { isAccessible: true },
    technicians: { isAccessible: true },
    super_admin_quotes: { isAccessible: true },
    defects_quotes: { isAccessible: true },
    quotes_services: { isAccessible: true },
    products: { isAccessible: true },
    super_admin_assets: { isAccessible: true },
    super_admin_invoices: { isAccessible: true },
    super_admin_control_panel: { isAccessible: true },
    super_admin_reports: { isAccessible: true },
    super_admin_commercial: { isAccessible: true },
    super_admin_timesheet: { isAccessible: true },
    super_admin_billing_info: { isAccessible: true },
    super_admin_billing_view: { isAccessible: true },
    super_admin_commercial_invoices: { isAccessible: true },
    super_admin_commercial_purchase_order: { isAccessible: true },
    suppliers: { isAccessible: true },
    master: { isAccessible: true },
    product_catalogue: { isAccessible: true },
    super_admin_reporting: { isAccessible: true },
    help_support: { isAccessible: true },
    system_setting: { isAccessible: true },
    bell_notification: { isAccessible: true },
  },
  office_management: {
    admin_dashboard: { isAccessible: true },
    customers: { isAccessible: true },
    name: { isAccessible: true },
    super_admin_properties: { isAccessible: true },
    properties_details: { isAccessible: true },
    scheduling: { isAccessible: true },
    super_admin_routine_services: { isAccessible: true },
    service_requests: { isAccessible: true },
    schedule_board: { isAccessible: true },
    work_orders: { isAccessible: true },
    work_orders_view: { isAccessible: true },
    super_admin_users: { isAccessible: true },
    technicians: { isAccessible: true },
    super_admin_quotes: { isAccessible: true },
    defects_quotes: { isAccessible: true },
    quotes_services: { isAccessible: true },
    products: { isAccessible: true },
    super_admin_assets: { isAccessible: true },
    super_admin_invoices: { isAccessible: true },
    super_admin_control_panel: { isAccessible: true },
    super_admin_reports: { isAccessible: true },
    super_admin_commercial: { isAccessible: true },
    super_admin_timesheet: { isAccessible: true },
    super_admin_billing_info: { isAccessible: true },
    super_admin_billing_view: { isAccessible: true },
    super_admin_commercial_invoices: { isAccessible: true },
    super_admin_commercial_purchase_order: { isAccessible: true },
    suppliers: { isAccessible: true },
    master: { isAccessible: true },
    product_catalogue: { isAccessible: true },
    super_admin_reporting: { isAccessible: true },
    help_support: { isAccessible: true },
    system_setting: { isAccessible: true },
    bell_notification: { isAccessible: true },
  },
};

export const SIDEBAR_MENU = {
  [GLOBAL_ADMIN]: [
    {
      id: AUTH_ROUTES.ADMIN_DASHBOARD,
      label: 'Dashboard',
      path: ROUTENAME.DASHBOARD,
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
    },
    {
      id: AUTH_ROUTES.FIRE_COMPANIES,
      label: 'Fire Companies',
      path: ROUTENAME.FIRE_COMPANY,
      subMenu: [
        {
          id: AUTH_ROUTES.COMPANY_LIST,
          label: 'Company List',
          icon: <ListAltOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.COMPANIES,
        },
      ],
    },
    {
      id: AUTH_ROUTES.USERS_MANAGEMENT,
      label: 'Users Management',
      icon: <BrowserUpdatedOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.USERS_MANAGEMENT,
    },
    {
      id: AUTH_ROUTES.GLOBAL_ADMIN_USERS,
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: AUTH_ROUTES.GLOBAL_ADMINS,
          label: 'Global Admins',
          icon: <PeopleAltOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.GLOBAL_ADMIN,
        },
      ],
    },
    {
      id: AUTH_ROUTES.DATA_MANAGEMENT,
      label: 'Data Management',
      icon: <PeopleAltOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DATA_MANAGEMENT,
    },
    {
      id: AUTH_ROUTES.GLOBAL_ADMIN_INTEGRATIONS,
      label: 'Integrations',
      icon: <IntegrationInstructionsOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.GLOBAL_ADMIN_INTEGRATIONS,
    },
    {
      id: AUTH_ROUTES.GLOBAL_ADMIN_CONTROL_PANEL,
      label: 'Control Panel',
      icon: <SettingsOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.CONTROL_PANEL,
    },
    {
      id: AUTH_ROUTES.MONITORING_ALERT,
      label: 'Monitoring & Alerts',
      icon: <MonitorHeartOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.MONITORING_ALERT,
    },
  ],
  [SUPER_ADMIN]: [
    {
      id: AUTH_ROUTES.ADMIN_DASHBOARD,
      label: 'Dashboard',
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DASHBOARD,
    },
    {
      id: AUTH_ROUTES.CUSTOMERS,
      label: 'Customers',
      path: ROUTENAME.CUSTOMERS,
      subMenu: [
        {
          id: AUTH_ROUTES.NAME,
          label: 'Name',
          icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.CUSTOMER,
        },
        {
          id: AUTH_ROUTES.BILLING_CONTRACTS,
          label: 'Contracts',
          icon: <HandshakeOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.BILLING_CONTRACTS,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_PROPERTIES,
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SCHEDULING,
      label: 'Scheduling',
      path: ROUTENAME.SCHEDULING,
      subMenu: [
        {
          id: AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES,
          label: 'Routine Services',
          icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICES,
        },
        {
          id: AUTH_ROUTES.SERVICE_REQUESTS,
          label: 'Service Requests',
          icon: <InventoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SERVICE_REQUESTS,
        },
        {
          id: AUTH_ROUTES.WORK_ORDERS,
          label: 'Work Orders',
          icon: <BallotOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.WORK_ORDERS,
        },
        {
          id: AUTH_ROUTES.SCHEDULE_BOARD,
          label: 'Schedule Board',
          icon: <CalendarMonthOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SCHEDULE_BOARD,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_USERS,
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: AUTH_ROUTES.SUPER_ADMIN,
          label: 'Super Admins',
          icon: <ManageAccountsOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPER_ADMIN,
        },
        {
          id: AUTH_ROUTES.OFFICE_STAFFS,
          label: 'Office Staffs',
          icon: <AccountCircleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.OFFICE_STAFF,
        },
        {
          id: AUTH_ROUTES.STAFF_MANAGEMENT,
          label: 'Managements',
          icon: <SwitchAccountOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.STAFF_MANAGEMENT,
        },
        {
          id: AUTH_ROUTES.TECHNICIANS,
          label: 'Technicians',
          icon: <EngineeringOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TECHNICIAN,
        },
        {
          id: AUTH_ROUTES.SUPPLIERS,
          label: 'Suppliers',
          icon: <LocalShippingOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPPLIERS,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL,
      label: 'Commercial',
      path: ROUTENAME.COMMERCIAL,
      subMenu: [
        {
          id: AUTH_ROUTES.DEFECTS_QUOTES,
          label: 'Defects Quotes',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS_QUOTES,
        },
        {
          id: AUTH_ROUTES.QUOTES_SERVICES,
          label: 'Services Quotes',
          icon: <ReceiptLongOutlined sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES_SERVICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER,
          label: 'Purchase Order',
          icon: <PlaylistAddCheckCircleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PURCHASE_ORDER,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES,
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_TIMESHEET,
          label: 'Timesheet',
          icon: <PendingActionsIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TIMESHEET,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO,
          label: 'Billing Information',
          icon: <CreditCardIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.BILLING,
        },
      ],
    },
    {
      id: AUTH_ROUTES.MASTER,
      label: 'Master',
      path: ROUTENAME.MASTER,
      subMenu: [
        {
          id: AUTH_ROUTES.PRODUCT_CATALOGUE,
          label: 'Product Catalogue',
          icon: <StorefrontIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PRODUCT_CATALOGUE,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SETTINGS,
      label: 'Settings',
      path: ROUTENAME.SETTINGS,
      subMenu: [
        {
          id: AUTH_ROUTES.TEMPLATES,
          label: 'Templates',
          icon: <DescriptionOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TEMPLATES,
        },
        {
          id: AUTH_ROUTES.FORMS,
          label: 'Forms',
          icon: <GradingOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.FORMS,
        },
        {
          id: AUTH_ROUTES.INTEGRATIONS,
          label: 'Integrations',
          icon: <IntegrationInstructionsOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INTEGRATIONS,
        },
        {
          id: AUTH_ROUTES.ROUTINE_SERVICE_FREQUENCIES,
          label: 'Routine Service Frequencies',
          icon: <HistoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICE_FREQUENCIES,
        },
      ],
    },

    {
      id: AUTH_ROUTES.SUPER_ADMIN_REPORTS,
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      label: 'Reports',
      path: ROUTENAME.REPORTS,
      subMenu: [
        {
          id: AUTH_ROUTES.DATA_MANAGEMENT,
          label: 'Data Management',
          icon: <BrowserUpdatedOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DATA_MANAGEMENT,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_REPORTING,
          label: 'Reporting',
          icon: <ArticleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPER_ADMIN_REPORTING,
        },
      ],
    },
  ],
  [CUSTOMER]: [
    {
      id: AUTH_ROUTES.CUSTOMER_DASHBOARD,
      label: 'Dashboard',
      path: ROUTENAME.DASHBOARD,
      subMenu: [
        {
          id: AUTH_ROUTES.CUSTOMER_DASHBOARD_PROPERTIES,
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
          isAccessible: true,
        },
        {
          id: AUTH_ROUTES.CUSTOMER_QUOTES,
          label: 'Quotes',
          icon: <ReceiptLongOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES,
          isAccessible: true,
        },
        {
          id: AUTH_ROUTES.CUSTOMER_INVOICES,
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
        {
          id: AUTH_ROUTES.DEFECTS,
          label: 'Defects',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS,
        },
      ],
    },
    {
      id: AUTH_ROUTES.CUSTOMER_REPORTS,
      label: 'Reports',
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.REPORTS,
    },
    {
      id: AUTH_ROUTES.MAKE_A_REQUEST,
      label: 'Make a Request',
      icon: <RequestQuoteOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.MAKE_REQUEST,
    },
    {
      id: AUTH_ROUTES.CUSTOMER_ASSETS,
      label: 'Assets',
      icon: <CategoryOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.ASSETS,
    },
    {
      id: AUTH_ROUTES.ROUTINE_ACTIVITIES,
      label: 'Routine Activities',
      icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.ROUTINE_ACTIVITIES,
    },
    {
      id: AUTH_ROUTES.REACTIVE_TASK,
      label: 'Reactive Tasks',
      icon: <TaskOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.REACTIVE_TASK,
    },
    {
      id: AUTH_ROUTES.DOCUMENTS,
      label: 'Documents',
      icon: <DocumentScannerOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DOCUMENTS,
    },
  ],
  [OFFICE_STAFF]: [
    {
      id: AUTH_ROUTES.ADMIN_DASHBOARD,
      label: 'Dashboard',
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DASHBOARD,
    },
    {
      id: AUTH_ROUTES.CUSTOMERS,
      label: 'Customers',
      path: ROUTENAME.CUSTOMERS,
      subMenu: [
        {
          id: AUTH_ROUTES.NAME,
          label: 'Name',
          icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.CUSTOMER,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_PROPERTIES,
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SCHEDULING,
      label: 'Scheduling',
      path: ROUTENAME.SCHEDULING,
      subMenu: [
        {
          id: AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES,
          label: 'Routine Services',
          icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICES,
        },
        {
          id: AUTH_ROUTES.SERVICE_REQUESTS,
          label: 'Service Requests',
          icon: <InventoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SERVICE_REQUESTS,
        },
        {
          id: AUTH_ROUTES.WORK_ORDERS,
          label: 'Work Orders',
          icon: <BallotOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.WORK_ORDERS,
        },
        {
          id: AUTH_ROUTES.SCHEDULE_BOARD,
          label: 'Schedule Board',
          icon: <CalendarMonthOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SCHEDULE_BOARD,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_USERS,
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: AUTH_ROUTES.TECHNICIANS,
          label: 'Technicians',
          icon: <EngineeringOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TECHNICIAN,
        },
        {
          id: AUTH_ROUTES.SUPPLIERS,
          label: 'Suppliers',
          icon: <LocalShippingOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPPLIERS,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL,
      label: 'Commercial',
      path: ROUTENAME.COMMERCIAL,
      subMenu: [
        {
          id: AUTH_ROUTES.DEFECTS_QUOTES,
          label: 'Defects Quotes',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS_QUOTES,
        },
        {
          id: AUTH_ROUTES.QUOTES_SERVICES,
          label: 'Services Quotes',
          icon: <ReceiptLongOutlined sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES_SERVICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER,
          label: 'Purchase Order',
          icon: <PlaylistAddCheckCircleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PURCHASE_ORDER,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES,
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_TIMESHEET,
          label: 'Timesheet',
          icon: <PendingActionsIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TIMESHEET,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO,
          label: 'Billing Information',
          icon: <CreditCardIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.BILLING,
        },
      ],
    },
    {
      id: AUTH_ROUTES.MASTER,
      label: 'Master',
      path: ROUTENAME.MASTER,
      subMenu: [
        {
          id: AUTH_ROUTES.PRODUCT_CATALOGUE,
          label: 'Product Catalogue',
          icon: <StorefrontIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PRODUCT_CATALOGUE,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_REPORTS,
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      label: 'Reports',
      path: ROUTENAME.REPORTS,
      subMenu: [
        {
          id: AUTH_ROUTES.REPORTING,
          label: 'Reporting',
          icon: <ArticleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.REPORTING,
        },
      ],
    },
  ],
  [OFFICE_MANAGEMENT]: [
    {
      id: AUTH_ROUTES.ADMIN_DASHBOARD,
      label: 'Dashboard',
      icon: <DashboardOutlinedIcon sx={{ color: 'black' }} />,
      path: ROUTENAME.DASHBOARD,
    },
    {
      id: AUTH_ROUTES.CUSTOMERS,
      label: 'Customers',
      path: ROUTENAME.CUSTOMERS,
      subMenu: [
        {
          id: AUTH_ROUTES.NAME,
          label: 'Name',
          icon: <PersonOutlineOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.CUSTOMER,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_PROPERTIES,
          label: 'Properties',
          icon: <MapsHomeWorkOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PROPERTIES,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SCHEDULING,
      label: 'Scheduling',
      path: ROUTENAME.SCHEDULING,
      subMenu: [
        {
          id: AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES,
          label: 'Routine Services',
          icon: <HistoryToggleOffOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.ROUTINE_SERVICES,
        },
        {
          id: AUTH_ROUTES.SERVICE_REQUESTS,
          label: 'Service Requests',
          icon: <InventoryOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SERVICE_REQUESTS,
        },
        {
          id: AUTH_ROUTES.WORK_ORDERS,
          label: 'Work Orders',
          icon: <BallotOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.WORK_ORDERS,
        },
        {
          id: AUTH_ROUTES.SCHEDULE_BOARD,
          label: 'Schedule Board',
          icon: <CalendarMonthOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SCHEDULE_BOARD,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_USERS,
      label: 'Users',
      path: ROUTENAME.USERS,
      subMenu: [
        {
          id: AUTH_ROUTES.TECHNICIANS,
          label: 'Technicians',
          icon: <EngineeringOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TECHNICIAN,
        },
        {
          id: AUTH_ROUTES.SUPPLIERS,
          label: 'Suppliers',
          icon: <LocalShippingOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.SUPPLIERS,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL,
      label: 'Commercial',
      path: ROUTENAME.COMMERCIAL,
      subMenu: [
        {
          id: AUTH_ROUTES.DEFECTS_QUOTES,
          label: 'Defects Quotes',
          icon: <ReportProblemOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.DEFECTS_QUOTES,
        },
        {
          id: AUTH_ROUTES.QUOTES_SERVICES,
          label: 'Services Quotes',
          icon: <ReceiptLongOutlined sx={{ color: 'black' }} />,
          path: ROUTENAME.QUOTES_SERVICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER,
          label: 'Purchase Order',
          icon: <PlaylistAddCheckCircleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PURCHASE_ORDER,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES,
          label: 'Invoices',
          icon: <ReceiptOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.INVOICES,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_TIMESHEET,
          label: 'Timesheet',
          icon: <PendingActionsIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.TIMESHEET,
        },
        {
          id: AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO,
          label: 'Billing Information',
          icon: <CreditCardIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.BILLING,
        },
      ],
    },
    {
      id: AUTH_ROUTES.MASTER,
      label: 'Master',
      path: ROUTENAME.MASTER,
      subMenu: [
        {
          id: AUTH_ROUTES.PRODUCT_CATALOGUE,
          label: 'Product Catalogue',
          icon: <StorefrontIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.PRODUCT_CATALOGUE,
        },
      ],
    },
    {
      id: AUTH_ROUTES.SUPER_ADMIN_REPORTS,
      icon: <SummarizeOutlinedIcon sx={{ color: 'black' }} />,
      label: 'Reports',
      path: ROUTENAME.REPORTS,
      subMenu: [
        {
          id: AUTH_ROUTES.REPORTING,
          label: 'Reporting',
          icon: <ArticleOutlinedIcon sx={{ color: 'black' }} />,
          path: ROUTENAME.REPORTING,
        },
      ],
    },
  ],
};
