import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { SECONDARY } from '../constants/Colors';
import LandingPageFooter from './LandingPageLayout/LandingPageFooter';
import LandingPageHeader from './LandingPageLayout/LandingPageHeader';

export const TermsService = () => {
  const { t } = useTranslation();
  const Body = (
    <Stack
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: SECONDARY,
      }}
    >
      <Stack
        sx={{
          padding: { xs: '128px 30px', sm: '128px 100px' },
          overflow: 'auto',
        }}
        spacing={2}
      >
        <Typography variant="h2" sx={{ fontSize: '24px' }}>
          {t('attributes.termsOfService.title')}
        </Typography>
        <Typography variant="body2">
          {t('attributes.termsOfService.one')}
        </Typography>
        <Typography variant="body2">
          {t('attributes.termsOfService.two')}
        </Typography>
        <Typography variant="body2">
          {t('attributes.termsOfService.three')}
        </Typography>
        <Typography variant="body2">
          {t('attributes.termsOfService.four')}
        </Typography>
      </Stack>
    </Stack>
  );

  return (
    <>
      <LandingPageHeader />
      {Body}
      <LandingPageFooter />
    </>
  );
};
