import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ROUTINE } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Inspection Form List
export const getInspectionFormList = createAsyncThunk(
  ROUTINE.GET_INSPECTION_FORM_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.INSPECTION_FORM.GET_INSPECTION_FORM_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
