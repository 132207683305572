import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce, isEmpty } from 'lodash';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV, validateAddress } from '../../constants/common';
import {
  getRolesList,
  getUserStatusList,
  setUserManagermentDrawerTitle,
  TAB_STATUS,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import {
  CUSTOMER,
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
  TECHNICIAN,
} from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../constants/Typography';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useRoleHook from '../../hooks/useRoleHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { getCategoryDropdownList } from '../../store/category/api';
import { snackbarToggle } from '../../store/CommonReducer';
import { getCompanyDropdownList } from '../../store/company/api';
import { updateOfficeManagement } from '../../store/officeManagement/api';
import { updateOfficeStaff } from '../../store/officeStaff/api';
import { getServiceAreasDropdownList } from '../../store/serviceAreas/api';
import { getSkillsDropdownList } from '../../store/skills/api';
import { updateSuperAdmin } from '../../store/superAdmin/api';
import { updateTechnician } from '../../store/technician/api';
import { getUsersList } from '../../store/userManagement/api';
import { getZonesDropdownList } from '../../store/zones/api';
import { formattedDate } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import SuperAdminForm from '../SuperAdmin/SuperAdminForm';
import CustomerForm from '../Users/Customer/CustomerForm';
import OfficeStaffForm from '../Users/OfficeStaff/OfficeStaffForm';
import OfficeManagementForm from '../Users/StaffManagement/StaffManagementForm';
import TechnicianForm from '../Users/Technician/TechnicianForm';

const defaultFilters = {
  status: null,
  role: null,
  company: null,
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

export const UsersManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasRole = useRoleHook();
  const navigate = useNavigate();

  const rolesList = getRolesList();
  const userStatusList = getUserStatusList();
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [companylistOptions, setCompanylistOptions] = useState([]);
  const [serverErrors, setServerErrors] = useState([]);
  const [userId, setUserId] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [editing, setEdititng] = useState('');
  const { categoryDropdownLoading, categoryDropdownData } = getDropdownListHook(
    {
      reducerName: 'category',
      dropdownListName: 'categoryDropdownList',
      labelName: 'display_name',
      valueName: 'name',
    }
  );
  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { skillsDropdownLoading, skillsDropdownData } = getDropdownListHook({
    reducerName: 'skills',
    dropdownListName: 'skillsDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { serviceAreasDropdownLoading, serviceAreasDropdownData } =
    getDropdownListHook({
      reducerName: 'serviceAreas',
      dropdownListName: 'serviceAreasDropdownList',
      labelName: 'display_name',
      valueName: 'name',
    });
  const {
    isLoading: createSuperAdminLoading,
    createdSuperAdmin,
    error: createSuperAdminError,
  } = useSelector((state) => state.superAdmin.create);

  const {
    isLoading: createOfficeStaffLoading,
    createdOfficeStaff,
    error: createOfficeStaffError,
  } = useSelector((state) => state.officeStaff.create);

  const {
    isLoading: createOfficeManagementLoading,
    createdOfficeManagement,
    error: createOfficeManagementError,
  } = useSelector((state) => state.officeManagement.create);

  const {
    isLoading: createTechnicianLoading,
    createdTechnician,
    error: createTechnicianError,
  } = useSelector((state) => state.technician.create);

  const { editTechnician } = useSelector((state) => state.technician.edit);

  const {
    isLoading: updateCustomerLoading,
    updatedCustomer,
    error: updateCustomerError,
  } = useSelector((state) => state.customer.update);

  const method = useForm();
  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
  } = method;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'user_name',
        headerName: `${t('attributes.usersManagement.name')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'company_name',
        headerName: `${t('attributes.usersManagement.companyName')}`,
        flex: 1,
        sortable: true,
      },
      {
        field: 'role',
        headerName: `${t('attributes.usersManagement.role')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'email',
        headerName: `${t('attributes.usersManagement.email')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'cell_phone',
        headerName: `${t('attributes.usersManagement.cell_phone')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'active',
        headerName: `${t('attributes.usersManagement.status')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.active),
      },
      {
        field: 'created_at',
        headerName: `${t('attributes.usersManagement.createdAt')}`,
        flex: 1,
        sortable: false,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: `${t('attributes.actions')}`,
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <View
              onClick={() => {
                handleViewUser(row);
              }}
            />
            <Edit
              onClick={() => {
                handleEditUser(row);
              }}
            />
          </>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const { usersList, total } = useSelector((state) => state.userManagement.get);
  const { isLoading, data } = useSelector((state) => state.company.getDropdown);

  const handleViewUser = (userDetails) => {
    const role = userDetails?.rolename;
    const id = userDetails?.profile_uuid;

    navigate(`/${ROUTENAME.USERS_MANAGEMENT}/${role}/${id}`);
  };

  const handleEditUser = (userDetails) => {
    setUserId(userDetails?.profile_uuid);
    setOpen(true);
    setDrawerTitle(setUserManagermentDrawerTitle(userDetails?.rolename, t));
    setEdititng(userDetails?.rolename);
  };
  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    if (createSuperAdminLoading !== null && !createSuperAdminLoading) {
      if (createSuperAdminError) {
        if (createSuperAdminError.errorDetails) {
          setServerErrors(createSuperAdminError.errorDetails);
        }
      } else {
        if (createdSuperAdmin) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.superAdmin.superAdminUpdatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [createSuperAdminError, createSuperAdminLoading]);

  useEffect(() => {
    if (createOfficeStaffLoading !== null && !createOfficeStaffLoading) {
      if (createOfficeStaffError) {
        if (createOfficeStaffError.errorDetails) {
          setServerErrors(createOfficeStaffError.errorDetails);
        }
      } else {
        if (createdOfficeStaff) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.office_staff.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [createOfficeStaffLoading, createOfficeStaffError]);

  useEffect(() => {
    if (
      createOfficeManagementLoading !== null &&
      !createOfficeManagementLoading
    ) {
      if (createOfficeManagementError) {
        if (createOfficeManagementError.errorDetails) {
          setServerErrors(createOfficeManagementError.errorDetails);
        }
      } else {
        if (createdOfficeManagement) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.office_management.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [createOfficeManagementLoading, createOfficeManagementError]);

  useEffect(() => {
    if (createTechnicianLoading !== null && !createTechnicianLoading) {
      if (createTechnicianError) {
        if (createTechnicianError.errorDetails) {
          setServerErrors(createTechnicianError.errorDetails);
        }
      } else {
        if (createdTechnician) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t('message.technician.updatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [createTechnicianLoading, createTechnicianError]);

  useEffect(() => {
    if (updateCustomerLoading !== null && !updateCustomerLoading) {
      if (updateCustomerError) {
        if (updateCustomerError.errorDetails) {
          setServerErrors(updateCustomerError.errorDetails);
        }
      } else {
        if (updatedCustomer) {
          const closeModel =
            updatedCustomer?.data[0]?.invitation_status === 'invited';

          closeModel && setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatedCustomer.message,
            })
          );
        }
      }
    }
  }, [updateCustomerError, updateCustomerLoading]);

  const formatStatus = (status) => {
    if (status === true) {
      return <StatusLabel label={t('common.active')} color="#95C020" />;
    }
    if (status === false) {
      return <StatusLabel label={t('common.inactive')} color="#454545" />;
    }
  };

  const companyList = useMemo(() => {
    const options = data?.data?.map((item) => ({
      label: item.company_name,
      value: item.uuid,
    }));

    return options || [];
  }, [data]);

  useEffect(() => {
    if (hasRole === GLOBAL_ADMIN) {
      dispatch(getCompanyDropdownList());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      setCompanylistOptions(companyList);
    }
  }, [companyList]);

  const getAllUsersList = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getUsersList({
        limit: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        active: filters.status?.value,
        role: filters.role?.value,
        company: filters.company?.value,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columnVisibilityModel,
    refresh,
  ]);

  const debouncedFetchData = useCallback(debounce(getAllUsersList, 500), [
    getAllUsersList,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    dispatch(getCategoryDropdownList());
    dispatch(getSkillsDropdownList());
    dispatch(getZonesDropdownList());
    dispatch(getServiceAreasDropdownList());
  }, []);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = usersList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.role = updatedItem?.roles?.[0]?.title;
    updatedItem.rolename = updatedItem?.roles?.[0]?.name;

    return updatedItem;
  });

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.zip_code,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });

    if (hasError) return;
    if (editing === SUPER_ADMIN) {
      const request = {
        name: data.name,
        email: data.email,
        address: data.address,
        cell_phone: data.cell_phone,
        city: data.city?.value,
        state: data.state?.value,
        zip_code: data.zip_code,
        company_uuid: data.company_name.value,
        company_name: data.company_name.label,
        active: data.active,
      };

      dispatch(updateSuperAdmin({ uuid: data?.uuid, req: request }));
    }

    if (editing === OFFICE_STAFF) {
      const createdOfficeStaffData = {
        name: data.name,
        email: data.email,
        cell_phone: data.cell_phone,
        address: data.address,
        city: data.city.value,
        state: data.state.value,
        postcode: data.postcode,
        is_active: data.active,
      };

      dispatch(
        updateOfficeStaff({ id: data?.uuid, data: createdOfficeStaffData })
      );
    }

    if (editing === OFFICE_MANAGEMENT) {
      const createdOfficeManagementData = {
        name: data.name,
        email: data.email,
        cell_phone: data.cell_phone,
        address: data.address,
        city: data.city.value,
        state: data.state.value,
        postcode: data.postcode,
        is_active: data.active,
      };

      dispatch(
        updateOfficeManagement({
          id: data?.uuid,
          data: createdOfficeManagementData,
        })
      );
    }

    if (editing === TECHNICIAN) {
      const isDocUploaded = data?.otherDocument;
      let docs = [];

      if (!isEmpty(isDocUploaded)) {
        docs.push(isDocUploaded);
      }
      if (editTechnician?.documents?.length > 0) {
        const oldDocId = editTechnician?.documents?.document_uuid;

        if (
          oldDocId !== isDocUploaded?.document_uuid ||
          isEmpty(isDocUploaded)
        ) {
          docs.push({ ...editTechnician?.documents[0], is_deleted: true });
        }
      }
      const request = {
        technician_name: data.primaryContact,
        categories: [data.category?.value || ''],
        skills: data.skill?.map((item) => item?.value),
        service_areas: data.serviceArea?.map((item) => item?.value),
        zones: data.zones?.map((item) => item?.value),
        notes: data.notes,
        primary_contact_details: {
          technician_name: data.primaryContact,
          contact_email: data.technician_email,
          contact_phone: data.contactPhone,
          address: data.address,
          state: data.state?.value,
          city: data.city?.value,
          postcode: data.postCode,
        },
        documents: docs,
        status: data.active ? TAB_STATUS.active : TAB_STATUS.inactive,
      };

      dispatch(updateTechnician({ id: data?.id, req: request }));
    }
  };

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText || filters.status || filters.role || filters.company
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.usersManagement.status')}
            options={userStatusList}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.usersManagement.role')}
            options={rolesList}
            value={filters?.role}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, role: newValue }))
            }
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.usersManagement.fireCompany')}
            options={companylistOptions}
            value={filters?.company}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, company: newValue }))
            }
            width="190px"
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const UserManagementqWrapper = (
    <>
      <StyledMainWrapper
        title={t('attributes.usersManagement.title')}
        style={{ backgroundColor: DIVIDER_COLOR }}
      ></StyledMainWrapper>
      <Box sx={{ paddingBottom: '16px' }}>
        <StyledMainWrapper
          btn={
            <>
              <CustomButton
                text={t('attributes.export')}
                color="secondary"
                sx={{ height: '52%' }}
                startIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (!selectedRows || selectedRows?.length === 0) {
                    dispatch(
                      snackbarToggle({
                        isOpen: true,
                        isErrorMsg: true,
                        msg: t('message.common.noRecordExportMessage'),
                      })
                    );
                    setSelectedRows(null);
                  } else {
                    exportToCSV(
                      selectedRows,
                      COLUMNS_TO_EXPORT.USER_MANAGEMENT,
                      EXPORT_FILE_NAMES.USER_MANAGEMENT
                    );
                  }
                }}
              />
            </>
          }
        >
          <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
            {renderedComponent}
          </Box>
        </StyledMainWrapper>
      </Box>
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {UserManagementqWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={drawerTitle}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            editing !== CUSTOMER && (
              <CustomButton
                text={
                  createSuperAdminLoading ||
                  createOfficeStaffLoading ||
                  createOfficeManagementLoading
                    ? t('common.loading')
                    : t('attributes.save')
                }
                disabled={
                  createSuperAdminLoading ||
                  createOfficeStaffLoading ||
                  createOfficeManagementLoading
                }
                startIcon={<SaveOutlinedIcon />}
                onClick={handleSubmit(onSubmit)}
              />
            )
          }
          width={editing === CUSTOMER && SWIPEABLE_DRAWER_STEPPER_WIDTH}
        >
          {editing === SUPER_ADMIN && (
            <SuperAdminForm
              id={userId}
              reset={reset}
              clearErrors={clearErrors}
              isGlobalAdmin={true}
              control={control}
              register={register}
              trigger={trigger}
              companylistOptions={companylistOptions}
              watch={watch}
              setValue={setValue}
              isCompanyNameLoading={isLoading}
              setServerErrors={setServerErrors}
            />
          )}

          {editing === OFFICE_STAFF && (
            <OfficeStaffForm
              id={userId}
              sethasFormValues={sethasFormValues}
              control={control}
              reset={reset}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              clearErrors={clearErrors}
              setServerErrors={setServerErrors}
            />
          )}

          {editing === OFFICE_MANAGEMENT && (
            <OfficeManagementForm
              id={userId}
              sethasFormValues={sethasFormValues}
              control={control}
              reset={reset}
              setValue={setValue}
              watch={watch}
              trigger={trigger}
              clearErrors={clearErrors}
              setServerErrors={setServerErrors}
            />
          )}

          {editing === TECHNICIAN && (
            <TechnicianForm
              sethasFormValues={sethasFormValues}
              clearErrors={clearErrors}
              id={userId}
              control={control}
              reset={reset}
              setValue={setValue}
              getValues={getValues}
              categoryList={categoryDropdownData}
              isCategoryListLoading={categoryDropdownLoading}
              zonesList={zonesDropdownData}
              isZonesListLoading={zonesDropdownLoading}
              skillsList={skillsDropdownData}
              isSkillsListLoading={skillsDropdownLoading}
              serviceAreaList={serviceAreasDropdownData}
              isServiceListLoading={serviceAreasDropdownLoading}
              watch={watch}
              trigger={trigger}
              setServerErrors={setServerErrors}
            />
          )}

          {editing === CUSTOMER && (
            <FormProvider {...method}>
              <CustomerForm
                customerId={userId}
                formMode="edit"
                sethasFormValues={sethasFormValues}
                setServerErrors={setServerErrors}
              />
            </FormProvider>
          )}
        </SwipeableDrawer>
      )}
    </>
  );
};
