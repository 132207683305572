import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_FORMAT_API } from '../../constants/Constants';
import { CUSTOMER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

const { GET_DEFECT_MONTHLY_CHART, GET_DEFECT_BY_EQUIPEMENT_CHART } = CUSTOMER;

export const getMonthlyDefectChartData = createAsyncThunk(
  GET_DEFECT_MONTHLY_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_MONTHLY_CHART}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectByEquipementType = createAsyncThunk(
  GET_DEFECT_BY_EQUIPEMENT_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_BY_EQUIPEMENT_CHART}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
