import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { DarkLogo } from '../assets/images';
import { DIVIDER_COLOR, SECONDARY } from '../constants/Colors';

export const AboutApp = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: SECONDARY,
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          bgcolor: '#EBEBEB33',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={DarkLogo} alt="" style={{ marginTop: '16px' }} />
        </Box>
        <Box
          sx={{
            bgcolor: SECONDARY,
            border: `2px solid ${DIVIDER_COLOR}`,
            margin: '16px !important',
            padding: '16px',
            borderRadius: '6px',
            textAlign: 'center',
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: 2 }}>
            {t('attributes.aboutApp')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
