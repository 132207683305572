import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { GLOBAL_ADMIN } from '../actionPrefix';
import {
  axiosInstanceAuthAndFilter,
  axiosInstanceCompany,
  axiosPropertyUrl,
} from '../axios';

export const getPropertiesChartData = createAsyncThunk(
  GLOBAL_ADMIN.GET_PROPERTY_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.GLOBAL_ADMIN.GET_PROPERTY_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFireCompaniesChartData = createAsyncThunk(
  GLOBAL_ADMIN.GET_FIRE_COMPANY_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.GLOBAL_ADMIN.GET_FIRE_COMPANY_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersChartData = createAsyncThunk(
  GLOBAL_ADMIN.GET_UERS_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.post(
        APIs.GLOBAL_ADMIN.GET_UERS_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getGlobalAdminDashboardCount = createAsyncThunk(
  GLOBAL_ADMIN.GET_DASHBOARD_COUNT,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.GLOBAL_ADMIN.GET_DASHBOARD_COUNT
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
