import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { Validation } from '../../constants/FieldValidationMsg';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { snackbarToggle } from '../../store/CommonReducer';
import { addAccountPartnerConfiguration } from '../../store/integrations/api';
import { resetAddAccountPartnerConfiguration } from '../../store/integrations/reducer';
import CustomButton from '../CommonComponents/CustomButton';
import CustomTextField from '../CommonComponents/CustomTextField';
import MainWrapper from '../CommonComponents/MainWrapper';

const StyledOrderedList = styled('ol')({
  listStyleType: 'decimal',
  paddingLeft: '16px',
  margin: 0,
});

const StyledListItem = styled('li')({
  marginBottom: '8px',
  fontWeight: 'normal',
});

const XeroIntegrations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm();

  const { isLoading, data } = useSelector(
    (state) => state.accountConfiguration.add
  );

  useEffect(
    () => () => {
      dispatch(resetAddAccountPartnerConfiguration());
    },
    []
  );

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: data.message,
          })
        );
      }
    }
  }, [isLoading, data]);

  const onSubmit = (data) => {
    const requestData = {
      account_partner_type: 'XERO',
      tenant_id: data.tenantId,
      client_id: data.clientId,
      secret: data.clientSecret,
      webhook_key: data.webhookKey,
    };

    dispatch(addAccountPartnerConfiguration(requestData));
  };

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.SETTINGS}/${ROUTENAME.INTEGRATIONS}`);
  };

  return (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.integrations.integrations')}
          variant="body1"
          isStep={true}
          step={t('attributes.integrations.xero')}
          handleDefaultStep={handleDefaultStep}
        />
      </Box>
      <Box
        sx={{ backgroundColor: DIVIDER_COLOR, height: 'calc(100vh - 120px)' }}
      >
        <Box sx={{ backgroundColor: SECONDARY, borderRadius: '6px' }}>
          {/* Tenant Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.tenantTitle')}:
            </Typography>
            <StyledOrderedList>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.tenantPoint1')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.tenantPoint2')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.tenantPoint3')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.tenantPoint4')}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {t('attributes.integrations.tenantCapitalizeId')}
                  </span>
                </Typography>
              </StyledListItem>
            </StyledOrderedList>
            <Box sx={{ paddingTop: '16px' }}>
              <Controller
                name="tenantId"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.tenantId')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.tenantId')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Box>
          </Box>
          <Divider />
          {/* Client Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.clientTitle')}
            </Typography>
            <StyledOrderedList>
              <StyledOrderedList>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.clientPoint1')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.clientPoint2')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.clientPoint3')}{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {t('attributes.integrations.clientCapitalizeId')}
                    </span>{' '}
                    {t('attributes.integrations.clientPoint4')}
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <Typography variant="body2">
                    {t('attributes.integrations.clientPoint3')}{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {t('attributes.integrations.clientSecret')}
                    </span>{' '}
                    {t('attributes.integrations.clientPoint4')}
                  </Typography>
                </StyledListItem>
              </StyledOrderedList>
            </StyledOrderedList>
            <Stack spacing={2} direction="row" sx={{ paddingTop: '16px' }}>
              <Controller
                name="clientId"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.clientId')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.clientId')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
              <Controller
                name="clientSecret"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.clientSecret')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.clientSecret')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Stack>
          </Box>
          <Divider />
          {/* Web hook Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.webhookKey')}
            </Typography>
            <Stack spacing={2} direction="row">
              <Controller
                name="webhookKey"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.webhookKey')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.webhookKey')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Stack>
          </Box>
          <Divider />
          <Stack direction="row" padding="16px" spacing={1} alignItems="center">
            <ErrorOutlineOutlinedIcon sx={{ height: '18px', width: '18px' }} />
            <Typography variant="body1">
              {t('attributes.integrations.xeroWarningMessage')}
            </Typography>
          </Stack>
          <Divider />

          <Stack direction="row" justifyContent="end" padding="16px">
            <CustomButton
              text={t('attributes.save')}
              color="primary"
              sx={{ height: '52%' }}
              startIcon={<SaveIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default XeroIntegrations;
