import { createSlice } from '@reduxjs/toolkit';

import { getInspectionFormList } from './api';

const initialData = {
  isLoading: false,
  error: false,
  data: false,
};

const inspectionFormSlice = createSlice({
  name: 'inspectionForm',
  initialState: {
    get: { ...initialData },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Add account partner configuration
      .addCase(getInspectionFormList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getInspectionFormList.fulfilled, (state, action) => {
        state.get.isLoading = false;
        state.get.error = false;
        state.get.data = action.payload;
      })
      .addCase(getInspectionFormList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      });
  },
});

export default inspectionFormSlice.reducer;
