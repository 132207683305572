import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Box, Typography } from '@mui/material';

import { FlexEnd, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import Autocomplete from '../CommonComponents/AutoComplete';

const ChartCard = ({
  title,
  children,
  options = [],
  selectedType = '',
  setSelectedType = () => {},
  id = '',
  isSettingMode = false,
  onChartRemove = () => {},
  height = '300px',
  isEditableChart = true,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      bgcolor: 'white',
      borderRadius: '4px',
    }}
  >
    <SpaceBetween
      sx={{
        width: '100%',
        padding: '8px',
        borderBottom: `1px solid ${DIVIDER_COLOR}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: '8px',
          alignItems: 'center',
        }}
      >
        {isEditableChart && isSettingMode && <DragIndicatorOutlinedIcon />}

        <Typography variant="body1">{title}</Typography>
      </Box>
      {isSettingMode && (
        <FlexEnd style={{ columnGap: '8px' }}>
          <Autocomplete
            options={options}
            value={selectedType}
            onChange={(e, newVal) => setSelectedType(id, newVal)}
            disableClearable
            sx={{
              paddingRight: 0,
              height: '26px',
              '.MuiAutocomplete-inputRoot': {
                paddingRight: '0 !important',
                height: '26px',
                width: '115px',
              },
              '.MuiOutlinedInput-root': { padding: '0 !important' },
              '& .MuiAutocomplete-endAdornment': {
                right: '0 !important',
              },
            }}
          />
          {isEditableChart && (
            <CancelOutlinedIcon
              fontSize="small"
              onClick={() => onChartRemove(id)}
            />
          )}
        </FlexEnd>
      )}
    </SpaceBetween>
    <Box sx={{ width: '100%', position: 'relative', height: height }}>
      {children}
    </Box>
  </Box>
);

export default ChartCard;
