import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Card, styled } from '@mui/material';
import { debounce } from 'lodash';

import { getTechnicianList } from '../../store/technician/api';
import { resetPagination } from '../../store/technician/reducer';
import { resetDownloadTimeSheetList } from '../../store/timesheet/reducer';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTableWithCheckbox from '../CommonComponents/CustomTableWithCheckbox';
import CustomSearch from '../CommonComponents/Search';

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(({ padding: customPadding }) => ({
  boxShadow: 'none',
  padding: customPadding || '16px',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TimeSheetDownload = ({
  technicianList,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  selectedTechnician,
  setSelectedTechnician,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTechnician, setSearchTechnician] = useState('');
  const { technicianInactiveList, isLoading } = useSelector(
    (state) => state.technician.get
  );

  const getAllTechnician = useCallback(() => {
    dispatch(
      getTechnicianList({
        limit: -1,
        search: searchTechnician,
      })
    ).finally(() => {});
  }, [dispatch, searchTechnician]);

  const debouncedFetchData = useCallback(debounce(getAllTechnician, 500), [
    getAllTechnician,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(
    () => () => {
      dispatch(resetDownloadTimeSheetList());
      dispatch(resetPagination());
    },
    []
  );

  const onCheckboxSelection = (item) => {
    if (selectedTechnician.some((tech) => tech.uuid === item.uuid)) {
      setSelectedTechnician(
        selectedTechnician.filter((tech) => tech.uuid !== item.uuid)
      );
      return;
    }
    setSelectedTechnician((prev) => [...prev, item]);
  };

  const rows = technicianInactiveList?.map((item) => ({
    ...item,
    uuid: item.uuid,
    displayName: item.technician_name,
  }));

  return (
    <FormWrapper>
      <CustomCard padding="16px">
        <CustomDateRangePicker
          label="Date Range"
          placeholder=" "
          isRequired={true}
          onOkClick={(val) => {
            setFromDate(val[0]);
            setToDate(val[1]);
          }}
          onClear={() => {
            setFromDate(null);
            setToDate(null);
          }}
          fromDate={fromDate}
          toDate={toDate}
          width="100%"
          placement="bottomEnd"
        />
      </CustomCard>
      <CustomCard>
        <CustomSearch
          width="100%"
          height="40px"
          onChange={(e) => setSearchTechnician(e.target.value)}
          value={searchTechnician}
        />
        {isLoading ? (
          <Box mt="16px">
            <CustomCircularLoader />
          </Box>
        ) : (
          <CustomTableWithCheckbox
            headers={[
              { type: 'checkbox', width: '10%' },
              {
                label: 'Technician',
                width: '90%',
                type: 'textField',
                field: 'displayName',
              },
            ]}
            data={rows}
            selectedItems={selectedTechnician}
            onItemSelect={onCheckboxSelection}
            onFieldChange={() => {}}
          />
        )}
      </CustomCard>
    </FormWrapper>
  );
};

export default TimeSheetDownload;
