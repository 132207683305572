import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

// Internal
import { WARNING } from '../../constants/Colors';
import CustomButton from './CustomButton';

export const Edit = ({ onClick = () => {}, ...rest }) => (
  <CustomButton
    startIcon={<ModeEditOutlineOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
    {...rest}
  />
);

export const InActive = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<BlockIcon />}
    text="InActive"
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '50px',
        padding: '5px',
        border: `1px solid ${WARNING}`,
        color: WARNING,
        fontWeight: 600,
      },
      '& .MuiButton-startIcon': {
        margin: 0,
        marginRight: '5px',
      },
    }}
  />
);
export const View = ({ onClick = () => {}, ...rest }) => (
  <CustomButton
    startIcon={<VisibilityOutlinedIcon />}
    // startIcon={<RemoveRedEyeOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
    {...rest}
  />
);

export const OpenInNewTabIcon = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<OpenInNewIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
  />
);

export const Delete = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<DeleteOutlineOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
        color: 'red',
        borderColor: 'red',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
  />
);

export const Download = ({ onClick = () => {} }) => (
  <CustomButton
    startIcon={<DownloadOutlinedIcon />}
    variant="outlined"
    onClick={onClick}
    sx={{
      '&.MuiButton-root': {
        minWidth: '24px',
        height: '24px',
        padding: '5px',
        border: '1px solid #95C020',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    }}
  />
);
