import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../../constants/Colors';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../../constants/Roles';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { getUserRole } from '../../../hoc/Permission';
import StyledMainWrapper from '../StyledMainWrapper';

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: 0,
}));

export const DataManagement = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cardData = {
    [GLOBAL_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.REFERENCE_DATA);
        },
        rightIcon: (
          <DatasetOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.reports.dataManagement.referenceData'),
        description: t(
          'attributes.reports.dataManagement.globalReferenceDataNote'
        ),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.IMPORT_DATA);
        },
        rightIcon: (
          <UpgradeOutlinedIcon
            style={{
              rotate: '180deg',
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.import'),
        description: t(
          'attributes.reports.dataManagement.globalImportDataNote'
        ),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.EXPORT_DATA);
        },
        rightIcon: (
          <UpgradeOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.export'),
        description: t(
          'attributes.reports.dataManagement.globalExportDataNote'
        ),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.TBC_DATA);
        },
        rightIcon: (
          <CheckCircleOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.reports.dataManagement.tbc'),
        description: t('attributes.reports.dataManagement.globalTBCDataNote'),
      },
    ],
    [SUPER_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.IMPORT_DATA);
        },
        rightIcon: (
          <UpgradeOutlinedIcon
            style={{
              rotate: '180deg',
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.import'),
        description: t('attributes.reports.dataManagement.importDataNote'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.EXPORT_DATA);
        },
        rightIcon: (
          <UpgradeOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.export'),
        description: t('attributes.reports.dataManagement.exportDataNote'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.SAMPLE_TEMPLATES);
        },
        rightIcon: (
          <BallotOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.reports.dataManagement.templates'),
        description: t('attributes.reports.dataManagement.templatesNote'),
      },
    ],
  };

  const Cards = () => (
    <Grid container spacing="25px">
      {cardData[getUserRole()]?.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{ boxShadow: 0, borderRadius: '4px', cursor: 'pointer' }}
            onClick={card.onclick}
          >
            <CustomCardContent>
              <IconBox>
                {card.rightIcon}
                {card.leftIcon}
              </IconBox>
              <CustomTypography variant="common600">
                {card.title}
              </CustomTypography>
              <CustomTypography variant="common400">
                {card.description}
              </CustomTypography>
            </CustomCardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.reports.dataManagement.title')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};
