import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../constants/Typography';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { getCompanyList } from '../../store/company/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../store/company/reducer';
import { formattedDate } from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import CompanyForm from './CompanyForm';

const defaultValues = {
  company_name: '',
  abn: '',
  address: '',
  state: null,
  city: null,
  zip_code: '',
  logo_upload: '',
  contact_name: '',
  email: '',
  cell_phone: '',
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 441 : 369);

const Companies = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.company.company')} ${t('attributes.name')}`,
        field: 'company_name',
        sortable: true,
        flex: 1,
      },
      {
        headerName: t('attributes.customer.contact_name'),
        field: 'contact_name',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.email'),
        field: 'email',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.company.cell_phone'),
        field: 'cell_phone',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('common.city'),
        field: 'city',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('common.state'),
        field: 'state',
        flex: 1,
        sortable: false,
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: true,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setOpen(true);
              setCompanyId(row.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Redux state
  const {
    companyActiveList,
    companyInactiveList,
    size,
    page,
    total,
    totalCount,
  } = useSelector((state) => state.company.get);
  const {
    isLoading: createLoading,
    createdCompany,
    error,
  } = useSelector((state) => state.company.create);

  // Form state and methods from react-hook-form

  const method = useForm({
    defaultValues: defaultValues,
  });
  const { setError, clearErrors, watch, reset } = method;

  // Local state
  const [currentPage, setCurrentPage] = useState(page);
  const [perPageData, setPerPageData] = useState(size);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ city: null, state: null });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  // Custom hook
  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  // Function to fetch data based on search, pagination, and filter
  const getCompanies = useCallback(() => {
    // Set loading state based on active tab
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getCompanyList({
        page: currentPage,
        size: perPageData,
        search: searchText,
        active: activeTab === 0,
        country: filters.country?.value,
        state: filters.state?.value,
        city: filters.city?.value,
        orderBy: orderBy,
        order: order,
        fromDate: fromDate,
        toDate: toDate,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    currentPage,
    perPageData,
    searchText,
    activeTab,
    filters,
    order,
    orderBy,
    fromDate,
    toDate,
    columnVisibilityModel,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText, fromDate, toDate]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getCompanies, 500), [
    getCompanies,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdCompany) {
          setOpen(false);
          if (!companyId) setActiveTab(0);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !companyId
                ? t('message.company.companyCreatedSuccessfully')
                : t('message.company.companyUpdatedSuccessfully'),
            })
          );
        }
      }
    }
  }, [error, createLoading]);

  const rows =
    activeTab === 0
      ? companyActiveList?.map((cl) => ({
          ...cl,
          id: cl.id,
          uuid: cl.uuid,
          company_name: cl.company_name,
          contact_name: cl.contact_name,
          email: cl.email,
          cell_phone: cl.cell_phone,
          address: cl.address,
          city: cl.city,
          state: cl.state,
          created_at: cl.created_at,
        }))
      : companyInactiveList?.map((cl) => ({
          ...cl,
          id: cl.id,
          uuid: cl.uuid,
          company_name: cl.company_name,
          contact_name: cl.contact_name,
          email: cl.email,
          cell_phone: cl.cell_phone,
          address: cl.address,
          city: cl.city,
          state: cl.state,
          created_at: cl.created_at,
        }));

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const resetFilter = () => {
    setFilters({ state: null, city: null });
    setSearchText('');
    setFromDate(null);
    setToDate(null);
  };

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ state: null, city: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<ListAltOutlinedIcon />}
      title={t('attributes.company.companies')}
      singularText={t('attributes.company.company')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.company.fire')} ${t('attributes.company.company')}`}
    />
  );
  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters.state ||
                filters.city ||
                fromDate ||
                toDate
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('common.state')}
              options={stateList}
              width="190px"
              value={filters?.state}
              onChange={(e, newVal) =>
                setFilters({ city: null, state: newVal })
              }
            />
            <Autocomplete
              placeholder={t('common.city')}
              options={cityList}
              width="190px"
              value={filters?.city}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, city: newVal }))
              }
              // disabledDropdown={!filters.state}
            />
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFromDate(val[0]);
                setToDate(val[1]);
              }}
              onClear={() => {
                setFromDate(null);
                setToDate(null);
              }}
              fromDate={fromDate}
              toDate={toDate}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      <StyledMainWrapper
        title={t('attributes.company.companies')}
        btn={
          <>
            <CustomButton
              text={`${t('attributes.add')} ${t('attributes.company.fire')} ${t('attributes.company.company')}`}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setOpen(true);
                setCompanyId('');
                reset(defaultValues);
              }}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.COMPANY,
                    EXPORT_FILE_NAMES.COMPANY
                  );
                }
              }}
            />
          </>
        }
      >
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          totalCount > 0 && (
            <TabView
              tabs={[
                {
                  label: t('common.active'),
                  component: renderedComponent,
                },
                {
                  label: t('common.inactive'),
                  component: renderedComponent,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onTabChange={onTabChange}
            />
          )
        )}
      </StyledMainWrapper>

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !companyId
              ? `${t('attributes.add')}  ${t('attributes.company.fire')} ${t('attributes.company.company')}`
              : `${t('attributes.edit')} ${t('attributes.company.fire')} ${t('attributes.company.company')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          width={companyId ? null : SWIPEABLE_DRAWER_STEPPER_WIDTH}
        >
          <FormProvider {...method}>
            <CompanyForm
              id={companyId}
              setServerErrors={setServerErrors}
              clearErrors={clearErrors}
              setError={setError}
            />
          </FormProvider>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Companies;
