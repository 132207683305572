import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { ROUTINE_SERVICE_FREQUENCIES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

export const getRoutineServiceFrequenciesList = createAsyncThunk(
  ROUTINE_SERVICE_FREQUENCIES.GET_ROUTINE_SERVICE_FREQUENCIES,
  async (
    {
      page,
      search,
      size,
      active,
      order,
      orderBy,
      company_uuid,
      fromDate,
      toDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (company_uuid) params.append('company_uuid', company_uuid);
    if (fromDate)
      params.append(
        'from_date',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'to_date',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    params.append('type', 'standard,custom');

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.ROUTINE_SERVICE_FREQUENCIES.GET_ROUTINE_SERVICE_FREQUENCIES}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoutineServiceFrequenciesById = createAsyncThunk(
  ROUTINE_SERVICE_FREQUENCIES.GET_ROUTINE_SERVICE_FREQUENCIES_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.ROUTINE_SERVICE_FREQUENCIES.GET_UPDATE_ROUTINE_SERVICE_FREQUENCIES.replace(
          '{uuid}',
          uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateRoutineServiceFrequencies = createAsyncThunk(
  ROUTINE_SERVICE_FREQUENCIES.UPDATE_ROUTINE_SERVICE_FREQUENCIES,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.ROUTINE_SERVICE_FREQUENCIES.GET_UPDATE_ROUTINE_SERVICE_FREQUENCIES.replace(
          '{uuid}',
          uuid
        ),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRoutineServiceFrequencies = createAsyncThunk(
  ROUTINE_SERVICE_FREQUENCIES.CREATE_ROUTINE_SERVICE_FREQUENCIES,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.ROUTINE_SERVICE_FREQUENCIES.CREATE_ROUTINE_SERVICE_FREQUENCIES,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
