const supplier = [
  {
    headerName: `Name`,
    field: 'name',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Cell Phone',
    field: 'business_number',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Name',
    field: 'contact_name',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Email',
    field: 'contact_email',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Contact Phone',
    field: 'contact_phone',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Address',
    field: 'address',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Supplier Email',
    field: 'supplier_email',
    flex: 1,
    sortable: false,
  },
];

const product_catalogue = [
  {
    headerName: 'Product Name',
    field: 'product_name',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Product Type',
    field: 'type',
    sortable: false,
    flex: 1,
  },
  { headerName: 'Description', field: 'description', sortable: false, flex: 1 },
  {
    headerName: 'Cost Currency',
    field: 'cost_currency',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Sell Currency',
    field: 'sell_currency',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Sell Price Type',
    field: 'sell_price_type',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Cost Price Type',
    field: 'cost_price_type',
    sortable: false,
    flex: 1,
  },
  { headerName: 'UM', field: 'um', sortable: false },
  {
    headerName: 'Estimated Time Hours',
    field: 'estimated_time_hours',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Cost Price',
    field: 'cost',
    sortable: false,
  },
  {
    headerName: 'Default Sell Price',
    field: 'sell_price',
    sortable: false,
    minWidth: 140,
  },
  { headerName: 'Status', field: 'status', sortable: false, flex: 1 },
];

const customer = [
  {
    headerName: 'Customer Name',
    field: 'customer_name',
    sortable: false,
    minWidth: 130,
  },
  {
    headerName: 'Customer ABN Number',
    field: 'customer_abn_number',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Customer Email',
    field: 'customer_email',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Categories',
    field: 'categories',
    sortable: false,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    sortable: false,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    sortable: false,
  },
  {
    headerName: 'Agreement Signed Date',
    field: 'agreement_signed_date',
    sortable: false,
    minWidth: 185,
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: false,
  },
  {
    headerName: 'Company Name',
    field: 'company_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Billing Contact Name',
    field: 'billing_contact_name',
    sortable: false,
    minWidth: 170,
  },
  {
    headerName: 'Email To',
    field: 'email_to',
    sortable: false,
  },
  {
    headerName: 'Fixed Price Contract',
    field: 'fixed_price_contract',
    sortable: false,
  },
  {
    headerName: 'Manual Billing Required',
    field: 'manual_billing_required',
    sortable: false,
    minWidth: 190,
  },
  {
    headerName: 'Quoting Contact Name',
    field: 'quoting_contact_name',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Quoting Email To',
    field: 'quoting_email_to',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Primary Contact Name',
    field: 'primary_contact_name',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Primary Contact Email',
    field: 'primary_contact_email',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Primary Contact Phone',
    field: 'primary_contact_phone',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Primary Address',
    field: 'primary_address',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Primary State',
    field: 'primary_state',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Primary City',
    field: 'primary_city',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Primary Postcode',
    field: 'primary_postcode',
    sortable: false,
    minWidth: 140,
  },
];

const property = [
  {
    headerName: 'Property Name',
    field: 'property_name',
    sortable: false,
    minWidth: 130,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'Country',
    field: 'country',
    sortable: false,
  },
  {
    headerName: 'Zip Code',
    field: 'zip_code',
    sortable: false,
  },
  {
    headerName: 'Customer Reference',
    field: 'customer_reference',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'OCSP Number',
    field: 'ocsp_number',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Organization',
    field: 'organization',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Internal Note',
    field: 'internal_note',
    sortable: false,
    minWidth: 110,
  },
  {
    headerName: 'AGM Date',
    field: 'agm_date',
    sortable: false,
  },
  {
    headerName: 'Review Date',
    field: 'review_date',
    sortable: false,
    minWidth: 110,
  },
  {
    headerName: 'Lot Number',
    field: 'lot_number',
    sortable: false,
  },
  {
    headerName: 'Customer Email',
    field: 'customer_email',
    sortable: false,
    minWidth: 135,
  },
  {
    headerName: 'Latitude',
    field: 'latitude',
    sortable: false,
  },
  {
    headerName: 'Longitude',
    field: 'longitude',
    sortable: false,
  },
  {
    headerName: 'Zone',
    field: 'zone',
    sortable: false,
  },
  {
    headerName: 'Is Booking Required',
    field: 'is_booking_required',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Annual Compliance Date',
    field: 'annual_compliance_date',
    sortable: false,
    minWidth: 190,
  },
  {
    headerName: 'Contract Start Date',
    field: 'contract_start_date',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Contract Expiry Date',
    field: 'contract_expiry_date',
    sortable: false,
    minWidth: 165,
  },
  {
    headerName: 'Technician Email',
    field: 'technician_email',
    sortable: false,
    minWidth: 160,
  },
];

const technician = [
  {
    headerName: 'Technician Name',
    field: 'technician_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Technician Email',
    field: 'technician_email',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Company Name',
    field: 'company_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Notes',
    field: 'notes',
    sortable: false,
  },
  {
    headerName: 'Skills',
    field: 'skills',
    sortable: false,
  },
  {
    headerName: 'Service Areas',
    field: 'service_areas',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Zones',
    field: 'zones',
    sortable: false,
  },
  {
    headerName: 'Categories',
    field: 'categories',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Contact Name',
    field: 'contact_name',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Contact Email',
    field: 'contact_email',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Contact Phone',
    field: 'contact_phone',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Address',
    field: 'address',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'City',
    field: 'city',
    sortable: false,
  },
  {
    headerName: 'State',
    field: 'state',
    sortable: false,
  },
  {
    headerName: 'Postcode',
    field: 'postcode',
    sortable: false,
    minWidth: 120,
  },
];

const asset = [
  {
    headerName: 'ID',
    field: 'id',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Location',
    field: 'location',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Value',
    field: 'value',
    sortable: false,
    flex: 1,
  },
];

export const COLUMNS = {
  supplier,
  product_catalogue,
  customer,
  property,
  technician,
  asset,
};
