import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

import { DIVIDER_COLOR, PRIMARY } from '../../constants/Colors';
import { FAQS_DATA } from '../../data/faqs';
import { loggedInUserRole } from '../../utils';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const HelpAndSupport = () => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [expandedPanels, setExpandedPanels] = useState([]);

  const faqsForRole = FAQS_DATA[loggedInUserRole()] || [];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
    );
  };

  const searchedFaqs = faqsForRole.filter((faq) => {
    const titleMatch = faq.title
      .toLowerCase()
      .includes(searchText.toLowerCase());

    const answerMatch =
      typeof faq.answer === 'string'
        ? faq.answer.toLowerCase().includes(searchText.toLowerCase())
        : Object.entries(faq.answer).some(
            ([key, value]) =>
              key.toLowerCase().includes(searchText.toLowerCase()) ||
              value.toLowerCase().includes(searchText.toLowerCase())
          );

    return titleMatch || answerMatch;
  });

  return (
    <StyledMainWrapper title="Help And Supports">
      <Box sx={{ display: 'flex', width: '100%', height: '85.5vh' }}>
        {/* Sidebar Section */}
        <Box sx={{ height: '100%', width: '30%', padding: '35px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '57px',
              width: '57px',
              bgcolor: '#95C02021',
              borderRadius: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50px',
                width: '50px',
                bgcolor: '#95C02033',
                borderRadius: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '42px',
                  width: '42px',
                  bgcolor: PRIMARY,
                  borderRadius: '100%',
                }}
              >
                <SupportAgentOutlinedIcon
                  style={{ height: '25px', width: '25px', color: 'white' }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingTop: '10px' }}>
            <Typography variant="body1">
              {t('common.still_need_a_support')}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: '20px' }}>
            <Box
              sx={{
                bgcolor: DIVIDER_COLOR,
                padding: '8px 16px',
                borderRadius: '4px',
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <PhoneOutlinedIcon style={{ height: '26px', width: '26px' }} />
              <Box>
                <Typography variant="body1">{t('common.call_on')}</Typography>
                <Typography variant="body1">
                  <Link to="tel:+610755108951">+61 07 5510 8951</Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ paddingTop: '10px' }}>
            <Box
              sx={{
                bgcolor: DIVIDER_COLOR,
                padding: '8px 16px',
                borderRadius: '4px',
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <EmailOutlinedIcon style={{ height: '26px', width: '26px' }} />
              <Box>
                <Typography variant="body1">{t('common.email_at')}</Typography>
                <Typography variant="body1">
                  <Link to="mailto:info@fireora.group">info@fireora.group</Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider orientation="vertical" flexItem />

        {/* FAQ Section */}
        <Box
          sx={{
            height: '100%',
            width: '70%',
            padding: '35px',
            overflowY: 'auto', // Make the FAQ section scrollable
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h4">{t('common.faq_title')}</Typography>
            </Box>
            <Box>
              <CustomSearch
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                height="36px"
              />
            </Box>
          </Box>

          <Box sx={{ paddingTop: '30px' }}>
            {searchedFaqs.length === 0 ? (
              <Typography variant="body1">
                {t('common.no_matching_results_found')}
              </Typography>
            ) : (
              searchedFaqs.map((faq) => (
                <Accordion
                  key={faq.id}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid black',
                    boxShadow: 'none',
                    marginBottom: 2,
                    borderRadius: '10px !important',
                    position: 'inherit',
                  }}
                  expanded={expandedPanels.includes(faq.id)}
                  onChange={handleChange(faq.id)}
                >
                  <AccordionSummary
                    expandIcon={
                      expandedPanels.includes(faq.id) ? (
                        <RemoveCircleOutlineIcon style={{ color: 'black' }} />
                      ) : (
                        <AddCircleOutlineIcon style={{ color: 'black' }} />
                      )
                    }
                    aria-controls={`panel${faq.id}-content`}
                    id={`panel${faq.id}-header`}
                  >
                    <HelpOutlineIcon
                      style={{
                        height: '24px',
                        width: '24px',
                        color: PRIMARY,
                      }}
                    />
                    <Typography variant="body1" sx={{ paddingLeft: '8px' }}>
                      {faq.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body2"
                      sx={{
                        paddingLeft:
                          typeof faq.answer === 'object' &&
                          !Array.isArray(faq.answer)
                            ? '10px'
                            : '33px',
                      }}
                    >
                      {typeof faq.answer === 'object' &&
                      !Array.isArray(faq.answer) ? (
                        <ul>
                          {Object.entries(faq.answer).map(
                            ([key, value], index) => (
                              <li key={index}>
                                <strong>{key}:</strong> {value}
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <span>{faq.answer}</span>
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </StyledMainWrapper>
  );
};

export default HelpAndSupport;
