import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { isArray } from 'lodash';

import { exportToCSV } from '../../constants/common';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../constants/Typography';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { getBillingList } from '../../store/billing/api';
import { resetBillingData } from '../../store/billing/reducer';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getBuildingClassList,
  getBuildingEraList,
  getBuildingTypeList,
  getConstructionClassList,
  getLocalGovernmentAreaList,
  getParentPropertyList,
  getProperty,
  getPropertyAccessProcedureList,
  getPropertyAccessScheduleList,
  getPropertyCustomerList,
  getPropertyList,
  getPropertyStatusList,
  getPropertyTagsList,
} from '../../store/property/api';
import {
  resetCreatePropertyList,
  resetUpdatePropertyList,
} from '../../store/property/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getBillingContractById } from '../../store/users/billingContract/api';
import { getZonesDropdownList } from '../../store/zones/api';
import { debounce, formattedDate } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import PropertyDetails from './PropertyDetails';
import { PropertyForm } from './PropertyForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 428 : 300);

// Property Component
const Properties = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    propertyList,
    createPropertyList,
    updatePropertyList,
    propertyStatusList,
    propertyTagList,
    propertyAccessScheduleList,
    propertyAccessProcedureList,
    propertyBuildingEraList,
    propertyBuildingClassList,
    propertyConstructionClassList,
    propertyBuildingTypeList,
    propertyLocalGovernmentAreaList,
    parentPropertyList,
    propertyCustomerList,
  } = useSelector((state) => state.property);

  // Selector for billing dropdown
  const { billingDropdownLoading, billingDropdownData } = getDropdownListHook({
    reducerName: 'billing',
    dropdownListName: 'getList',
    fetchDataFrom: 'billingList',
    labelName: 'billing_name',
    valueName: 'uuid',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [selectStatus, setSelectStatus] = useState(null);
  const [selectTags, setSelectTags] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [selectBillingCard, setSelectBillingCard] = useState(null);
  const [formMode, setFormMode] = useState('create');
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [open, setOpen] = useState(false);
  const [propertiesData, setPropertiesData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [formStatusList, setFormStatusList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [accessScheduleList, setAccessScheduleList] = useState([]);
  const [accessProcedureList, setAccessProcedureList] = useState([]);
  const [buildingEraList, setBuildingEraList] = useState([]);
  const [buildingClassList, setBuildingClassList] = useState([]);
  const [constructionClassList, setConstructionClassList] = useState([]);
  const [selectConstructionClass, setSelectConstructionClass] = useState(null);
  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [localGovermentAreaList, setLocalGovernmentAreaList] = useState([]);
  const [parentPropertyListData, setParentPropertyListData] = useState([]);
  const [selectParentProperty, setSelectParentProperty] = useState(null);
  const methods = useForm();
  const { clearErrors, setError, watch, setValue } = methods;
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [propertyDataloading, setPropertyDataLoading] = useState(
    uuid ? true : false
  );
  const [activeStep, setActiveStep] = useState(0);
  const [isFromDetails, setIsFromDetails] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [propertyNumId, setPropertyNumId] = useState();
  const [selectedRows, setSelectedRows] = useState(null);

  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  // Use the custom hook
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(
      Object.values(watchedFields).some((value) => {
        if (isArray(value)) {
          return value.length > 0;
        } else {
          return value;
        }
      })
    );
  }, [watchedFields]);

  useEffect(() => {
    dispatch(getZonesDropdownList());
  }, []);

  const fetchPropertyDetails = async (id) => {
    setPropertyDataLoading(true);
    try {
      const response = await dispatch(getProperty(id));
      const { payload } = response;
      const { contract_assignment_details } = payload?.data?.[0] || {};
      const activeBillingContractUUID =
        contract_assignment_details?.active_billing_contract_uuid;
      const billingContractResponse = await dispatch(
        getBillingContractById({
          billing_contract_uuid: activeBillingContractUUID,
        })
      );
      const {
        name: contractName,
        contract_start_date: contractStartDate,
        contract_end_date: contractEndDate,
      } = billingContractResponse?.payload?.data?.[0] || {};

      setPropertyNumId(payload?.data?.[0]?.id || '-');
      if (payload.data[0]) {
        setSelectedProperty({
          ...payload.data[0],
          contract_assignment_details: {
            ...contract_assignment_details,
            contract_name: contractName,
            contract_start_date: contractStartDate,
            contract_end_date: contractEndDate,
          },
        });
      } else {
        setSelectedProperty(null);
      }
    } catch (error) {
      console.error('Error fetching property data:', error);
    } finally {
      setPropertyDataLoading(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchPropertyDetails(uuid);
    }
  }, [uuid]);

  const columns = useMemo(() => {
    const baseColumn = [
      {
        headerName: t('attributes.property.propertyId'),
        field: 'pid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.property.property')} ${t('attributes.name')}`,
        field: 'property_name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedProperty(null);
              setPropertyDataLoading(true);
              setPropertyNumId(row.id);
              navigate(row.uuid);
            }}
          >
            {row.property_name}
          </Typography>
        ),
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.customer.customer'),
        field: 'customer_uuid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.property.status'),
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.property_status.display_name),
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: true,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                setSelectedProperty(null);
                setPropertyDataLoading(true);
                setPropertyNumId(row.id);
                navigate(row.uuid);
              }}
            />
            <Edit
              onClick={() => {
                setSelectedProperty(null);
                setOpen(true);
                setFormMode('edit');
                setIsFromDetails(false);
                dispatch(getProperty(row.uuid)).then((response) => {
                  setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
                });
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumn;
  }, []);

  const getAllProperties = (text = '') => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');
    const selectedTags =
      selectTags.length > 0
        ? selectTags.map((item) => item.value).join(',')
        : null;

    setIsDataLoading(true);
    const req = {
      page: currentPage,
      size: perPageData,
      status: selectStatus?.value,
      tags: selectedTags,
      constructionClass: selectConstructionClass?.value,
      customerUuid: selectCustomer?.value,
      parentProperty: selectParentProperty?.value,
      billingCard: selectBillingCard?.value,
      search: text,
      fromDate: fromDate,
      toDate: toDate,
      order: order,
      orderBy: orderBy,
      list_column_names: visibleFieldsString,
    };

    dispatch(getPropertyList(req)).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getAllProperties(searchText);
  }, [
    currentPage,
    perPageData,
    selectStatus,
    selectCustomer,
    selectTags,
    selectConstructionClass,
    createPropertyList.data,
    updatePropertyList.data,
    selectParentProperty,
    selectBillingCard,
    order,
    orderBy,
    fromDate,
    toDate,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectStatus,
    selectCustomer,
    searchText,
    selectTags,
    selectConstructionClass,
    fromDate,
    toDate,
  ]);

  const debounceSearch = useCallback(
    debounce((text) => {
      getAllProperties(text);
    }, 500),
    []
  );

  const handleStatus = (status, color) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          height: '16px',
          width: '16px',
          borderRadius: '50%',
        }}
      ></Box>
      <Typography
        sx={{
          color: color,
          fontSize: '12px',
          weight: '600',
          marginLeft: '8px',
        }}
      >
        {status}
      </Typography>
    </Box>
  );

  const formatStatus = (status) => {
    if (status?.toLowerCase() == 'inactive') {
      return handleStatus(status, '#C54036');
    }
    if (status?.toLowerCase() == 'active') {
      return handleStatus(status, '#95C020');
    }
    if (status?.toLowerCase() == 'hold') {
      return handleStatus(status, '#FD7E14');
    }
    if (status?.toLowerCase() == 'setup') {
      return handleStatus(status, '#007BFF');
    }
  };

  useEffect(
    () => () => {
      dispatch(resetUpdatePropertyList());
      dispatch(resetCreatePropertyList());
    },
    []
  );

  // Status Filter Dropdown list data

  useEffect(() => {
    dispatch(getPropertyStatusList());
    dispatch(getTechnicianNamesList());
  }, [dispatch]);

  // Set data for filter dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Set data for form dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setFormStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Property tags dropdown data
  useEffect(() => {
    dispatch(getPropertyTagsList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyTagList?.data) {
      const tagsList = propertyTagList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setTagsList(tagsList);
    }
  }, [propertyTagList, dispatch]);

  // Property access schedule dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessScheduleList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessScheduleList?.data) {
      const accessSchedule = propertyAccessScheduleList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessScheduleList(accessSchedule);
    }
  }, [propertyAccessScheduleList, dispatch]);

  // Property access procedure dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessProcedureList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessProcedureList?.data) {
      const accessProcedure = propertyAccessProcedureList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessProcedureList(accessProcedure);
    }
  }, [propertyAccessProcedureList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingEraList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingEraList?.data) {
      const buildinEra = propertyBuildingEraList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setBuildingEraList(buildinEra);
    }
  }, [propertyBuildingEraList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingClassList?.data) {
      const buildinClass = propertyBuildingClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingClassList(buildinClass);
    }
  }, [propertyBuildingClassList, dispatch]);

  // Property construction list dropdown data
  useEffect(() => {
    dispatch(getConstructionClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyConstructionClassList?.data) {
      const constructionClass = propertyConstructionClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setConstructionClassList(constructionClass);
    }
  }, [propertyConstructionClassList, dispatch]);

  // Property building list dropdown data
  useEffect(() => {
    dispatch(getBuildingTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingTypeList?.data) {
      const buildingTypeList = propertyBuildingTypeList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingTypeList(buildingTypeList);
    }
  }, [propertyBuildingTypeList, dispatch]);

  // Property local government area dropdown data
  useEffect(() => {
    dispatch(getLocalGovernmentAreaList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyLocalGovernmentAreaList?.data) {
      const localGArea = propertyLocalGovernmentAreaList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setLocalGovernmentAreaList(localGArea);
    }
  }, [propertyLocalGovernmentAreaList, dispatch]);

  // Parent property dropdown data
  useEffect(() => {
    dispatch(getParentPropertyList());
  }, [dispatch]);

  useEffect(() => {
    if (parentPropertyList?.data) {
      const parentProperty = parentPropertyList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setParentPropertyListData(parentProperty);
    }
  }, [parentPropertyList, dispatch]);

  // Property Customer dropdown data
  useEffect(() => {
    dispatch(getPropertyCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyCustomerList?.data) {
      const customersList = propertyCustomerList?.data?.data?.map((item) => ({
        label: item.customer_name,
        value: item.uuid,
      }));

      setCustomerList(customersList);
    }
  }, [propertyCustomerList, dispatch]);

  // Property billing card dropdown data
  useEffect(() => {
    dispatch(
      getBillingList({
        limit: -1,
        customer_uuid: selectCustomer?.value || null,
      })
    );

    return () => {
      dispatch(resetBillingData());
    };
  }, [dispatch, selectCustomer]);

  useEffect(() => {
    if (propertyList.data) {
      const updatedData = propertyList.data.data?.map((item) => {
        const updatedItem = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key] === ('' || null) ? '-' : item[key];

          return acc;
        }, {});

        updatedItem.tags = item?.property_tags?.map(
          (tag) => tag.tags.display_name
        );
        updatedItem.access_schedule =
          item?.access_details?.[0]?.access_detail_schedule?.map(
            (schedule) => schedule?.schedules?.display_name
          ) || [];

        updatedItem.access_procedure =
          item?.access_details?.[0]?.access_detail_procedure?.map(
            (procedure) => procedure?.procedures?.display_name
          ) || [];

        updatedItem.access_code = item?.access_details?.[0]?.access_code || '';
        updatedItem.access_note = item?.access_details?.[0]?.access_note || '';

        updatedItem.signature_required =
          item?.site_requirements?.[0]?.signature_required || false;
        updatedItem.induction_required =
          item?.site_requirements?.[0]?.induction_required || false;
        updatedItem.log_book_entry_required =
          item?.site_requirements?.[0]?.log_book_entry_required || false;
        updatedItem.police_check_required =
          item?.site_requirements?.[0]?.police_check_required || false;
        updatedItem.working_with_children_check_required =
          item?.site_requirements?.[0]?.working_with_children_check_required ||
          false;
        updatedItem.service_requirements =
          item?.site_requirements?.[0]?.service_requirements || false;
        updatedItem.technician_requirements =
          item?.site_requirements?.[0]?.technician_requirements || false;

        updatedItem.annual_due_certification_date =
          item?.building_attributes?.[0]?.annual_due_certification_date || '';
        updatedItem.property_building_era =
          item?.building_attributes?.[0]?.property_building_era?.display_name ||
          '';
        updatedItem.property_building_class =
          item?.building_attributes?.[0]?.property_building_class
            ?.display_name || '';
        updatedItem.property_building_type =
          item?.building_attributes?.[0]?.property_building_type
            ?.display_name || '';
        updatedItem.building_size =
          item?.building_attributes?.[0]?.building_size || '';
        updatedItem.stories_above_ground =
          item?.building_attributes?.[0]?.stories_above_ground || '';
        updatedItem.property_construction_class =
          item?.building_attributes?.[0]?.property_construction_class
            ?.display_name || '';
        updatedItem.building_part =
          item?.building_attributes?.[0]?.building_part || '';
        updatedItem.building_tenancies_no =
          item?.building_attributes?.[0]?.building_tenancies_no || '';
        updatedItem.property_building_standard =
          item?.building_attributes?.[0]?.property_building_standard
            ?.standard_display_name || '';

        updatedItem.op_number = item?.occupancy_permit?.[0]?.op_number || '';
        updatedItem.op_date = item?.occupancy_permit?.[0]?.op_date || '';
        updatedItem.op_location =
          item?.occupancy_permit?.[0]?.op_location || '';

        updatedItem.customer_uuid = item.customer;
        updatedItem.account_manager_uuid =
          item.property_account_manager?.display_name;
        updatedItem.status = item.property_status.display_name;
        updatedItem.created_at = item.created_at;

        return updatedItem;
      });

      setPropertiesData(updatedData);
    }
  }, [propertyList, dispatch]);

  useEffect(() => {
    if (createPropertyList.loading !== null) {
      if (createPropertyList.error) {
        if (createPropertyList.error.errorDetails) {
          setServerErrors(createPropertyList.error.errorDetails);
        }
      } else {
        if (createPropertyList.data) {
          setOpen(false);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createPropertyList.data.message,
            })
          );
        }
      }
    }
  }, [createPropertyList.data, createPropertyList.error]);

  useEffect(() => {
    if (updatePropertyList.loading !== null) {
      if (updatePropertyList.error) {
        if (updatePropertyList.error.errorDetails) {
          setServerErrors(updatePropertyList.error.errorDetails);
        }
      } else {
        if (updatePropertyList.data) {
          setPropertyDataLoading(true);
          setOpen(false);
          if (uuid) {
            fetchPropertyDetails(updatePropertyList.data?.data[0]?.uuid);
          }
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatePropertyList.data.message,
            })
          );
        }
      }
    }
  }, [updatePropertyList.data, updatePropertyList.error]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(
      propertiesData?.filter((row) => newSelection.includes(row.id))
    );
  };

  const noData = (
    <NewNoDataPage
      icon={<HomeWorkOutlinedIcon />}
      title={t('attributes.property.property')}
      createBtnText={`${t('attributes.add')} ${t('attributes.property.property')}`}
      singularText={t('attributes.property.property')}
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const onResetFilter = () => {
    setSelectCustomer(null);
    setSelectStatus(null);
    setSelectTags([]);
    setFromDate(null);
    setToDate(null);
    setSearchText('');
    setSelectBillingCard(null);
    setSelectConstructionClass(null);
    setSelectParentProperty(null);
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
          debounceSearch(e.target.value);
        }}
        isActive={isFilterComponentVisible}
        onResetFilter={onResetFilter}
        isResetButtonVisible={
          selectStatus ||
          selectCustomer ||
          selectTags.length > 0 ||
          fromDate ||
          toDate ||
          selectConstructionClass ||
          selectParentProperty
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.property.status')}
            options={statusList}
            value={selectStatus}
            onChange={(e, newValue) => setSelectStatus(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={t('attributes.customer.customer')}
            options={customerList}
            value={selectCustomer}
            onChange={(e, newValue) => setSelectCustomer(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={`${t('attributes.property.parent')} ${t('attributes.property.property')}`}
            options={parentPropertyListData}
            value={selectParentProperty}
            onChange={(e, newValue) => setSelectParentProperty(newValue)}
            width="190px"
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
          <Autocomplete
            placeholder={t('attributes.property.billingCard')}
            options={billingDropdownData}
            value={selectBillingCard}
            onChange={(e, newValue) => setSelectBillingCard(newValue)}
            width="190px"
            isLoadingData={billingDropdownLoading}
          />
          <Autocomplete
            placeholder={t('attributes.property.tags')}
            options={tagsList}
            value={selectTags}
            handleMultipleFilter={true}
            multiple={true}
            width="190px"
            onChange={(e, newValue) => setSelectTags(newValue)}
          />
          <Autocomplete
            placeholder={t('attributes.property.constructionClass')}
            options={constructionClassList}
            value={selectConstructionClass}
            width="190px"
            onChange={(e, newValue) => setSelectConstructionClass(newValue)}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={propertiesData}
        total={propertyList.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={t('attributes.property.property')}
      btn={
        <>
          <CustomButton
            text={`${t('attributes.add')} ${t('attributes.property.property')}`}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              setOpen(true);
              setFormMode('create');
              setActiveStep(0);
            }}
          />
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.PROPERTY,
                  EXPORT_FILE_NAMES.PROPERTY,
                  COLUMNS_TO_MAP.PROPERTY
                );
              }
            }}
          />
        </>
      }
    >
      <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
        {!isDataLoading && propertyList?.totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading && propertyList?.totalCount > 0 && renderedComponent
        )}
      </Box>
    </StyledMainWrapper>
  );

  const handleEditPropertyForm = (step) => {
    setIsFromDetails(true);
    setActiveStep(step);
    setOpen(true);
    setFormMode('edit');
    dispatch(getProperty(uuid)).then((response) => {
      setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
    });
  };

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`);
    setIsFilterComponentVisible(false);
    onResetFilter();
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {!uuid ? (
        mainWrapper
      ) : (
        <PropertyDetails
          propertyId={uuid}
          propertyNumId={propertyNumId}
          selectedProperty={selectedProperty}
          propertyDataloading={propertyDataloading}
          handleEditPropertyForm={handleEditPropertyForm}
          handleDefaultStep={handleDefaultStep}
        />
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            formMode === 'create'
              ? `${t('attributes.add')} ${t('attributes.property.property')}`
              : `${t('attributes.edit')} ${t('attributes.property.property')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          width={SWIPEABLE_DRAWER_STEPPER_WIDTH}
        >
          <FormProvider {...methods}>
            <PropertyForm
              formMode={formMode}
              propertyData={selectedProperty}
              formStatusList={formStatusList}
              tagsList={tagsList}
              accessScheduleList={accessScheduleList}
              accessProcedureList={accessProcedureList}
              buildingEraList={buildingEraList}
              buildingClassList={buildingClassList}
              constructionClassList={constructionClassList}
              buildingTypeList={buildingTypeList}
              localGovermentAreaList={localGovermentAreaList}
              parentPropertyListData={parentPropertyListData}
              customerList={customerList}
              watch={watch}
              setValue={setValue}
              isOpen={open}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isFromDetails={isFromDetails}
              zoneList={zonesDropdownData}
              isZoneLoading={zonesDropdownLoading}
              setServerErrors={setServerErrors}
              createLoading={createPropertyList.loading}
              updateLoading={updatePropertyList.loading}
              setSelectedProperty={setSelectedProperty}
            />
          </FormProvider>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Properties;
