import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, styled } from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CommonCarouselView = ({
  widthInPercentage = 25,
  children,
  totalItems,
  arrowSize = '12px',
  arrowWrapperSize = '20px',
  arrowLeft = 0,
  arrowRight = 0,
  isModal = false,
  ...rest
}) => {
  const CustomStyledArrow = styled(Box)(({ theme, left, right, disabled }) => ({
    height: arrowWrapperSize,
    width: arrowWrapperSize,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: disabled ? 'none' : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left,
    right,
    zIndex: 9999,
  }));

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentModalSlide, setCurrentModalSlide] = useState(0);

  const handleArrowClick = (direction) => {
    setCurrentSlide((prevSlide) => {
      const maxSlides = totalItems || 0;

      if (maxSlides === 0) return prevSlide;

      if (direction === 'prev') {
        if (prevSlide === maxSlides - 1) return maxSlides - 3;
        if (prevSlide === 2) return 0;

        return prevSlide - 1;
      } else {
        if (prevSlide === 0) return 2;
        if (prevSlide === maxSlides - 3) return prevSlide + 2;

        return prevSlide + 1;
      }
    });
  };

  const modalHandleArrowClick = (value) => {
    setCurrentModalSlide((prevSlide) =>
      value === 'prev' ? prevSlide - 1 : prevSlide + 1
    );
  };

  const CustomPrevArrow = ({ onClick, disabled }) => (
    <CustomStyledArrow left={arrowLeft} onClick={onClick} disabled={disabled}>
      <ArrowBackIosIcon
        style={{
          height: arrowSize,
          width: arrowSize,
          fill: 'white',
        }}
      />
    </CustomStyledArrow>
  );

  const CustomNextArrow = ({ onClick, disabled }) => (
    <CustomStyledArrow right={arrowRight} onClick={onClick} disabled={disabled}>
      <ArrowForwardIosIcon
        style={{
          height: arrowSize,
          width: arrowSize,
          fill: 'white',
        }}
      />
    </CustomStyledArrow>
  );

  return (
    <Carousel
      renderArrowPrev={() => (
        <CustomPrevArrow
          onClick={() =>
            isModal ? modalHandleArrowClick('prev') : handleArrowClick('prev')
          }
          disabled={isModal ? currentModalSlide === 0 : currentSlide === 0}
        />
      )}
      renderArrowNext={() => (
        <CustomNextArrow
          onClick={() =>
            isModal ? modalHandleArrowClick('next') : handleArrowClick('next')
          }
          disabled={
            isModal
              ? currentModalSlide === totalItems - 1
              : currentSlide === totalItems - 1
          }
        />
      )}
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      centerMode
      centerSlidePercentage={widthInPercentage}
      selectedItem={isModal ? currentModalSlide : currentSlide}
      {...rest}
    >
      {children}
    </Carousel>
  );
};

export default CommonCarouselView;
