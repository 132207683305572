import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { GET_CARD_STATUS_DATA, SUPER_ADMIN } from '../actionPrefix';
import {
  axiosInstanceAuthAndFilter,
  axiosInstanceBilling,
  axiosInstanceTechnician,
  axiosPropertyUrl,
} from '../axios';

export const getSuperAdminList = createAsyncThunk(
  SUPER_ADMIN.GET_SUPER_ADMINS,
  async (
    {
      page,
      search,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      company,
      fromDate,
      toDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (limit) params.append('items_per_page', limit);
    if (typeof active === 'boolean') params.append('active', active); // For boolean, explicitly check
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (company) params.append('company_name', company);
    if (fromDate)
      params.append(
        'from_date',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'to_date',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceAuthAndFilter.get(
        `${APIs.SUPER_ADMIN.GET_SUPER_ADMINS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSuperAdminById = createAsyncThunk(
  SUPER_ADMIN.GET_SUPER_ADMIN_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.get(
        APIs.SUPER_ADMIN.GET_UPDATE_SUPER_ADMINS.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSuperAdmin = createAsyncThunk(
  SUPER_ADMIN.UPDATE_SUPER_ADMIN,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.put(
        APIs.SUPER_ADMIN.GET_UPDATE_SUPER_ADMINS.replace('{uuid}', uuid),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSuperAdmin = createAsyncThunk(
  SUPER_ADMIN.CREATE_SUPER_ADMIN,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceAuthAndFilter.post(
        APIs.SUPER_ADMIN.CREATE_SUPER_ADMINS,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Charts API
export const getPropertyChartData = createAsyncThunk(
  SUPER_ADMIN.GET_PROPERTY_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.SUPER_ADMIN.GET_PROPERTY_CHART_DATA
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getDefectsChartData = createAsyncThunk(
  SUPER_ADMIN.GET_DEFECT_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.SUPER_ADMIN.GET_DEFECT_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getQoutesChartData = createAsyncThunk(
  SUPER_ADMIN.GET_QUOTES_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.SUPER_ADMIN.GET_QUOTES_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getPurchaseOrderChartData = createAsyncThunk(
  SUPER_ADMIN.GET_PURCHASE_ORDER_DASHBOARD_CHART,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.SUPER_ADMIN.GET_PURCHASE_ORDER_DASHBOARD_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getDashboardInvoice = createAsyncThunk(
  GET_CARD_STATUS_DATA,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.INVOICE.GET_CARD_STATUS_DATA
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
