import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography } from '@mui/material';

import { getDefectQuote } from '../../../store/workOrder/api';
import { formatStatus } from '../../../utils';
import CommonCarouselView from '../../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value }) => (
  <Box>
    <Typography variant="body1">{name}</Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const DefectsQuotesView = ({ work_order_id, defect_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const [defect, setDefect] = useState(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (work_order_id && defect_id) {
      setIsLoading(true);
      dispatch(
        getDefectQuote({ work_order_id: work_order_id, defect_id: defect_id })
      )
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setIsLoading(false));
    }

    return () => {
      setImgSrc(null);
    };
  }, [work_order_id, defect_id]);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">{`${t('attributes.work_order.defect')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            width: '100%',
          }}
        >
          <KeyValueComponent
            name={t('attributes.work_order.title')}
            value={defect?.defect_title || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.severity')}
            value={formatStatus(defect?.severity) || '-'}
          />
          <KeyValueComponent
            name={t('attributes.productCatalogue.description')}
            value={defect?.defect_description || '-'}
          />
          <KeyValueComponent
            name={t('attributes.work_order.resolution')}
            value={defect?.short_resolution || '-'}
          />
        </Box>
      </Card>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">
            {t('attributes.work_order.photos')}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            padding: '16px',
            flexWrap: 'wrap',
          }}
        >
          {defect?.documents?.length ? (
            <CommonCarouselView
              totalItems={defect?.documents?.length}
              centerMode={true}
            >
              {defect?.documents?.map((doc, index) => (
                <ImageView
                  index={index}
                  uuid={doc?.document_uuid}
                  onClick={() => setOpen(true)}
                  imgSrc={imgSrc}
                  setImgSrc={setImgSrc}
                />
              ))}
            </CommonCarouselView>
          ) : (
            <>No Document Uploaded</>
          )}
        </Box>
      </Card>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={defect?.documents?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
            >
              {imgSrc?.map((img) => imageModalView(img))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </Box>
  );
};

export default DefectsQuotesView;
