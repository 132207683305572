import { createSlice } from '@reduxjs/toolkit';

import { getUsersList } from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  usersList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    get: { ...initialGetData },
  },
  reducers: {
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.get.isLoading = false;
        state.get.usersList = data;
        state.get.total = pagination.total_items;
        state.get.totalCount = pagination.total_count;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      });
  },
});

export const { resetPagination } = userManagementSlice.actions;

export default userManagementSlice.reducer;
