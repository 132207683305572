import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { DOCUMENT_IMPORT_STATUS_LIST } from '../../../constants/Constants';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  downloadImportedFile,
  getDocumentList,
} from '../../../store/document/api';
import { formattedDate } from '../../../utils';
import { Download } from '../ActionComponent';
import Autocomplete from '../AutoComplete';
import CustomButton from '../CustomButton';
import CustomDateRangePicker from '../CustomDateRangePicker';
import CustomGridTable from '../CustomGridTable';
import { FilterComponent, FilterSection } from '../FilterComponent';
import MainWrapper from '../MainWrapper';
import NoRecordFound from '../NoDataPage/NoRecordFound';
import StatusLabel from '../StatusLabel';
import StyledMainWrapper from '../StyledMainWrapper';
import ImportStepper from './ImportStepper';

//default filters
const defaultFilters = {
  status: null,
  dateRange: {
    fromData: null,
    toDate: null,
  },
};

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const ImportData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // states
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [importStart, setImportStart] = useState(false);

  useEffect(() => {
    setImportStart(false);
    debouncedFetchData();
  }, [location]);

  // api states
  const { documentList, total } = useSelector((state) => state.document.get);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: `${t('attributes.reports.importData.batchId')}`,
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => `B-${row.id}`,
      },
      {
        field: 'file_type',
        headerName: `${t('attributes.reports.importData.fileType')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'file_name',
        headerName: `${t('attributes.reports.importData.fileName')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'results',
        headerName: `${t('attributes.reports.importData.result')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'current_status',
        headerName: `${t('attributes.reports.importData.currentStatus')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.current_status),
      },
      {
        field: 'created_at',
        headerName: `${t('attributes.reports.importData.createdAt')}`,
        flex: 1,
      },
      {
        field: 'edit',
        headerName: `${t('attributes.actions')}`,
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Download
            onClick={() => {
              dispatch(downloadImportedFile(row?.uuid)).then((res) => {
                const data = res?.payload;

                if (data.status === 200 && data.data?.length > 0) {
                  const fileUrl = data.data[0].pre_signed_url;
                  // Create a temporary anchor element to trigger the download
                  const link = document.createElement('a');

                  link.href = fileUrl;
                  link.download = data.data[0].file_name || 'download.csv'; // Specify the file name
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } else {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t(
                        'attributes.reports.dataManagement.template.downloadError'
                      ),
                    })
                  );
                }
              });
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const formatStatus = (status) => {
    const lowerCaseStatus = status?.toLowerCase();

    if (lowerCaseStatus === 'uploaded') {
      return <StatusLabel label="Uploaded" color="#59C3C3" />;
    }
    if (lowerCaseStatus === 'inserted') {
      return <StatusLabel label="Inserted" color="#95C020" />;
    }
    if (lowerCaseStatus === 'processing') {
      return <StatusLabel label="Processing" color="#F7A142" />;
    }
    if (lowerCaseStatus === 'failed') {
      return <StatusLabel label="Failed" color="#C54036" />;
    }
    if (lowerCaseStatus === 'inserted_with_errors') {
      return <StatusLabel label="Inserted with Errors" color="#395B50" />;
    }
  };

  const getAllDocumentList = useCallback(() => {
    setIsDataLoading(true);

    dispatch(
      getDocumentList({
        order: order,
        orderBy: orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        fromDate: filters.dateRange?.fromData,
        toDate: filters.dateRange?.toDate,
        status: filters.status?.value,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
  ]);

  const debouncedFetchData = useCallback(debounce(getAllDocumentList, 500), [
    getAllDocumentList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = documentList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.file_type = item?.file_type_relation?.type;
    const recordResult =
      item.current_status === 'uploaded'
        ? '0'
        : `${item.succeeded_records ? item.succeeded_records : '0'}/${item.total_records ? item.total_records : '0'}`;

    updatedItem.results = recordResult;
    updatedItem.created_at = formattedDate(item.created_at);

    return updatedItem;
  });

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.status ||
          filters.dateRange.fromData ||
          filters.dateRange.toDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Status"
            options={DOCUMENT_IMPORT_STATUS_LIST}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
            // isLoadingData={quoteStatusListLoading}
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: val[0], toDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromData: null, toDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromData}
            toDate={filters.dateRange?.toDate}
            placeholder="Upload Date"
            placement="bottomEnd"
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}`);
  };

  const ImportDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.dataManagement.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.dataManagement.importData')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      {!importStart ? (
        <Box sx={{ paddingBottom: '16px' }}>
          <StyledMainWrapper
            btn={
              <>
                <CustomButton
                  text={t('attributes.reports.dataManagement.importData')}
                  color="secondary"
                  sx={{ height: '52%' }}
                  startIcon={<ArrowCircleDownOutlinedIcon />}
                  onClick={() => {
                    setImportStart(true);
                  }}
                />
              </>
            }
          >
            <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
              {renderedComponent}
            </Box>
          </StyledMainWrapper>
        </Box>
      ) : (
        <ImportStepper />
      )}
    </>
  );

  return <>{ImportDataWrapper}</>;
};

export default ImportData;
