import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';

import { XeroLogo } from '../../assets/images';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const IconBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(() => ({
  fontSize: '11px',
}));

const CustomCardContent = styled(CardContent)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: 0,
}));

const Integrations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const Cards = () => (
    <Grid container spacing="25px">
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{ boxShadow: 0, borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTENAME.XERO_INTEGRATION);
          }}
        >
          <CustomCardContent>
            <IconBox>
              <img src={XeroLogo} alt="Xero Logo" height="35px" width="35px" />
              <EastOutlinedIcon
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </IconBox>
            <CustomTypography variant="common600">
              {t('attributes.integrations.xero')}
            </CustomTypography>
            <CustomTypography variant="common400">
              {t('attributes.integrations.xeroDecription')}
            </CustomTypography>
          </CustomCardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.integrations.integrations')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};

export default Integrations;
