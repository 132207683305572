import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { CUSTOMER_QUOTATION_STATUS } from '../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../constants/Typography';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { getProperty } from '../../store/property/api';
import {
  deleteDefectQuotationProduct,
  getDefectQuotesDetail,
  getDefectsQuotesList,
  postAddDefectQuoteProduct,
  updateDefectQuotationProduct,
  updateDefectQuotationStatus,
} from '../../store/workOrder/api';
import {
  resetAddDefectProductData,
  resetDefectQuotesDetail,
  resetdeleteQuotationItems,
  resetUpdateQuotationItems,
  resetUpdateQuotationStatus,
} from '../../store/workOrder/reducer';
import { Delete, Edit } from '../CommonComponents/ActionComponent';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { ConfirmationModal } from '../CommonComponents/Modal';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import AddProduct from './AddDefectQuotesProduct';
import generatePDF from './DefectQuotePDF';
import { formatDefectQuotesStatus } from './DefectQuotes';
import EditDefectQuotesProduct from './EditDefectQuotesProduct';
import PreviewDefectQuoteInvoice from './PreviewDefectQuoteInvoice';

// Styles
const headerStyle = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'left',
  backgroundColor: '#ebebeb19',
};

const veryNarrowStyle = {
  width: '60px',
};

const narrowStyle = {
  width: '80px',
};

const expandStyle = {
  width: '800px',
};

const cellStyle = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'left',
};

const cellStyleCenter = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'center',
};

const cellStyleRight = {
  padding: '7px',
  border: '1px solid #EBEBEB',
  textAlign: 'right',
};

const defaultValues = {
  defect_title: null,
  type: null,
  search: '',
};

const DefectQuotesView = ({ workOrderId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );
  const { isLoading, data } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );

  const {
    isLoading: addedDefectQuoteProductLoading,
    data: addedDefectQuoteProduct,
  } = useSelector((state) => state.workOrder.addDefectQuoteProduct);
  const { isLoading: updateQuotiationLoading, data: updateQuotiationData } =
    useSelector((state) => state.workOrder.updateQuotationStatus);

  const {
    isLoading: updatedQuotiationProductLoading,
    data: updatedQuotiationProduct,
  } = useSelector((state) => state.workOrder.updateQuotationItems);

  const {
    isLoading: deleteQuotiationProductLoading,
    data: deleteQuotiationProduct,
  } = useSelector((state) => state.workOrder.deleteQuotationItems);

  const [serverErrors, setServerErrors] = useState(null);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [defects, setDefects] = useState([]);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteProductDetail, setDeleteProductDetail] = useState(null);

  const workOrderUUID = workOrderId;

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    trigger,
    setError,
    clearErrors,
  } = useForm({ defaultValues: defaultValues });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  const updateDefectQuoteDetail = () => {
    dispatch(
      getDefectQuotesDetail({
        work_order_id: workOrderId,
        quote_id: data?.uuid,
      })
    );
  };

  useEffect(
    () => () => {
      dispatch(resetDefectQuotesDetail());
      dispatch(resetUpdateQuotationStatus());
      dispatch(resetAddDefectProductData());
      dispatch(resetUpdateQuotationItems());
      dispatch(resetdeleteQuotationItems());
    },
    []
  );

  useEffect(() => {
    if (updateQuotiationLoading !== null && !updateQuotiationLoading) {
      if (updateQuotiationData?.data?.length > 0) {
        updateDefectQuoteDetail();
        setOpenPreview(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.work_order.quotationSuccessMessage'),
          })
        );
      }
    }
  }, [updateQuotiationData, updateQuotiationLoading]);

  useEffect(() => {
    if (
      addedDefectQuoteProductLoading !== null &&
      !addedDefectQuoteProductLoading
    ) {
      if (Object.keys(addedDefectQuoteProduct).length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.work_order.quoteAddProductSuccessMsg'),
          })
        );
      }
    }
  }, [addedDefectQuoteProductLoading, addedDefectQuoteProduct]);

  useEffect(() => {
    if (
      updatedQuotiationProductLoading !== null &&
      !updatedQuotiationProductLoading
    ) {
      if (updatedQuotiationProduct?.data?.length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t('attributes.work_order.quoteEditProductSuccessMsg'),
          })
        );
      }
    }
  }, [updatedQuotiationProductLoading, updatedQuotiationProduct]);

  useEffect(() => {
    if (!deleteQuotiationProductLoading) {
      if (deleteQuotiationProduct) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deleteQuotiationProduct.message,
          })
        );
      }
    }
  }, [deleteQuotiationProductLoading, deleteQuotiationProduct]);

  useEffect(() => {
    if (addedDefectQuoteProduct && workOrderId && data?.uuid) {
      setOpenAddProduct(false);
      updateDefectQuoteDetail();
    }
  }, [
    addedDefectQuoteProduct,
    updatedQuotiationProduct,
    deleteQuotiationProduct,
  ]);

  const sendDefectQuotation = () => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: workOrderUUID,
        quote_id: data?.uuid,
        status: 'submit_to_customer',
      })
    );
  };

  const getDefects = useCallback(() => {
    dispatch(
      getDefectsQuotesList({
        workorder_uuid: workOrderUUID,
      })
    ).then((res) => setDefects(res?.payload));
  }, [workOrderUUID]);

  useEffect(() => {
    if (workOrderUUID) {
      getDefects();
    }
  }, [workOrderUUID]);

  const defectsList = getDropdownDataHook({
    data: defects?.data,
    labelName: 'defect_title',
    valueName: 'uuid',
  });

  const onSubmit = (formData) => {
    setDisableSave(true);
    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      // Set an error for the selectedProducts field
      setSelectedProductsError('Please select atleast one product!');
      setDisableSave(false);

      return; // Prevent form submission
    }
    formData.selectedProducts.forEach((product) => {
      const matchingDefect = defects?.data?.find(
        (defect) => defect.uuid === formData.defect_title.value
      );
      const createProductRequest = {
        asset_uuid: defects?.data[0]?.asset_uuid,
        asset_defect_uuid: formData.defect_title.value,
        product_catalogue_uuid: product.uuid,
        qty: product.qty,
        cost: product.cost,
        sell_price: product.sell_price,
        comment: 'No comments',
      };

      dispatch(
        postAddDefectQuoteProduct({
          work_order_uuid: workOrderUUID,
          quotation_uuid: data?.uuid,
          data: createProductRequest,
        })
      );
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    const createData = {
      qty: formData.qty,
      sell_price: formData.sell_price,
    };

    dispatch(
      updateDefectQuotationProduct({
        quotation_item_uuid: formData?.uuid,
        data: createData,
      })
    );
  };

  const defect_quotes_header_options = useMemo(() => {
    // List of statuses that should hide certain buttons
    const statusToHideButtons = [
      CUSTOMER_QUOTATION_STATUS.PENDING,
      CUSTOMER_QUOTATION_STATUS.APPROVED,
    ];

    return [
      // Add Product button, shown only if the status is not in the list
      ...(statusToHideButtons.includes(data?.status)
        ? []
        : [
            {
              label: `${t('attributes.add')} ${t(
                'attributes.productCatalogue.product'
              )}`,
              onClick: () => {
                setOpenAddProduct(true);
                reset(defaultValues);
              },
              icon: <ControlPointIcon />,
            },
          ]),

      // Preview button, always shown
      {
        label: `${t('attributes.work_order.preview')}`,
        icon: <RemoveRedEyeOutlinedIcon />,
        onClick: () => {
          setOpenPreview(true);
          if (user.company_uuid && data.property_uuid) {
            setIsPropertyLoading(true);
            dispatch(editCompany(user?.company_uuid));
            dispatch(getProperty(data?.property_uuid))
              .then((res) => setPropertyData(res.payload?.data[0]))
              .finally(() => setIsPropertyLoading(false));
          }
        },
      },

      // Send Defects Quote button, shown only if the status is not in the list
      ...(statusToHideButtons.includes(data?.status)
        ? []
        : [
            {
              label: `${t('common.send')} ${t(
                'attributes.customer.defects'
              )} ${t('attributes.work_order.quote')}`,
              icon: <ForwardToInboxOutlinedIcon />,
              onClick: sendDefectQuotation,
            },
          ]),
    ];
  }, [user, data]);

  // Calculate subtotal, tax, and total
  const total = data?.quotation_items?.reduce(
    (acc, item) => acc + item?.sell_price * item?.qty,
    0
  );

  if (isLoading) return <CustomCircularLoader />;

  return (
    <>
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deleteDefectQuotationProduct({
                quotation_item_uuid: deleteProductDetail?.quotation_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setEditProductFormOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isLoading && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '12px 16px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {defect_quotes_header_options.map((val) => (
                  <CustomButton
                    text={val.label}
                    color="secondary"
                    sx={{ height: '52%' }}
                    startIcon={val.icon}
                    onClick={() => {
                      if (val.onClick) {
                        val.onClick();
                      }
                    }}
                  />
                ))}
              </Box>
              <Box>
                <Typography>
                  {formatDefectQuotesStatus(data?.status, t)}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box sx={{ paddingTop: '16px', px: '16px' }}>
              <Box
                sx={{
                  border: `1px solid ${DIVIDER_COLOR}`,
                  borderRadius: '6px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 'calc(100vh - 210px)',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    maxHeight: '100vh-200px',
                    overflowY: 'auto',
                  }}
                >
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={headerStyle}>Product Name</th>
                        <th style={headerStyle}>Asset Name</th>
                        <th style={headerStyle}>Defect ID</th>
                        <th style={headerStyle}>Quantity</th>
                        <th style={headerStyle}>Cost</th>
                        <th style={headerStyle}>Sell</th>
                        <th style={headerStyle}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.quotation_items?.map((item, index) => (
                        <tr key={index}>
                          <td style={{ ...cellStyle, ...expandStyle }}>
                            <Typography>
                              {item?.product_catalogue?.product_name}
                            </Typography>
                          </td>
                          <td style={cellStyle}>
                            <Typography>
                              {item?.asset_details?.label}
                            </Typography>
                          </td>
                          <td style={{ ...cellStyleRight, ...narrowStyle }}>
                            <Typography>
                              D-{item?.work_order_asset_defects?.id}
                            </Typography>
                          </td>
                          <td style={{ ...cellStyleRight, ...veryNarrowStyle }}>
                            <Typography>{item?.qty}</Typography>
                          </td>
                          <td style={{ ...cellStyleRight, ...veryNarrowStyle }}>
                            <Typography>{item?.cost?.toFixed(2)}</Typography>
                          </td>
                          <td style={{ ...cellStyleRight, ...veryNarrowStyle }}>
                            <Typography>
                              {item?.sell_price?.toFixed(2)}
                            </Typography>
                          </td>
                          <td style={{ ...cellStyleRight, ...narrowStyle }}>
                            <Box sx={{ display: 'flex', gap: '8px' }}>
                              {data?.status !==
                                CUSTOMER_QUOTATION_STATUS.PENDING &&
                                data?.status !==
                                  CUSTOMER_QUOTATION_STATUS.APPROVED && (
                                  <>
                                    <Edit
                                      onClick={() => {
                                        setEditProductDetail(item);
                                        setEditProductFormOpen(true);
                                      }}
                                    />
                                    <Delete
                                      onClick={() => {
                                        setDeleteConfirmation(true);
                                        setDeleteProductDetail({
                                          quotation_item_uuid: item?.uuid,
                                        });
                                      }}
                                    />
                                  </>
                                )}
                            </Box>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${DIVIDER_COLOR}`,
                    backgroundColor: 'rgba(235, 235, 235, 0.2)',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      py: '12px',
                      paddingRight: '10px',
                      gap: '8px',
                    }}
                  >
                    <Typography variant="body1">TOTAL:</Typography>
                    <Typography variant="body1">{total?.toFixed(2)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )
      )}
      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title="Add Product"
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            defectTitleList={defectsList}
            selectedProductsError={selectedProductsError}
          />
        </SwipeableDrawer>
      )}
      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${data?.id}`}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text="Donwload"
              color="inherit"
              disabled={isPropertyLoading || isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(data, propertyData, company);
              }}
            />,
            data.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              data.status !== CUSTOMER_QUOTATION_STATUS.APPROVED && (
                <CustomButton
                  text="Send Defects Quote"
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    updateQuotiationLoading
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={sendDefectQuotation}
                />
              ),
          ]}
          width={653}
        >
          <PreviewDefectQuoteInvoice
            companyData={company}
            propertyData={propertyData}
            isPropertyLoading={isPropertyLoading}
          />
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product_catalogue?.product_name}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              disabled={disableSave}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                handleSubmit(onSubmitEditProduct)();
              }}
            />,
          ]}
          width={653}
        >
          <EditDefectQuotesProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectQuotesView;
