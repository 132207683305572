import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { longDateTimeRange } from '../../constants/common';
import {
  getStatusLabels,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  WO_STATUS,
  WORK_ORDER_STEPS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getDocument } from '../../store/CommonAPI';
import { snackbarToggle } from '../../store/CommonReducer';
import { resetUpdateInvoice } from '../../store/invoices/reducer';
import { getProperty } from '../../store/property/api';
import {
  resetPagination,
  resetUpdatePurchaseOrder,
} from '../../store/purchaseOrders/reducer';
import { getWorkOrder } from '../../store/workOrder/api';
import {
  resetUpdateQuotationStatus,
  resetWorkOrderData,
} from '../../store/workOrder/reducer';
import { formatStatus, formattedDate } from '../../utils';
import ComingSoon from '../CommonComponents/ComingSoon';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import PdfView from '../CommonComponents/PdfView';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import Assets from './Assets/Assets';
import DefectQuotes from './DefectQuotes';
import DefectQuotesView from './DefectQuotesView';
import DefectsQuotes from './DefectsQuotes/DefectsQuotes';
import Invoices from './Invoices/Invoices';
import PurchaseOrders from './PurchaseOrders/PurchaseOrders';
import WorkOrderData from './WorkOrderData';

const tabHashMap = {
  0: '', // Default tab
  1: '#assets',
  2: '#defects',
  3: '#defects_quotes',
  4: '#invoices',
  5: '#purchase_orders',
  6: '#documents',
  7: '#coming_soon',
};

const WorkOrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();

  const initialTabIndex =
    Object.keys(tabHashMap).find((key) => hash === tabHashMap[key]) || 0;
  const [activeTab, setActiveTab] = useState(initialTabIndex);
  const [open, setOpen] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [nextStepTitle, setNextStepTitle] = useState();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const { isLoading, workOrder } = useSelector(
    (state) => state.workOrder?.details
  );

  useEffect(() => {
    // Update active tab based on hash changes
    const hashIndex = Object.keys(tabHashMap).find(
      (key) => hash === tabHashMap[key]
    );

    setActiveTab(hashIndex ? parseInt(hashIndex) : 0); // Default to 0 if no match
  }, [hash]);

  useEffect(() => {
    // setActiveTab(hash === '#assets' ? 1 : 0);
    if (params?.uuid) {
      dispatch(getWorkOrder({ id: params.uuid }));
      setIsPropertyLoading(true);
    }

    return () => {
      resetWorkOrderData();
    };
  }, [params?.uuid]);

  useEffect(() => {
    if (workOrder?.id && !isLoading) {
      dispatch(getProperty(workOrder?.property_uuid))
        .then((res) => setSelectedProperty(res.payload?.data[0]))
        .catch((err) => console.error('Error fetching property data:', err))
        .finally(() => setIsPropertyLoading(false));
    }
  }, [workOrder?.id]);

  const handleNextStep = () => {
    setNextStepTitle();
    dispatch(resetUpdateQuotationStatus());
    dispatch(resetPagination());
    dispatch(resetUpdatePurchaseOrder());
    dispatch(resetUpdateInvoice());
  };

  const workOrderDetailKey =
    workOrder[
      workOrder?.type_name
        ?.split(' ')
        .map((d) => d.toLowerCase())
        .join('_')
    ];

  const downloadFile = async (doc_uuid) => {
    setIsDownloadingPDF(true);
    if (workOrder?.id) {
      dispatch(getDocument({ doc_uuid }))
        .then((res) => {
          if (res.payload?.status === 200 && res?.payload?.data?.[0]) {
            const link = document.createElement('a');

            link.href = res?.payload?.data?.[0]?.presigned_url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: t('message.work_order.unableToDownloadPDF'),
              })
            );
          }
        })
        .finally(() => setIsDownloadingPDF(false));
    }
  };

  return isLoading || isPropertyLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.workOrder.workOrder')}
          variant="body1"
          isStep={true}
          step={`W-${workOrder?.id}`}
          handleDefaultStep={() =>
            navigate(`/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`)
          }
          nextStepTitle={nextStepTitle}
          handleNextStep={handleNextStep}
        ></MainWrapper>
      </Box>
      {nextStepTitle?.title === WORK_ORDER_STEPS.defect_quotes && (
        <DefectQuotesView workOrderId={workOrder?.uuid} />
      )}
      {nextStepTitle?.title === WORK_ORDER_STEPS.purchase_orders && (
        <PurchaseOrders
          workOrderId={workOrder?.uuid}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
        />
      )}
      {nextStepTitle?.title === WORK_ORDER_STEPS.invoice && (
        <Invoices
          workOrderId={workOrder?.uuid}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
        />
      )}
      {!nextStepTitle?.title && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: DIVIDER_COLOR,
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              marginBottom: '16px',
              width: '100%',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ padding: '16px' }}>
                  <Typography variant="body1">
                    {workOrder?.work_order_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: '16px',
                    borderLeft: `1px solid ${DIVIDER_COLOR}`,
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Typography variant="body1">
                    {formatStatus(getStatusLabels(workOrder), '10px', '10px')}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', columnGap: '8px', padding: '16px' }}>
                <Typography variant="body1">
                  {t('attributes.work_order.frequency')}
                </Typography>
                <Box
                  sx={{
                    width: '30px',
                    height: '20px',
                    border: '1px solid black',
                    borderRadius: '24px',
                    bgcolor: DIVIDER_COLOR,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {workOrderDetailKey?.frequencies?.code}
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ padding: '16px' }}>
                  <Typography variant="body1">
                    {t('attributes.work_order.work_order_type')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {workOrder?.work_order_type}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: '16px',
                    borderLeft: `1px solid ${DIVIDER_COLOR}`,
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.service_report')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '8px',
                      cursor: 'pointer',
                      alignItems: 'unset',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    <VisibilityOutlinedIcon
                      style={{
                        height: '24px',
                        width: '24px',
                      }}
                      color="primary"
                    />
                    <Typography variant="body1" color="primary">
                      {t('attributes.view')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', columnGap: '20px', padding: '16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">{`${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`}</Typography>
                  <Typography variant="body2">
                    {longDateTimeRange(
                      formattedDate(
                        workOrder?.scheduled_start_date_time,
                        SCHEDULE_BOARD_WO_UTC_LOCAL
                      ),
                      formattedDate(
                        workOrder?.scheduled_end_date_time,
                        SCHEDULE_BOARD_WO_UTC_LOCAL
                      )
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.created_on')}
                  </Typography>
                  <Typography variant="body2">
                    {workOrder?.work_order_status?.filter(
                      ({ status }) => status === WO_STATUS.created
                    )[0]?.created_at
                      ? formattedDate(
                          workOrder?.work_order_status?.filter(
                            ({ status }) => status === WO_STATUS.created
                          )[0]?.created_at
                        )
                      : '-'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.due_date')}
                  </Typography>
                  <Typography variant="body2">
                    {formattedDate(workOrder?.due_date)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '6px 6px 0 0',
            }}
          >
            <TabView
              tabs={[
                {
                  label: t('attributes.supplier.details'),
                  component: (
                    <WorkOrderData
                      data={workOrder}
                      selectedProperty={selectedProperty}
                    />
                  ),
                },
                {
                  label: t('attributes.property.assets'),
                  component: <Assets workOrderUUID={workOrder?.uuid} />,
                },
                {
                  label: t('attributes.customer.defects'),
                  component: <DefectsQuotes workOrderUUID={workOrder?.uuid} />,
                },
                {
                  label: t('attributes.work_order.defects_quote'),
                  component: (
                    <DefectQuotes
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                {
                  label: t('attributes.invoice.invoice'),
                  component: (
                    <Invoices
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                {
                  label: t('attributes.work_order.purchase_order'),
                  component: (
                    <PurchaseOrders
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                {
                  label: t('attributes.documents'),
                  component: <ComingSoon height={'calc(100vh - 400px)'} />,
                },
                {
                  label: 'Comming Soon...',
                  component: <ComingSoon height={'calc(100vh - 400px)'} />,
                },
              ]}
              activeTab={activeTab}
              setActiveTab={(index) => {
                setActiveTab(index);
                // Update the hash based on the active tab
                const newHash = tabHashMap[index] || '';

                navigate(`${location.pathname}${newHash}`, { replace: true });
              }}
              onTabChange={() => {
                // navigate('#assets');
              }}
              isSubDetails={true}
            />
          </Box>
        </Box>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.work_order.routineServiceReport')}
          onClose={() => setOpen(false)}
          footerButton={
            <CustomButton
              text="Download"
              startIcon={<DownloadOutlinedIcon />}
              disabled={
                isDownloadingPDF ||
                !workOrder?.work_order_service_report[
                  workOrder?.work_order_service_report?.length - 1
                ]?.document_uuid
              }
              onClick={() => {
                downloadFile(
                  workOrder?.work_order_service_report[
                    workOrder?.work_order_service_report?.length - 1
                  ]?.document_uuid
                );
              }}
            />
          }
        >
          <PdfView
            id={
              workOrder?.work_order_service_report[
                workOrder?.work_order_service_report?.length - 1
              ]?.document_uuid
            }
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default WorkOrderDetails;
