import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Box, styled } from '@mui/material';
import moment from 'moment';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getPropertyList } from '../../store/property/api';
import {
  getQuotationStatus,
  getQuotationTotalAmount,
} from '../../store/quotes/api';
import { addSpaceAndCapitalizeString, loggedInUserDetail } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import MainWrapper from '../CommonComponents/MainWrapper';

//default filters
const defaultFilters = {
  dateRange: {
    fromQuoteDate: moment().subtract(6, 'months').toDate(),
    toQuoteDate: moment().toDate(),
  },
  quoteType: null,
  properties: null,
};

const FiltersContainer = styled(Box)({
  borderRadius: '4px',
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  columnGap: '16px',
  rowGap: '16px',
  flexWrap: 'wrap',
});

const QuotesDashboardLayout = () => {
  const { t } = useTranslation();
  const { status } = useParams();
  const navigate = useNavigate();

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(defaultFilters);

  const { quotesDropdownLoading, quotesDropdownData } = getDropdownListHook({
    reducerName: 'quotes',
    dropdownListName: 'quotesTypesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });
  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  useEffect(() => {
    let req = {};

    if (!filters.dateRange.fromQuoteDate && !filters.dateRange.toQuoteDate) {
      req = {
        start_date: moment().subtract(6, 'months'),
        end_date: moment(),
      };
    } else {
      req = {
        start_date: filters.dateRange.fromQuoteDate,
        end_date: filters.dateRange.toQuoteDate,
      };
    }

    if (filters.properties) {
      req = { ...req, property_uuid: filters.properties.value };
    }

    if (filters.quoteType) {
      req = { ...req, type: filters.quoteType.value };
    }

    dispatch(getQuotationStatus(req));
    dispatch(getQuotationTotalAmount(req));
  }, [filters]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(getPropertyList(req));
  }, []);

  const handleResetFilterBtnClick = () => {
    setFilters(defaultFilters);
  };
  const handleRefreshDashboardBtnClick = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        bgcolor: status ? SECONDARY : DIVIDER_COLOR,
        height: '100%',
      }}
    >
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.customer.quotes')}
          variant="body1"
          isStep={status ? true : false}
          step={addSpaceAndCapitalizeString(status)}
          handleDefaultStep={() =>
            navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}`)
          }
          btn={
            status
              ? []
              : [
                  <ResetFilterButton
                    onClick={handleResetFilterBtnClick}
                    disabled={
                      !(
                        filters.quoteType ||
                        filters.properties ||
                        filters.dateRange.fromQuoteDate ||
                        filters.dateRange.toQuoteDate
                      )
                    }
                  />,
                  <FilterButton
                    onClick={() => setShowFilterOptions(!showFilterOptions)}
                    isActive={showFilterOptions}
                  />,
                  <RefreshDashboardButton
                    onClick={handleRefreshDashboardBtnClick}
                  />,
                ]
          }
        ></MainWrapper>
        {showFilterOptions && !status && (
          <Box sx={{ paddingTop: '16px' }}>
            <FiltersContainer>
              <CustomDateRangePicker
                onOkClick={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    dateRange: { fromQuoteDate: val[0], toQuoteDate: val[1] },
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    dateRange: { fromQuoteDate: null, toQuoteDate: null },
                  }));
                }}
                fromDate={filters.dateRange?.fromQuoteDate}
                toDate={filters.dateRange?.toQuoteDate}
              />
              <Autocomplete
                placeholder={t('attributes.customer.quote_type')}
                options={quotesDropdownData}
                value={filters?.quoteType}
                isLoadingData={quotesDropdownLoading}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, quoteType: newVal }))
                }
                width="190px"
              />
              <Autocomplete
                placeholder={t('attributes.property.properties')}
                options={propertyDropdownData}
                isLoadingData={propertyDropdownLoading}
                value={filters?.properties}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, properties: newVal }))
                }
                width="190px"
              />
            </FiltersContainer>
          </Box>
        )}
      </Box>

      <Outlet context={{ dashboardFilter: filters }} />
    </Box>
  );
};

export default QuotesDashboardLayout;
