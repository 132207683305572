import { useTranslation } from 'react-i18next';

import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { Box, styled, Typography } from '@mui/material';

import { PRIMARY, SECONDARY } from '../../constants/Colors';

const CustomIcon = styled(Box)(() => ({
  display: 'flex',
  background: PRIMARY,
  padding: '20px',
  borderRadius: '100%',
}));

const InviteTheFireCompany = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          padding: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <CustomIcon>
          <MarkEmailReadOutlinedIcon
            sx={{ color: 'white', height: '40px', width: '40px' }}
          />
        </CustomIcon>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingTop: '16px',
          }}
        >
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body2">
            {t('attributes.company.invite_company_super_admin')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InviteTheFireCompany;
