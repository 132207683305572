import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography, styled } from '@mui/material';

import { WARNING } from '../../constants/Colors';
import { getDefectQuote } from '../../store/workOrder/api';
import { formatDate } from '../../utils';
import CommonCarouselView from '../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const KeyValueComponent = ({ name, value, minWidth = '180px' }) => (
  <Box>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const ImageWrapper = styled(Box)({
  height: '100px',
  width: '100px',
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden', // Ensure tooltip stays within the bounds
  '&:hover .hoverText': {
    visibility: 'visible',
    opacity: 1,
  },
});
const HoverText = styled(Box)({
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translate(-50%,0)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  height: 'inherit',
  width: 'inherit',
  borderRadius: '4px',
  visibility: 'hidden',
  opacity: 0,
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  textDecoration: 'underline',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ViewDashboardDefect = ({ workOrderUUID, defectUUID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defect, setDefect] = useState(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentModalSlide, setCurrentModalSlide] = useState(0);

  useEffect(() => {
    if (workOrderUUID && defectUUID) {
      dispatch(
        getDefectQuote({ work_order_id: workOrderUUID, defect_id: defectUUID })
      )
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setLoading(false));
    }
  }, [defectUUID, workOrderUUID]);

  const handleArrowClick = (value) => {
    if (value === 'prev') {
      currentSlide !== 0 && setCurrentSlide(currentSlide - 1);
    } else {
      currentSlide < 4 && setCurrentSlide(currentSlide + 1);
    }
  };

  const modalHandleArrowClick = (value) => {
    if (value === 'prev') {
      currentModalSlide !== 0 && setCurrentModalSlide(currentModalSlide - 1);
    } else {
      currentModalSlide < 2 && setCurrentModalSlide(currentModalSlide + 1);
    }
  };

  const openImage = () => setOpen(true);

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box
            sx={{
              padding: '12px',
              display: 'flex',
              width: '100%',
              columnGap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                minWidth: '50%',
              }}
            >
              <KeyValueComponent
                name={t('attributes.property.propertyName')}
                value={defect?.property?.display_name || '-'}
              />
              <KeyValueComponent
                name={`${t('attributes.property.property')} ${t('attributes.address')}`}
                value={defect?.property?.address || '-'}
              />
              <KeyValueComponent
                name={t('attributes.technician.technician_name')}
                value={defect?.work_order?.technician?.technician_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.description')}
                value={defect?.defect_description || '-'}
              />
              <KeyValueComponent
                name={t('attributes.work_order.severity')}
                value={defect?.severity || '-'}
              />
              <KeyValueComponent
                name={t('attributes.property.status')}
                value={defect?.latest_status || '-'}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                minWidth: '30%',
              }}
            >
              <KeyValueComponent
                name={t('attributes.property.asset_name')}
                value={defect?.asset?.display_name || '-'}
              />
              <KeyValueComponent
                name={`${t('attributes.property.asset')} ${t('attributes.location')}`}
                value={defect?.asset?.location || '-'}
              />
              <KeyValueComponent
                name={t('attributes.work_order.routineServiceName')}
                value={defect?.asset?.display_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.recommendation')}
                value={'-'}
              />
              <KeyValueComponent
                name={`${t('attributes.work_order.identified')} ${t('attributes.date')}`}
                value={formatDate(defect?.created_at) || '-'}
              />
            </Box>
          </Box>
        </Card>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box sx={{ padding: '12px' }}>
            <Typography variant="body1">
              {t('attributes.work_order.image')}
              <span style={{ color: WARNING }}>*</span>
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
              padding: '16px',
              flexWrap: 'wrap',
            }}
          >
            <CommonCarouselView>
              <ImageWrapper>
                <img
                  src="https://plus.unsplash.com/premium_photo-1683910767532-3a25b821f7ae?q=80&w=2008&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Fire Logo"
                  style={{ height: 'inherit', backgroundSize: 'cover' }}
                />
                <HoverText className="hoverText" onClick={openImage}>
                  <Typography variant="body1">View</Typography>
                </HoverText>
              </ImageWrapper>
              <ImageWrapper>
                <img
                  src="https://plus.unsplash.com/premium_photo-1683910982837-81f0671bbb0d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Fire Logo"
                  style={{ height: 'inherit', backgroundSize: 'cover' }}
                />
                <HoverText className="hoverText">
                  <Typography variant="body1">View</Typography>
                </HoverText>
              </ImageWrapper>
              <ImageWrapper>
                <img
                  src="https://plus.unsplash.com/premium_photo-1661841439995-1706237c83dc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Fire Logo"
                  style={{ height: 'inherit', backgroundSize: 'cover' }}
                />
                <HoverText className="hoverText">
                  <Typography variant="body1">View</Typography>
                </HoverText>
              </ImageWrapper>
              <ImageWrapper>
                <img
                  src="https://plus.unsplash.com/premium_photo-1661812071978-771a70ca1516?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Fire Logo"
                  style={{ height: 'inherit', backgroundSize: 'cover' }}
                />
                <HoverText className="hoverText">
                  <Typography variant="body1">View</Typography>
                </HoverText>
              </ImageWrapper>
              <ImageWrapper>
                <img
                  src="https://plus.unsplash.com/premium_photo-1661812071978-771a70ca1516?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Fire Logo"
                  style={{ height: 'inherit', backgroundSize: 'cover' }}
                />
                <HoverText className="hoverText">
                  <Typography variant="body1">View</Typography>
                </HoverText>
              </ImageWrapper>
            </CommonCarouselView>
          </Box>
        </Card>
      </Box>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={3}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              isModal={true}
            >
              <img
                src="https://plus.unsplash.com/premium_photo-1661812071978-771a70ca1516?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Fire Logo"
                style={{ height: '500px', width: '500px' }}
              />
              <img
                src="https://plus.unsplash.com/premium_photo-1661841439995-1706237c83dc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Fire Logo"
                style={{ height: '500px', width: '500px' }}
              />
              <img
                src="https://plus.unsplash.com/premium_photo-1661841439995-1706237c83dc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Fire Logo"
                style={{ height: '500px', width: '500px' }}
              />
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </>
  );
};

export default ViewDashboardDefect;
