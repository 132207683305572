import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Card, Divider, styled, Typography } from '@mui/material';
import { uniqueId } from 'lodash';

import {
  WORK_ORDER_TYPE,
  WORK_ORDER_TYPE_ADHOC,
  WORK_ORDER_TYPE_REPAIR,
  WORK_ORDER_TYPE_ROUTINE,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { getEquipmentTypesDropdown } from '../../store/equipmentTypes/api';
import { resetEquipmentTypesDropdownList } from '../../store/equipmentTypes/reducer';
import {
  getPropertyAssetsList,
  getPropertyList,
  getRoutineStandardFrequencyList,
} from '../../store/property/api';
import {
  resetAssetsList,
  resetPropertyList,
  resetRoutineStandardFrequencyList,
} from '../../store/property/reducer';
import {
  getDefectsQuotesList,
  getWorkOrderDropdownList,
} from '../../store/workOrder/api';
import {
  resetDefectQuotesDetail,
  resetWorkOrderDropdownList,
} from '../../store/workOrder/reducer';
import { Delete } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomTextField from '../CommonComponents/CustomTextField';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

// Todo: Reset all the api's used here just search api here and take's it's reset and pass it in useEffect
const WorkOrderForm = ({
  id,
  control,
  reset,
  setValue,
  watch,
  trigger,
  clearErrors,
  setServerErrors,
  customerList,
  customerListLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [defects, setDefects] = useState([]);
  const [defectsLoading, setDefectsLoading] = useState(true);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const [woLineItems, setWoLineItems] = useState([]);

  const workOrderTypeSelected = watch('workOrderType');
  const propertySelected = watch('property');
  const attach = watch('attach');
  const equipmentType = watch('equipmentType');
  const parentWO = watch('parentWO');
  const customerSelect = watch('customer');

  // Memoized columns
  const adHocColumns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.description'),
        field: 'description',
        sortable: false,
        flex: 1,
      },
      {
        field: 'delete',
        headerName: t('attributes.action'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Delete
            onClick={() =>
              setWoLineItems((prev) =>
                prev.filter((prevValue) => prevValue.id !== row.id)
              )
            }
          />
        ),
      },
    ];

    attach &&
      baseColumns.splice(1, 0, {
        headerName: t('attributes.property.assets'),
        field: 'asset',
        sortable: false,
        flex: 1,
        valueGetter: (value) => value?.label,
      });

    return baseColumns;
  }, [attach]);

  const repairColumn = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('attributes.work_order.defect_id'),
        field: 'id',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.property.asset')} ${t('attributes.name')}`,
        field: 'assets',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography variant="body2">{row?.asset?.label}</Typography>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'id',
    });

  const { assetsListDropdownLoading, assetsListDropdownData } =
    getDropdownListHook({
      reducerName: 'property',
      dropdownListName: 'assetsList',
      labelName: 'label',
      valueName: 'uuid',
      loading: 'loading',
      customNameForDropdown: 'assetsList',
    });

  const {
    routineStandardFrequencyDropdownLoading,
    routineStandardFrequencyDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'routineStandardFrequencyList',
    labelName: 'name',
    valueName: 'routine_id',
    loading: 'loading',
    customNameForDropdown: 'routineStandardFrequency',
  });

  const { workOrderLoading, workOrderDropdownData } = getDropdownListHook({
    reducerName: 'workOrder',
    dropdownListName: 'workOrderDropdownList',
    labelName: 'woid',
    valueName: 'uuid',
    quotation_id: 'parent_quotation_approved_uuid',
  });

  useEffect(
    () => () => {
      reset();
      dispatch(resetEquipmentTypesDropdownList());
      dispatch(resetAssetsList());
      dispatch(resetPropertyList());
      dispatch(resetRoutineStandardFrequencyList());
      dispatch(resetDefectQuotesDetail());
      dispatch(resetWorkOrderDropdownList());
    },
    []
  );

  const clearHandler = (name) => setValue(name, '');

  useEffect(() => {
    dispatch(getEquipmentTypesDropdown());
  }, []);

  useEffect(() => {
    if (
      (workOrderTypeSelected?.value === undefined ||
        workOrderTypeSelected?.value === WORK_ORDER_TYPE_ROUTINE) &&
      propertySelected?.value
    ) {
      dispatch(
        getRoutineStandardFrequencyList({
          limit: -1,
          property_id: propertySelected?.value,
          type: 'custom',
        })
      );
    }
  }, [propertySelected, workOrderTypeSelected]);

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: watch('customer')?.value,
    };

    if (watch('customer')?.value) {
      dispatch(getPropertyList(req));
    }
  }, [dispatch, customerSelect]);

  useEffect(() => {
    if (
      workOrderTypeSelected?.value === WORK_ORDER_TYPE_ADHOC &&
      propertySelected &&
      equipmentType
    ) {
      dispatch(
        getPropertyAssetsList({
          property_uuid: propertySelected?.value,
          limit: -1,
          equipment_type_id: equipmentType?.value,
        })
      );
    }
  }, [workOrderTypeSelected, propertySelected, equipmentType]);

  useEffect(() => {
    if (
      workOrderTypeSelected?.value === WORK_ORDER_TYPE_REPAIR &&
      propertySelected
    ) {
      dispatch(
        getWorkOrderDropdownList({
          property: propertySelected?.value,
          limit: -1,
        })
      );
    }
  }, [propertySelected, workOrderTypeSelected]);

  useEffect(() => {
    if (parentWO) {
      dispatch(getDefectsQuotesList({ workorder_uuid: parentWO?.value }))
        .then((res) => setDefects(res?.payload?.data))
        .finally(() => setDefectsLoading(false));
    }
  }, [parentWO]);

  useEffect(() => {
    if (!attach) {
      setValue('equipmentType', null);
      setValue('assets', null);
    }
  }, [attach]);

  // Todo: Right now asset_uuid is mandatory so if not selected sending a static later after discussion with arpit need to change BE.
  useEffect(() => {
    setValue(
      'woListItem',

      woLineItems.map((row) => ({
        description: row?.description || '',
        asset_uuid: row?.asset?.value || 'faca216f-2f3c-4561-be37-802b5cea64ac',
      }))
    );
  }, [woLineItems]);

  useEffect(() => {
    setValue(
      'defects',

      selectedDefects.map((row) => ({
        defect_uuid: row?.uuid,
        asset_uuid: row?.asset?.uuid,
      }))
    );
  }, [selectedDefects]);

  const handleSelectionChange = (newSelection) => {
    setSelectedDefects(defects?.filter((row) => newSelection.includes(row.id)));
  };

  return (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.customer.customer')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />

        <FormFieldWrapper>
          <Controller
            name="customer"
            control={control}
            rules={{
              required: `${t('attributes.customer.customer')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={customerList}
                isLoadingData={customerListLoading}
                fullWidth
                label={t('attributes.customer.customer')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('customer');
                  setValue('property', null);
                  setValue('routineType', null);
                }}
                value={value}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />

          <Controller
            name="property"
            control={control}
            rules={{
              required: `${t('attributes.property.property')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={propertyDropdownData}
                isLoadingData={propertyDropdownLoading}
                fullWidth
                label={t('attributes.property.property')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('property');
                  setValue('routineType', null);
                  setValue('parentWO', null);
                }}
                value={value}
                disabledDropdown={!watch('customer')?.value}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />

          <Controller
            name="workOrderType"
            control={control}
            rules={{
              required: `${t('attributes.work_order.work_order_type')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={WORK_ORDER_TYPE}
                fullWidth
                label={t('attributes.work_order.work_order_type')}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('customerId');
                }}
                value={value}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>

      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.work_order.work_order')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />

        <FormFieldWrapper>
          {(workOrderTypeSelected?.value === undefined ||
            workOrderTypeSelected?.value === WORK_ORDER_TYPE_ROUTINE) && (
            <Controller
              name="routineType"
              control={control}
              rules={{
                required: `${t('attributes.work_order.routine_type')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={routineStandardFrequencyDropdownData}
                  isLoadingData={routineStandardFrequencyDropdownLoading}
                  fullWidth
                  label={t('attributes.work_order.routine_type')}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('routineType');
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
          )}

          {workOrderTypeSelected?.value === WORK_ORDER_TYPE_ADHOC && (
            <Controller
              name="title"
              control={control}
              rules={{
                required: `${t('attributes.work_order.title')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.title,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.work_order.title')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('title');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
          )}

          {workOrderTypeSelected?.value === WORK_ORDER_TYPE_REPAIR && (
            <Controller
              name="parentWO"
              control={control}
              rules={{
                required: `${t('attributes.work_order.work_order')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  options={workOrderDropdownData}
                  isLoadingData={workOrderLoading}
                  fullWidth
                  label={t('attributes.work_order.parent_work_order')}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('parentWO');
                    setValue('equipmentType', null);
                    setValue('assets', null);
                  }}
                  value={value}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
          )}

          <CustomDatePicker
            control={control}
            name="due_date"
            label={t('attributes.work_order.due_date')}
            pickerType="date"
            defaultValue={null}
            trigger={trigger}
            isRequired={true}
          />
        </FormFieldWrapper>
      </CustomCard>

      {workOrderTypeSelected?.value === WORK_ORDER_TYPE_ADHOC && (
        <CustomCard>
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1">{`${t('attributes.work_order.work_order')} ${t('attributes.work_order.line_items')}`}</Typography>
          </Box>

          <FormFieldWrapper>
            <Controller
              name="description"
              control={control}
              rules={{
                required: `${t('attributes.description')} ${Validation.general.required}`,
                maxLength: {
                  value: 225,
                  message: Validation.general.title,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={t('attributes.description')}
                  fullWidth
                  rows={3}
                  multiline
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('description');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                />
              )}
            />
            <CommonCheckbox
              control={control}
              name="attach"
              label={`${t('attributes.work_order.attach_description_with_asset')}?`}
              isRequired={false}
            />
            {attach && (
              <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                <Controller
                  name="equipmentType"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.equipment_type')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('attributes.property.equipment_type')}
                      options={equipmentTypesDropdownData}
                      isLoadingData={equipmentTypesDropdownLoading}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('equipmentType');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
                <Controller
                  name="assets"
                  control={control}
                  rules={{
                    required: `${t('attributes.property.assets')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('attributes.property.assets')}
                      options={assetsListDropdownData}
                      isLoadingData={assetsListDropdownLoading}
                      value={value}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        trigger('assets');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
              </Box>
            )}
            <Box
              columnGap="8px"
              width={'100%'}
              display="flex"
              justifyContent="flex-end"
            >
              <CustomButton
                text={t('attributes.add')}
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => {
                  setWoLineItems((prev) => [
                    ...prev,
                    {
                      id: uniqueId(),
                      description: watch('description') || '',
                      asset: watch('assets') || '',
                    },
                  ]);
                }}
              />
            </Box>
            <CustomGridTable
              columns={adHocColumns}
              rows={woLineItems}
              total={woLineItems.length}
              checkboxSelection={false}
              paginationRequired={false}
              disableColumnMenu={true}
              noData={<NoRecordFound />}
              isTableView={true}
            />
          </FormFieldWrapper>
        </CustomCard>
      )}

      {workOrderTypeSelected?.value === WORK_ORDER_TYPE_REPAIR && parentWO && (
        <CustomCard>
          <CustomGridTable
            columns={repairColumn}
            isLoading={defectsLoading}
            rows={defects}
            total={defects.length}
            paginationRequired={false}
            disableColumnMenu={true}
            onRowSelectionModelChange={handleSelectionChange}
            noData={<NoRecordFound />}
          />
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default WorkOrderForm;
