import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, styled, Typography } from '@mui/material';

import { GoogleIcon, MicrosoftIcon } from '../../assets/icons';
import { DarkLogo } from '../../assets/images';
import { PRIMARY } from '../../constants/Colors';
import { LOGIN_TYPE } from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import CustomButton from '../CommonComponents/CustomButton';
import CustomButtonLoader from '../CommonComponents/CustomButtonLoader';

// Styled Components
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '800px', // Ensure dialog width is set to 800px
    maxWidth: '800px', // Prevent it from growing larger than 800px
    overflow: 'hidden', // Prevent content overflow
    height: '384px',
    maxHeight: '384px',
    padding: '8px',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LeftSection = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '10px',
}));

const RightSection = styled(Box)({
  width: '40%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SSOLoginButton = styled(CustomButton)(({ theme }) => ({
  width: '260px',
  height: '40px',
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  border: `1px solid ${theme.palette.secondary.contrastText}`,
  '&:hover': {
    border: `1px solid ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.common.white,
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  margin: '16px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  width: '36px',
  height: '36px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    svg: {
      path: {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const LandingPageLoginModal = ({
  closeModal,
  open,
  handleLogin,
  isSSOPopupOpen,
  loginType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAuthLoading } = useSelector((state) => state.common);

  return (
    <CustomDialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick' && isSSOPopupOpen) {
          return;
        }
        closeModal();
      }}
    >
      <Box style={{ display: 'flex', height: '100%' }}>
        <LeftSection>
          <ContentBox>
            <img src={DarkLogo} alt="Logo" width="249" height="82" />
            <Typography variant="h2" style={{ marginTop: '10px' }}>
              Streamlined Fire Safety Management
            </Typography>
          </ContentBox>
        </LeftSection>
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <RightSection>
          <ContentBox>
            <Typography variant="h1" align="center" mb={2}>
              {t('auth.login.continueLoginWith')}
            </Typography>
            <SSOLoginButton
              variant="outlined"
              startIcon={<ReactSVG src={MicrosoftIcon} alt="Microsoft" />}
              onClick={() => handleLogin(LOGIN_TYPE.microsoft)}
              disabled={isAuthLoading || isSSOPopupOpen}
              endIcon={
                isAuthLoading && loginType === LOGIN_TYPE.microsoft ? (
                  <CustomButtonLoader />
                ) : null
              }
            >
              Microsoft
            </SSOLoginButton>
            <SSOLoginButton
              startIcon={<ReactSVG src={GoogleIcon} alt="Google" />}
              onClick={() => handleLogin(LOGIN_TYPE.google)}
              variant="outlined"
              disabled={isAuthLoading || isSSOPopupOpen}
              endIcon={
                isAuthLoading && loginType === LOGIN_TYPE.google ? (
                  <CustomButtonLoader />
                ) : null
              }
            >
              Google
            </SSOLoginButton>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '11px',
                width: '190px',
                textAlign: 'center',
              }}
            >
              {t('attributes.landingPageTermsPolicy').split(' \n ')[0]}
              <span
                style={{ color: PRIMARY, cursor: 'pointer' }}
                onClick={() => window.open(ROUTENAME.TERMS_SERVICE, '_blank')}
              >
                {t('attributes.termsOfService.title')}
              </span>{' '}
              {t('attributes.landingPageTermsPolicy').split(' \n ')[1]}{' '}
              <span
                style={{ color: PRIMARY, cursor: 'pointer' }}
                onClick={() => window.open(ROUTENAME.PRIVACY_POLICY, '_blank')}
              >
                {t('attributes.privacyPolicy.title')}
              </span>
              .
            </Typography>
          </ContentBox>
        </RightSection>
      </Box>
    </CustomDialog>
  );
};

export default LandingPageLoginModal;
