import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, styled } from '@mui/material';

import { DIVIDER_COLOR } from '../../../../constants/Colors';
import { ROUTENAME } from '../../../../constants/RoutesConstants';
import ComingSoon from '../../ComingSoon';
import MainWrapper from '../../MainWrapper';

const Centered = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '100px',
  flexDirection: 'column',
  gap: '20px',
  height: '87vh',
});

export const RefernceData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.DATA_MANAGEMENT}`);
  };

  const TemplateDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%' }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.reports.dataManagement.title')}
          variant="body1"
          isStep={true}
          step={t('attributes.reports.dataManagement.referenceData')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      <ComingSoon height={'87vh'} />
    </>
  );

  return <>{TemplateDataWrapper}</>;
};
