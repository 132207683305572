export const DROPDOWN = {
  CONTRACTS: {
    CONTRACT_TYPE: [
      {
        invoiceType: 'M',
        renderComponent: 0,
        value: 'amortized',
        label: 'Amortized',
      },
      {
        renderComponent: 1,
        value: 'do_and_charge',
        label: 'Custom',
      },
    ],
    CPI_INCREASE: [
      { percentage: '0', value: 'n_a', label: 'N/A' },
      { percentage: '4', value: 'not_known', label: 'Not Known' },
      { percentage: null, value: 'other', label: 'Other' },
    ],
  },
};
