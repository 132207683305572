export const Validation = {
  general: {
    required: 'is required',
    abnNumber: 'ABN number must have 11 characters',
    address: 'Address must have maximum 300 characters',
    notes: 'Notes must have maximum 65,535 characters',
    postCode: 'Postal code must have 4 characters',
    contactName: 'Contact name must have maximum 80 characters',
    title: 'Title must have maximum 80 characters',
    customerName: 'Customer name must have maximum 80 characters',
    cellPhone: 'Cell phone must have maximum 10 characters',
    email: 'Email must have maximum 254 characters',
    validEmail: 'Enter valid email address',
    contactPhone: 'Contact phone must have maximum 10 characters',
    organization: 'Organisation must have maximum 100 characters',
    billingContactName: 'Billing contact name must have maximum 80 characters',
    quotingContactName: 'Quoting Contact Name must have maximum 80 characters',
    numericValue: 'Only numeric values are allowed',
    description: 'Description must have maximum 300 characters',
    delivery_instruction:
      'Delivery Instruction must have maximum 300 characters',
    generalTextArea: 'Field must have maximum 300 characters',
  },
  company: {
    companyName:
      'Company name must have minimum 2 characters and maximum 255 characters',
  },
  superAdmin: {
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
  },
  property: {
    name: 'Property Name must have maximum 100 characters',
    contactName: 'Contact Name must have maximum 100 characters',
    landLotNumber: 'Land/Lot Number must have maximum 20 characters',
    ocspNumber: 'OCSP or DP/DN Number must have maximum 30 characters',
    organization: 'Organisation must have maximum 100 characters',
    internalNote: 'Internal Note must have maximum 65535 characters',
    accessCode: 'Access Code must have maximum 15 characters',
    accessNote: 'Access Note must have maximum 4000 characters',
    serviceRequirements:
      'Service Requirements must have maximum 65535 characters',
    safetyRequirements:
      'Safety Requirements must have maximum 65535 characters',
    buildingSize: 'Buiding Size must have maximum 40 characters',
    buildingSizeSqm: 'Buiding Size (sqm) must have maximum 20 characters',
    storyAboveGround: 'Stories Above Ground must have maximum 3 characters',
    storyBelowGround: 'Stories Below Ground must have maximum 3 characters',
    buildingPart: 'Building Part must have maximum 200 characters',
    buildingTenancies: 'Building Tenancies must have maximum 200 characters',
    occupancyPermitNumber:
      'Occupancy Permit Number must have maximum 30 characters',
    occupancyPermitLocation:
      'Occupancy Permit Location must have maximum 300 characters',
  },
  supplier: {
    name: 'Supplier name must have minimum 2 characters and maximum 255 characters',
    business_number:
      'Business Number must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
  },
  officeStaff: {
    name: 'Office Staff name must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
  },
  officeManagement: {
    name: 'Office Management name must have minimum 2 characters and maximum 255 characters',
    postCodeCharacter: 'Post Code must have',
    characters: 'characters',
    phone: 'Contact phone must have 10 characters',
  },
  billingCard: {
    address: 'Field must have maximum 300 characters',
    info: ' Billing Information Not Linked with Accounting Partners',
  },
};
