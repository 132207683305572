import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { CONFIRMATIVE_OPTIONS } from '../../constants/Constants';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { createBillingCard, getBillingList } from '../../store/billing/api';
import {
  resetBillingCreate,
  resetBillingData,
} from '../../store/billing/reducer';
import { snackbarToggle } from '../../store/CommonReducer';
import { getCustomerList } from '../../store/users/customer/api';
import { formattedDate } from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import { CustomDatePicker } from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import BillingForm from './BillingForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  customer: null,
  accounting_partner: null,
  created_at: null,
};

export const defaultValues = {
  customer: null,
  billing_name: '',
  abn_number: '',
  contact_name: '',
  email: '',
  phone: '',
  address: '',
  city: null,
  state: null,
  post_code: '',
};

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [customerOptions, setCustomerOptions] = useState(null);

  const [selectedBill, setSelectedBill] = useState(null);

  const [serverErrors, setServerErrors] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const { billingList, total, totalCount, isLoading } = useSelector(
    (state) => state?.billing?.getList
  );

  const {
    error,
    bill,
    isLoading: createLoading,
  } = useSelector((state) => state?.billing?.create);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.work_order.id'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'billing_name',
        headerName: t('attributes.name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'customer',
        headerName: t('attributes.customer.customer'),
        flex: 1,
        sortable: false,
      },

      {
        field: 'contact_name',
        headerName: t('attributes.customer.contact_name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'email',
        headerName: t('attributes.customer.contact_email'),
        flex: 1,
      },
      {
        field: 'phone',
        headerName: t('attributes.technician.contact_phone'),
        flex: 1,
      },
      {
        field: 'address',
        headerName: t('attributes.address'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.technician.created_at'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formattedDate(row.created_at),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              navigate(`${row?.uuid}`);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = billingList?.map((bill, index) => ({
    ...bill,
    id: bill?.id,
    billing_name: bill?.billing_name,
    customer: bill?.customer_name,
    contact_name: bill?.contact_name,
    contact_email: bill?.email,
    contact_phone: bill?.phone,
    address: bill?.address,
    created_at: bill?.created_at,
  }));

  const onSubmit = (value) => {
    const bodyData = {
      customer_uuid: value?.customer?.value,
      billing_name: value?.billing_name,
      contact_name: value?.contact_name,
      abn_number: value?.abn_number?.length ? value?.abn_number : null,
      email: value?.email,
      phone: value?.phone,
      address: value?.address,
      city: value?.city?.value,
      state: value?.state?.value,
      postcode: value?.post_code,
    };

    dispatch(createBillingCard(bodyData)).catch((err) =>
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg: err?.message,
        })
      )
    );
  };

  const getBillsList = useCallback(() => {
    setIsDataLoading(true);
    dispatch(
      getBillingList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        limit: 1,
        is_accounting_partner: filters?.accounting_partner?.value,
        customer_uuid: filters?.customer?.value,
        created_at: filters?.created_at,
        order: order,
        orderBy: orderBy,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    perPageData,
    currentPage,
    searchText,
    order,
    orderBy,
    filters?.customer,
    filters?.accounting_partner,
    filters?.created_at,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getBillsList, 500), [
    getBillsList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    dispatch(getCustomerList({ limit: -1 })).then((res) => {
      const options = res?.payload?.data?.map((c) => ({
        label: c?.customer_name,
        value: c?.uuid,
      }));

      setCustomerOptions(options);
    });
  }, []);

  useEffect(
    () => () => {
      dispatch(resetBillingData());
      dispatch(resetBillingCreate());
    },
    []
  );

  useEffect(() => {
    if (!createLoading) {
      if (error) {
        setServerErrors(error?.errorDetails);
      }
      if (!error && bill) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: bill?.message,
          })
        );
        setOpen(false);
        getBillsList();
      }
    }
  }, [error, createLoading]);

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const noData = (
    <NewNoDataPage
      icon={<CreditCardIcon />}
      title={t('attributes.billingCard.billingInfoText')}
      singularText={t('attributes.billingCard.billingInfoText')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      createBtnText={`${t('attributes.add')} ${t('attributes.new')}`}
    />
  );

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <RefreshDashboardButton
            label={t('common.refresh')}
            onClick={getBillsList}
          />
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.accounting_partner ||
                filters?.customer ||
                filters?.created_at
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.customer')}
              options={customerOptions}
              width="190px"
              value={filters?.customer}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, customer: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('attributes.billingCard.accountpartner')}
              options={CONFIRMATIVE_OPTIONS}
              width="190px"
              value={filters?.accounting_partner}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, accounting_partner: newVal }))
              }
            />
            <CustomDatePicker
              placeholder={t('attributes.technician.created_at')}
              value={filters?.created_at}
              onChange={(date) =>
                setFilters((pre) => ({ ...pre, created_at: date }))
              }
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading || isLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setSelectedBill('');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      <StyledMainWrapper
        title={t('attributes.billingCard.billingInfoText')}
        btn={
          <>
            <CustomButton
              text={`${t('attributes.add')} ${t('attributes.new')}`}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => setOpen(true)}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {!isInitialLoading && totalCount === 0 && noData}
          {isInitialLoading ? (
            <Box
              sx={{
                width: '100%',
                mt: 2,
                minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomCircularLoader />
            </Box>
          ) : (
            !isInitialLoading && totalCount > 0 && renderedComponent
          )}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.billingCard.addBill')}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpen(false) && setSelectedBill('');
          }}
          footerButton={
            <CustomButton
              text={t('attributes.add')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={isDataLoading}
            />
          }
          width="500px"
        >
          <BillingForm
            id={selectedBill}
            watch={watch}
            trigger={trigger}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
            reset={reset}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Billing;
