import { createSlice } from '@reduxjs/toolkit';

import {
  createUpdateRoutine,
  getPropertyRoutine,
  getRoutineList,
  getRoutineStandardFrequencyList,
} from './api';

const initialRoutineStandardFrequencyList = {
  isLoading: false,
  error: false,
  data: false,
};

const initialListData = {
  isLoading: false,
  error: false,
  data: false,
};

const routineSlice = createSlice({
  name: 'routine',
  initialState: {
    routineStandardFrequencyList: initialRoutineStandardFrequencyList,
    routineList: initialListData,
    propertyRoutineList: initialListData,
    createdOrUpdatedRoutine: initialListData,
  },
  reducers: {
    resetRoutineStandardFrequencyList(state) {
      state.routineStandardFrequencyList = {
        ...initialRoutineStandardFrequencyList,
      };
    },
    resetRoutineList(state) {
      state.routineList = {
        ...initialListData,
      };
    },
    resetCreateRoutine(state) {
      state.createdOrUpdatedRoutine = {
        ...initialListData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Routine Standard Frequency List
      .addCase(getRoutineStandardFrequencyList.pending, (state) => {
        state.routineStandardFrequencyList.isLoading = true;
      })
      .addCase(getRoutineStandardFrequencyList.fulfilled, (state, action) => {
        state.routineStandardFrequencyList.isLoading = false;

        const { data, billingContractDetails } = action.payload || [];

        const routineData = data?.reduce((acc, item) => {
          const routineTypeId = item.routine_types?.id;
          const frequencyCode = item.frequencies?.code;

          // Check if the routine already exists in the accumulator
          let existingRoutine = acc.find(
            (routine) => routine.routine_types.id === routineTypeId
          );

          if (!existingRoutine) {
            // If routine is not in accumulator, add it with an empty frequencies list
            existingRoutine = { ...item, frequencies_list: [] };
            acc.push(existingRoutine);
          }

          // Add the frequency code if it's unique for this routine
          if (
            frequencyCode &&
            !existingRoutine.frequencies_list.includes(frequencyCode)
          ) {
            existingRoutine.frequencies_list.push(frequencyCode);
          }

          /* This code updates the `contract_valid_till` property of an existing 
          routine with the `contract_end_date` from `billingContractDetails`, 
          but only if `contract_end_date` exists. */
          if (billingContractDetails?.contract_end_date) {
            existingRoutine.contract_valid_till =
              billingContractDetails?.contract_end_date;
          }

          return acc;
        }, []);

        state.routineStandardFrequencyList.data = routineData;
      })
      .addCase(getRoutineStandardFrequencyList.rejected, (state, action) => {
        state.routineStandardFrequencyList.isLoading = false;
        state.routineStandardFrequencyList.error = action.error.message;
      })
      // Get Routine List
      .addCase(getRoutineList.pending, (state) => {
        state.routineList.isLoading = true;
      })
      .addCase(getRoutineList.fulfilled, (state, action) => {
        state.routineList.isLoading = false;
        state.routineList.data = action.payload;
      })
      .addCase(getRoutineList.rejected, (state, action) => {
        state.routineList.isLoading = false;
        state.routineList.error = action.error.message;
      })
      // Get Property Routine
      .addCase(getPropertyRoutine.pending, (state) => {
        state.propertyRoutineList.isLoading = true;
      })
      .addCase(getPropertyRoutine.fulfilled, (state, action) => {
        state.propertyRoutineList.isLoading = false;
        state.propertyRoutineList.data = action.payload;
      })
      .addCase(getPropertyRoutine.rejected, (state, action) => {
        state.propertyRoutineList.isLoading = false;
        state.propertyRoutineList.error = action.error.message;
      })

      // Create and Update Property Routine
      .addCase(createUpdateRoutine.pending, (state) => {
        state.createdOrUpdatedRoutine.isLoading = true;
      })
      .addCase(createUpdateRoutine.fulfilled, (state, action) => {
        state.createdOrUpdatedRoutine.isLoading = false;
        state.createdOrUpdatedRoutine.data = action.payload;
      })
      .addCase(createUpdateRoutine.rejected, (state, action) => {
        state.createdOrUpdatedRoutine.isLoading = false;
        state.createdOrUpdatedRoutine.error = action.error.message;
      });
  },
});

export const {
  resetRoutineStandardFrequencyList,
  resetRoutineList,
  resetCreateRoutine,
} = routineSlice.actions;

export default routineSlice.reducer;
