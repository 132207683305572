import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { googleLogout } from '@react-oauth/google';

// Internal imports
import { DarkLogo, LogoIcon } from '../../assets/images';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { LOGIN_TYPE } from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { SIDEBAR_MENU } from '../../constants/SidebarMenu';
import { DRAWER_WIDTH, ICON_DRAWER_WIDTH } from '../../constants/Typography';
import { getUserRole } from '../../hoc/Permission';

const Sidebar = ({ isNavbarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { t } = useTranslation();

  const [openSubmenu, setOpenSubmenu] = useState([]);
  const firstLoad = useRef(true);

  // Logout handler
  const handleLogout = async () => {
    const type = localStorage.getItem('loginType');

    if (type == LOGIN_TYPE['microsoft']) {
      instance.logoutPopup({
        postLogoutRedirectUri: ROUTENAME.DEFAULT_ROUTE,
      });
    }
    if (type == LOGIN_TYPE['google']) {
      googleLogout();
    }
    localStorage.clear();
    navigate(ROUTENAME.DEFAULT_ROUTE);
  };

  // Filter menu based on user role
  const role = getUserRole();
  const { profileDetails } = useSelector((state) => state.common);
  const permissionRoute =
    profileDetails?.portals?.[0]?.permissions?.[role] || {};

  function filterSidebarByPermissions(sidebar, permissions) {
    return sidebar.reduce((filteredSidebar, item) => {
      // Check if the main item (parent) is accessible
      const isMainAccessible = permissions[item.id]?.isAccessible;

      if (isMainAccessible) {
        // If the item has submenus, filter the submenus based on accessibility
        if (item.subMenu) {
          const accessibleSubMenu = item.subMenu.filter(
            (subItem) => permissions[subItem.id]?.isAccessible
          );

          // Only add the parent if it has accessible submenus
          if (accessibleSubMenu.length > 0) {
            filteredSidebar.push({ ...item, subMenu: accessibleSubMenu });
          }
        } else {
          // Include the main item without submenus if there are no submenus
          filteredSidebar.push(item);
        }
      }

      return filteredSidebar;
    }, []);
  }

  const filteredMenu = filterSidebarByPermissions(
    SIDEBAR_MENU[role],
    permissionRoute
  );

  // Handle click on menu item
  const handleMenuClick = (menu) => {
    if (menu.subMenu) {
      setOpenSubmenu((prevOpenSubmenus) => {
        if (prevOpenSubmenus.includes(menu.id)) {
          return prevOpenSubmenus.filter((id) => id !== menu.id); // Close submenu if open
        } else {
          return [...prevOpenSubmenus, menu.id]; // Open submenu
        }
      });
    } else {
      navigate(menu.path);
    }
  };

  // Open submenus based on the current path on first load
  useEffect(() => {
    if (firstLoad.current) {
      filteredMenu?.forEach((menu) => {
        if (
          menu.subMenu?.some((subMenu) =>
            location.pathname.includes(subMenu.path)
          )
        ) {
          setOpenSubmenu((prev) => [...prev, menu.id]);
        }
      });
      firstLoad.current = false;
    }
  }, [location.pathname, filteredMenu]);

  const renderMenuItem = (menu) => {
    const isMenuSelected = location.pathname.startsWith(`/${menu.path}`);
    const isSubMenuSelected = menu.subMenu?.some((subMenu) =>
      location.pathname.startsWith(`/${menu.path}/${subMenu.path}`)
    );

    return (
      <Box key={menu.id}>
        <ListItem disablePadding onClick={() => handleMenuClick(menu)}>
          <ListItemButton
            sx={{
              gap: isNavbarOpen ? 1 : 0,
              overflow: 'hidden',
            }}
            selected={isMenuSelected && !isSubMenuSelected} // Main menu selected if submenu is not selected
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {menu.icon && (
                <Tooltip
                  title={!isNavbarOpen ? menu.label : ''}
                  placement="right"
                  arrow
                >
                  {!isNavbarOpen && (
                    <ListItemIcon
                      sx={{
                        pl: '4px',
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                  )}
                </Tooltip>
              )}
              {isNavbarOpen && (
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {menu.label}
                </Typography>
              )}
            </Box>
            {menu.subMenu &&
              (openSubmenu.includes(menu.id) ? (
                <ListItemIcon sx={{ pl: '4px' }}>
                  <ExpandLess sx={{ color: 'black' }} />
                </ListItemIcon>
              ) : (
                <ListItemIcon sx={{ pl: '4px' }}>
                  <ExpandMore sx={{ pt: '1px', color: 'black' }} />
                </ListItemIcon>
              ))}
          </ListItemButton>
        </ListItem>
        {menu.subMenu && (
          <Collapse
            in={openSubmenu.includes(menu.id)}
            timeout="auto"
            unmountOnExit
          >
            <List disablePadding>
              {menu?.subMenu?.map((subMenu, idx) => {
                const isSubSelected = location.pathname.startsWith(
                  `/${menu.path}/${subMenu.path}`
                );

                return (
                  <ListItemButton
                    sx={{ pl: isNavbarOpen ? 6 : 2 }}
                    key={idx}
                    onClick={() => navigate(`${menu.path}/${subMenu.path}`)}
                    selected={isSubSelected}
                  >
                    <Tooltip
                      title={!isNavbarOpen ? subMenu.label : ''}
                      placement="right"
                      arrow
                    >
                      <ListItemIcon
                        sx={{
                          pl: '4px',
                        }}
                      >
                        {subMenu.icon}
                      </ListItemIcon>
                    </Tooltip>
                    {isNavbarOpen && (
                      <Typography
                        variant={isSubSelected ? 'body1' : 'body2'}
                        sx={{ ml: 1 }}
                      >
                        {subMenu.label}
                      </Typography>
                    )}
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        )}
      </Box>
    );
  };

  const drawerContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Ensure the sidebar takes the full height of the viewport
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          m: '24px 0',
        }}
      >
        {isNavbarOpen ? (
          <img src={DarkLogo} alt="Logo" />
        ) : (
          <img src={LogoIcon} alt="Logo" />
        )}
      </Toolbar>
      <Divider />
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <List>{filteredMenu?.map(renderMenuItem)}</List>
      </Box>
      <Box
        sx={{
          borderTop: `1px solid ${DIVIDER_COLOR}`,
          backgroundColor: 'background.paper',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleLogout}
              sx={{ justifyContent: 'space-between' }}
            >
              <Tooltip
                title={!isNavbarOpen ? t('common.logout') : ''}
                placement="right"
                arrow
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    ...(isNavbarOpen ? { pl: 2 } : { pl: '4px' }),
                  }}
                >
                  <ExitToAppOutlinedIcon />
                </ListItemIcon>
              </Tooltip>
              {isNavbarOpen && (
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {t('common.logout')}
                </Typography>
              )}
            </ListItemButton>

            {isNavbarOpen && (
              <Box sx={{ display: 'flex', alignItems: 'baseline', ml: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'var(--font-grey-color)',
                    pl: 5,
                  }}
                >
                  {`${t('label.version')}:`}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'var(--font-grey-color)',
                    pl: 0.5,
                    pr: 1,
                  }}
                >
                  {profileDetails?.app_version || ''}
                </Typography>
              </Box>
            )}
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  if (!profileDetails) {
    return null;
  }

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH },
        flexShrink: { sm: 0 },
      }}
      aria-label="Sidebar Menus"
    >
      <Drawer
        variant="temporary"
        open={!isNavbarOpen}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH,
            overflow: 'hidden',
          },
        }}
      >
        {drawerContent}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isNavbarOpen ? DRAWER_WIDTH : ICON_DRAWER_WIDTH,
            overflowX: 'hidden',
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
