import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  CUSTOMER_DEFECT_STATUS,
  DEFECT_SEVERITY,
} from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getPropertyAssetsList,
  getPropertyList,
} from '../../store/property/api';
import { resetAssetsList } from '../../store/property/reducer';
import { getCustomerDefectList } from '../../store/users/customer/api';
import { formatStatus, formattedDate, loggedInUserDetail } from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ViewDashboardDefect from './ViewDashboardDefect';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  status: null,
  asset: null,
  severity: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
};

const DefectList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status } = useParams();
  const { dashboardFilter } = useOutletContext();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...dashboardFilter,
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [defects, setDefects] = useState(null);
  const [selectedDefect, setSelectedDefect] = useState(null);

  const [assetOptions, setAssetsOptions] = useState([]);
  const [assetDropdownLoading, setAssetDropdownLoading] = useState(null);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: 'Defect ID',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.id ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            >
              {row.id}
            </Typography>
          ) : (
            '-'
          ),
      },
      {
        field: 'severity',
        headerName: 'Severity',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row?.severity),
      },
      {
        field: 'property_name',
        headerName: 'Property Name',
        flex: 1,
        sortable: false,
      },

      {
        field: 'asset',
        headerName: 'Asset',
        flex: 1,
        sortable: false,
      },
      {
        field: 'raised_by',
        headerName: 'Raised By',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row?.status ? formatStatus(row.status) : '-'),
      },
      {
        field: 'identified_date',
        headerName: 'Identified Date',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formattedDate(row?.technician?.created_at),
      },
      {
        field: 'edit',
        headerName: 'Actions',
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setOpen(true);
              setSelectedDefect(row);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = defects?.map((defect, index) => ({
    ...defect,
    id: defect?.id || index + 1,
    severity: defect?.severity,
    property_name: defect?.property_name || defect?.property_uuid,
    asset: defect?.asset?.label || '-',
    raised_by: defect?.technician?.technician_name || '-',
    description: defect?.defect_description,
    status: defect?.asset?.status?.display_name,
  }));

  const getDefects = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getCustomerDefectList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        // TODO: Right now BE api is not ready once ready change the keys.
        property_uuid: filters?.property?.value,
        asset_uuid: filters?.asset?.value,
        severity: filters?.severity?.value,
        status: filters?.status?.value,
        fromDate: filters?.dateRange?.fromDate,
        toDate: filters?.dateRange?.toDate,
        search: searchText,
        list_column_names: visibleFieldsString,
      })
    )
      .then((res) => setDefects(res?.payload?.data?.[0] || []))
      .finally(() => setIsDataLoading(false));

    setColumnVisibilityModel(visibleFieldsString);
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getDefects, 500), [
    getDefects,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(getPropertyList(req));
  }, []);

  // For asset dropdown
  useEffect(() => {
    if (filters?.property?.value) {
      setAssetDropdownLoading(true);
      const req = {
        limit: -1,
        property_uuid: filters?.property?.value,
      };

      dispatch(getPropertyAssetsList(req)).then((res) => {
        setAssetsOptions(
          res?.payload?.data?.map((d) => ({ label: d?.label, value: d?.uuid }))
        );
        setAssetDropdownLoading(false);
      });
    }
  }, [filters?.property]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    if (status) {
      setFilters((pre) => ({
        ...pre,
        status: CUSTOMER_DEFECT_STATUS?.find((s) => s.value === status),
      }));
      setIsFilterComponentVisible(true);
    }

    return () => {
      dispatch(resetAssetsList());
    };
  }, []);

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <RefreshDashboardButton label="Refresh" />
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.dateRange?.fromDate ||
                filters?.dateRange?.toDate ||
                filters?.severity ||
                filters?.status ||
                filters?.asset ||
                filters?.property_name
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder="Severity"
              options={DEFECT_SEVERITY}
              width="190px"
              value={filters?.severity}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, severity: newVal }))
              }
            />
            <Autocomplete
              placeholder="Property Name"
              options={propertyDropdownData}
              isLoadingData={propertyDropdownLoading}
              width="190px"
              value={filters?.property}
              onChange={(e, newVal) => {
                setFilters((pre) => ({
                  ...pre,
                  property: newVal,
                  asset: null,
                }));
                setAssetsOptions([]);
              }}
            />
            <Autocomplete
              placeholder="Asset"
              options={assetOptions}
              width="190px"
              value={filters?.asset}
              isLoadingData={assetDropdownLoading}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, asset: newVal }))
              }
            />
            <Autocomplete
              placeholder="Status"
              options={CUSTOMER_DEFECT_STATUS}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, status: newVal }))
              }
            />
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: val[0], toDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: null, toDate: null },
                }));
              }}
              fromDate={filters?.dateRange?.fromDate}
              toDate={filters?.dateRange?.toDate}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={1}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {}}
          />
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          open={open}
          title={selectedDefect?.id}
          onClose={() => {
            setOpen(false);
          }}
          width={500}
        >
          <ViewDashboardDefect
            defectUUID={selectedDefect?.uuid}
            workOrderUUID={selectedDefect?.work_order_uuid}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectList;
