import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import { getPropertyList } from '../../store/property/api';
import {
  getQuotesList,
  getQuotesPropertyDropdownList,
  getQuotesStatusDropdownList,
} from '../../store/quotes/api';
import { resetQuoteList } from '../../store/quotes/reducer';
import { getCustomerList } from '../../store/users/customer/api';
import {
  formatPriceWithDecimalValue,
  formatStatus,
  formattedDate,
} from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  customer: null,
  property: null,
  status: null,
  dateRange: {
    fromDueDate: null,
    toDueDate: null,
    fromQuoteDate: null,
    toQuoteDate: null,
  },
};

const SuperAdminDefectsQoutes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [propertyDetailsList, setPropertyDetailsList] = useState([]);

  const [searchText, setSearchText] = useState('');

  const [customerOption, setCustomerOptions] = useState(null);

  const { quoteList, total } = useSelector((state) => state.quotes.get);
  const { propertyList } = useSelector((state) => state.property);

  const { data: quoteStatusList, isLoading: quoteStatusListLoading } =
    useSelector((state) => state.quotes.quotesStatusDropdownList);

  const quotesPropertyList = getDropdownDataHook({
    data: propertyDetailsList,
    labelName: 'property_name',
    valueName: 'uuid',
  });

  const quotesStatusList = getDropdownDataHook({
    data: quoteStatusList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });

  const findProperty = (property_uuid) => {
    const property = propertyDetailsList?.find(
      (prop) => prop.uuid === property_uuid
    );

    return property;
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'work_order_id',
        headerName: t('attributes.work_order.work_order_ID'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'quote_id',
        headerName: t('attributes.work_order.quote_id'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'customer',
        headerName: t('roles.customer'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property_name',
        headerName: t('attributes.property.propertyName'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property_address',
        headerName: `${t('attributes.property.property')} ${t('attributes.address')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('attributes.property.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row?.status),
      },
      {
        field: 'quote_date',
        headerName: t('attributes.work_order.quote_date'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.quote_date ? formattedDate(row?.quote_date) : '-',
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) =>
          row?.due_date ? formattedDate(row?.due_date) : '-',
      },
      {
        field: 'amount',
        headerName: t('attributes.amount'),
        flex: 1,
        renderCell: ({ row }) => `${formatPriceWithDecimalValue(row.amount)}`,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => <View onClick={() => {}} />,
      },
    ];

    return baseColumns;
  }, []);

  const rows = quoteList?.map((quote, index) => ({
    ...quote,
    work_order_id: quote?.work_order?.woid || index + 1,
    quote_id: `Q-${quote?.id}` || index + 1,
    customer: quote?.work_order?.customer?.display_name || '-',
    property_name: findProperty(quote?.property_uuid)?.property_name,
    property_address: findProperty(quote?.property_uuid)?.address,
    status: quote?.status,
    quote_date: quote?.quote_date || '',
    due_date: quote?.work_order?.due_date || '',
    amount: quote?.quotation_items.reduce(
      (sum, item) => sum + item?.sell_price * item?.qty,
      0
    ),
  }));

  const getQoutes = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getQuotesList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        customer_uuid: filters?.customer?.value,
        propertyUUID: filters?.property?.value,
        status: filters?.status?.value,
        fromDueDate: filters.dateRange?.fromDueDate,
        toDueDate: filters.dateRange?.toDueDate,
        fromQuoteDate: filters.dateRange?.fromQuoteDate,
        toQuoteDate: filters.dateRange?.toQuoteDate,
        search: searchText,
      })
    ).finally(() => setIsDataLoading(false));

    setColumnVisibilityModel(visibleFieldsString);
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
  ]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters?.customer?.value,
    };
    dispatch(getPropertyList(req));
  }, [filters?.customer]);

  useEffect(() => {
    dispatch(getQuotesPropertyDropdownList());
    dispatch(getQuotesStatusDropdownList());

    return () => {
      dispatch(resetQuoteList());
    };
  }, []);

  // For customer dropdown
  useEffect(() => {
    dispatch(getCustomerList({ limit: -1 })).then((res) => {
      const options = res?.payload?.data?.map((c) => ({
        label: c?.customer_name,
        value: c?.uuid,
      }));

      setCustomerOptions(options || []);
    });
  }, []);

  useEffect(() => {
    setPropertyDetailsList(propertyList?.data?.data);
  }, [propertyList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getQoutes, 500), [getQoutes]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <RefreshDashboardButton label={t('common.refresh')} />
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters.dateRange.fromDueDate ||
                filters.dateRange.toDueDate ||
                filters.dateRange?.fromQuoteDate ||
                filters.dateRange?.toQuoteDate ||
                filters?.status ||
                filters?.customer ||
                filters?.property
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('roles.customer')}
              options={customerOption}
              width="190px"
              value={filters?.customer}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, customer: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('attributes.property.property')}
              options={quotesPropertyList}
              width="190px"
              value={filters?.property}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, property: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('attributes.property.status')}
              options={quotesStatusList}
              isLoadingData={quoteStatusListLoading}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, status: newVal }))
              }
            />
            <CustomDateRangePicker
              placeholder={t('attributes.invoice.due_date')}
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromDueDate: val[0], toDueDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromDueDate: null, toDueDate: null },
                }));
              }}
              fromDate={filters.dateRange?.fromDueDate}
              toDate={filters.dateRange?.toDueDate}
              placement="bottomEnd"
            />
            <CustomDateRangePicker
              placeholder={t('attributes.invoice.quote_date')}
              onOkClick={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromQuoteDate: val[0], toQuoteDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange: { fromQuoteDate: null, toQuoteDate: null },
                }));
              }}
              fromDate={filters.dateRange?.fromQuoteDate}
              toDate={filters.dateRange?.toQuoteDate}
              placement="bottomEnd"
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.superAdmin.add_defect_quote')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {}}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
    </>
  );
};

export default SuperAdminDefectsQoutes;
