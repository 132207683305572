import moment from 'moment';
import Papa from 'papaparse';

import {
  ADDRESS_COUNTRY,
  ADDRESS_REGION_CODE,
  GOOGLE_API_KEY,
} from '../constants/Constants';
import { axiosInstanceGoogleMapAPI } from '../store/axios';
import { EXPORT_FILE_DATE_TIME_FORMAT } from './ExportConstant';

export const PER_PAGE_DATA = 10;
export const FIRST_PAGE = 1;

export const SQFT_TO_SQM = (sqft) => {
  // Check if sqft is a number or a numeric string
  if (!isNaN(sqft) && sqft !== null && sqft !== '') {
    const sqm = parseFloat(sqft) * 0.092903;

    return sqm.toFixed(2);
  }
  // Return 0 if the input is not a valid number

  return 0;
};

// Common function to validate the address using Google API
export const validateAddress = async ({
  address,
  city,
  state,
  postcode,
  fieldName,
  setServerErrors = () => {},
  setCoords = () => {},
  clearErrors = () => {},
}) => {
  if (!address || !city?.value || !state?.value || !(postcode?.length > 3)) {
    clearErrors(fieldName.addressKey);
    clearErrors(fieldName.stateKey);
    clearErrors(fieldName.cityKey);
    clearErrors(fieldName.postCodeKey);

    return false;
  }

  // Check the address against Google API
  const checkAddress = async () => {
    const params = new URLSearchParams();

    params.append('key', GOOGLE_API_KEY);

    try {
      const response = await axiosInstanceGoogleMapAPI.post(
        `?${params.toString()}`,
        {
          address: {
            regionCode: ADDRESS_REGION_CODE,
            addressLines: [
              `${address}, ${city.value} ${state.value} ${postcode}, ${ADDRESS_COUNTRY}`,
            ],
          },
        }
      );

      return response.data;
    } catch (error) {
      return null; // Return null on error
    }
  };

  // Perform the address validation
  const resp = await checkAddress();

  if (!resp) return;

  let hasError = false;
  const { unconfirmedComponentTypes, addressComponents } = resp.result.address;
  const { geocode } = resp.result;

  clearErrors(fieldName.addressKey);
  clearErrors(fieldName.stateKey);
  clearErrors(fieldName.cityKey);
  clearErrors(fieldName.postCodeKey);
  // Check for specific address errors
  if (unconfirmedComponentTypes) {
    if (unconfirmedComponentTypes.includes('postal_code')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.postCodeKey,
          message: `${fieldName.postCodeValue} is not correct.`,
        },
      ]);
    }

    if (unconfirmedComponentTypes.includes('locality')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.cityKey,
          message: `Combination of ${fieldName.cityValue} and ${fieldName.postCodeValue} is not valid`,
        },
      ]);
    }

    if (unconfirmedComponentTypes.includes('administrative_area_level_1')) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.stateKey,
          message: `Combination of ${fieldName.stateValue} and ${fieldName.postCodeValue} is not valid`,
        },
      ]);
    }

    const filteredUnconfirmedComponentTypes = unconfirmedComponentTypes.filter(
      (val) =>
        val !== 'postal_code' ||
        val !== 'locality' ||
        val !== 'administrative_area_level_1'
    );

    const suspiciousComponents = addressComponents
      .filter(
        (component) =>
          filteredUnconfirmedComponentTypes.includes(component.componentType) &&
          component.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS'
      )
      .map((component) => component.componentName.text);

    if (suspiciousComponents.length > 0) {
      hasError = true;
      setServerErrors((prev) => [
        ...prev,
        {
          field: fieldName.addressKey,
          message: `${suspiciousComponents.join(' ')} address not found.`,
        },
      ]);
    }
  }

  // Set the result or clear errors if no issues are found
  if (hasError) {
    setCoords(null);
  } else {
    clearErrors(fieldName.addressKey);
    clearErrors(fieldName.stateKey);
    clearErrors(fieldName.cityKey);
    clearErrors(fieldName.postCodeKey);
    // Check for specific address errors
    setCoords({
      latitude: geocode?.location?.latitude,
      longitude: geocode?.location?.longitude,
    });
  }

  return hasError;
};

export const dateTimeRange = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  const startFormattedDate = startDate.format('DD/MM/YYYY');
  const endFormattedDate = endDate.format('DD/MM/YYYY');

  const startTime = startDate.format('hh:mm A');
  const endTime = endDate.format('hh:mm A');

  return { startFormattedDate, endFormattedDate, startTime, endTime };
};

export const longDateTimeRange = (start, end) => {
  if (!start || !end) return '-';

  const startDate = moment(start);
  const endDate = moment(end);

  if (startDate.isSame(endDate, 'day')) {
    return `${startDate.format('dddd, DD MMMM, YYYY hh:mm A')} to ${endDate.format('hh:mm A')}`;
  } else {
    return `${startDate.format('dddd, DD MMMM, YYYY hh:mm A')} to ${endDate.format('dddd, DD MMMM, YYYY hh:mm A')}`;
  }
};

const escapeCSV = (value) => {
  if (value === null || value === undefined) return '';

  if (Array.isArray(value) && typeof value[0] === 'string') {
    return value.join('|');
  }

  return `"${String(value).replace(/"/g, '""')}"`;
};

const getValues = (key, obj) => {
  if (!key.includes('.')) {
    return obj[key]; // Directly return the value if there’s no dot notation
  }

  // For dot-notated keys
  const path = key.split('.');

  return path.reduce((acc, part) => acc && acc[part], obj);
};

export const exportToCSV = (
  selectedRows,
  columns,
  fileName,
  columns_to_map = null
) => {
  // Create CSV content starting with headers
  let csvContent = `data:text/csv;charset=utf-8,${columns.join(',')}\n`;

  // Add data rows
  selectedRows.forEach((row) => {
    const rowData = (columns_to_map ? Object.keys(columns_to_map) : columns)
      .map((field) =>
        escapeCSV(
          columns_to_map ? getValues(field, row) : row[field.toLowerCase()]
        )
      )
      .join(',');

    csvContent += `${rowData}\n`;
  });

  // Create and trigger download
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute(
    'download',
    `${moment().format(EXPORT_FILE_DATE_TIME_FORMAT)}_${fileName}.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up
};

export const calculateTimeDuration = ({ startTime, endTime }) => {
  let hours = '0';
  let minutes = '00';

  if (endTime && startTime) {
    const startMoment = moment(startTime, 'YYYY-MM-DD HH:mm:ss');
    const endMoment = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

    // Check if both start and end are valid moment objects
    if (startMoment.isValid() && endMoment.isValid()) {
      const durationInSeconds = endMoment.diff(startMoment, 'seconds');
      const duration = moment.duration(durationInSeconds, 'seconds');

      if (duration.asHours() >= 0 && duration.minutes() >= 0) {
        // Get hours and minutes separately
        hours = Math.floor(duration.asHours());
        minutes = duration.minutes();
      }
    }
  }

  return `${hours}h ${minutes}m`;
};

export const readCSVFileData = ({ file, setSelectedDocument }) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      setSelectedDocument((prev) => ({
        ...prev,
        data:
          results.data.length > 20 ? results.data.slice(0, 20) : results.data,
      }));
    },
  });
};

export const routineTextFromCode = (code) => {
  switch (code) {
    case 'M':
      return 'Month';
    case 'D':
      return 'Day';
    case 'W':
      return 'Weekly';
    case 'Y':
      return 'Yearly';
    default:
      return code;
  }
};
