import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { capitalize, debounce } from 'lodash';

import { exportToCSV } from '../../constants/common';
import { ROUTINE_TYPES } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { CUSTOMER } from '../../constants/Roles';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../constants/Typography';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createUpdateRoutine,
  getPropertyRoutine,
} from '../../store/routine/api';
import { formattedDate, loggedInUserRole } from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { FilterSection } from '../CommonComponents/FilterComponent';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import RoutineForm from './RoutineForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const RoutineList = ({ selectedProperty }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [open, setOpen] = useState(false);
  const [routineId, setRoutineId] = useState('');
  const [selectedRows, setSelectedRows] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [routineDetailsData, setRoutineDetailsData] = useState();

  const { data: routinesList, isLoading } = useSelector(
    (state) => state.routine.propertyRoutineList
  );

  const {
    data: createdOrUpdatedRoutine,
    isLoading: createdOrUpdatedRoutineLoading,
  } = useSelector((state) => state.routine.createdOrUpdatedRoutine);

  const { isLoading: loadingFrequency } = useSelector(
    (state) => state.frequency.frequencyList
  );

  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'routine_name',
        headerName: t('attributes.property.routines'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'type',
        headerName: t('attributes.property.type'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'wo_creation_mode',
        headerName: t('attributes.property.wo_creation_mode'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'Frequency',
        headerName: 'Frequency',
        flex: 2,
        sortable: false,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
              flexWrap: 'wrap',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            {row.frequency.map((item, index) => (
              <Box
                key={index}
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.frequencies?.code}
              </Box>
            ))}
          </Box>
        ),
      },
      {
        field: 'created_at',
        headerName: t('common.createdAt'),
        flex: 1,
        sortable: false,
      },
    ];

    if (loggedInUserRole() !== CUSTOMER) {
      baseColumns.push({
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setOpen(true);
              setRoutineId(row?.id);
            }}
          />
        ),
      });
    }

    return baseColumns;
  }, []);

  const getAllRoutineList = useCallback(() => {
    setSelectedRows(null);
    if (!selectedProperty?.uuid) {
      return;
    }
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');
    const req = {
      order: order,
      orderBy: orderBy,
      page: currentPage,
      size: perPageData,
      search: searchText,
      property_uuid: selectedProperty?.uuid,
      type: 'standard,custom',
      list_columns_names: visibleFieldsString,
    };

    dispatch(getPropertyRoutine(req)).finally(() => {
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    searchText,
    columns,
    columnVisibilityModel,
    selectedProperty,
  ]);

  useEffect(() => {
    const detail =
      routinesList &&
      routinesList?.data?.find((item) => item?.id === routineId);

    setRoutineDetailsData(detail);
  }, [routinesList, routineId]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllRoutineList, 500), [
    getAllRoutineList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = routinesList
    ? routinesList?.data?.map((item) => ({
        ...item,
        routine_name: item.name,
        frequency: item.standard_frequencies,
        created_at: formattedDate(item?.created_at),
        wo_creation_mode: item?.wo_creation_mode || '-',
        type: capitalize(item?.type),
      }))
    : null;

  useEffect(() => {
    if (!createdOrUpdatedRoutineLoading) {
      if (createdOrUpdatedRoutine) {
        setOpen(false);
        debouncedFetchData();
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createdOrUpdatedRoutine.message,
          })
        );
      }
    }
  }, [createdOrUpdatedRoutineLoading]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row?.id)));
  };

  const onSubmit = (data) => {
    const checkedItems = data?.frequency_form?.filter(
      (item) =>
        item.isChecked && item?.standardFrequencyType === ROUTINE_TYPES.CUSTOM
    );

    const frequencyForms = checkedItems?.map((item) => ({
      frequency: {
        frequency_id: item?.id,
        tolerance: item?.tolerance,
        tolerance_type: item?.tolerance_type,
      },
      form: {
        form_id: item.selectedForm?.value,
      },
      type: item?.standardFrequencyType,
    }));

    const requestData = {
      property_id: routineId
        ? routineDetailsData?.standard_frequencies[0]?.property?.id
        : selectedProperty?.id,
      type: routineId ? routineDetailsData?.type : ROUTINE_TYPES.CUSTOM,
      equipment_type_id: data?.equipmentType?.value,
      code: data?.referenceCode,
      name: data?.name,
      display_name: data?.name,
      standard_id: data?.standard?.value,
      frequency_form: frequencyForms,
      routine_id: routineId,
    };

    dispatch(createUpdateRoutine({ data: requestData }));
  };

  const noData = (
    <>
      <NewNoDataPage
        icon={<PersonOutlineOutlinedIcon />}
        title={t('attributes.property.routines')}
        createBtnText={t('attributes.property.add_routine_service_type')}
        singularText={t('attributes.property.routine')}
        filterHeight={380}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.property.add_routine_service_type')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                setOpen(true);
                reset({ name: '' });
                setRoutineId();
              }}
            />
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.ROUTINE,
                    EXPORT_FILE_NAMES.ROUTINE,
                    COLUMNS_TO_MAP.ROUTINE
                  );
                }
              }}
            />
          </>
        }
        isSubDetails={true}
      >
        {!isLoading && routinesList?.total_records === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(false)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(false)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          routinesList?.total_records > 0 && (
            <Box sx={{ paddingTop: '16px' }}>
              <FilterSection
                onFilterBtnClick={() => {}}
                isRefresh={false}
                isFilter={false}
                isReset={false}
                searchText={searchText}
                onSearchChange={(e) => setSearchText(e.target.value)}
                isResetButtonVisible={searchText}
                sx={{ marginRight: '16px' }}
              />
              <CustomGridTable
                columns={columns}
                rows={rows}
                total={routinesList?.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPageData={perPageData}
                setPerPageData={setPerPageData}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                columnVisibilityModel={columnVisibilityModel}
                onRowSelectionModelChange={handleSelectionChange}
                setColumnVisibilityModel={setColumnVisibilityModel}
                noData={<NoRecordFound />}
                isLoading={isLoading}
                filterHeight={filterHeight(false)}
              />
            </Box>
          )
        )}
      </StyledMainWrapper>

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            routineId
              ? t('attributes.property.edit_routine_service_type')
              : t('attributes.property.add_routine_service_type')
          }
          onClose={() => {
            setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={loadingFrequency || createdOrUpdatedRoutineLoading}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <RoutineForm
            id={routineId}
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default RoutineList;
