import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Typography, styled } from '@mui/material';

import { DIVIDER_COLOR, WARNING } from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import {
  deleteBillingCard,
  getBill,
  updateBillingCard,
} from '../../store/billing/api';
// eslint-disable-next-line import-helpers/order-imports
import { Validation } from '../../constants/FieldValidationMsg';
// eslint-disable-next-line import-helpers/order-imports
import { snackbarToggle } from '../../store/CommonReducer';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../CommonComponents/Modal';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import { defaultValues } from './Billing';
import BillingForm from './BillingForm';

const StyledHeader = styled(Box)({
  padding: '16px',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const SideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  padding: '16px',
  width: '100%',
});

const KeyValueComponent = ({
  name,
  value,
  minWidth = '180px',
  isCapitalize = true,
}) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography
      variant="body2"
      sx={{ textTransform: isCapitalize ? 'capitalize' : '' }}
    >
      {value}
    </Typography>
  </Box>
);

const ViewBillingCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openEditConfirmationModal, setOpenEditConfirmationModal] =
    useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);

  const [serverErrors, setServerErrors] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );
  const {
    error,
    bill,
    isLoading: createLoading,
  } = useSelector((state) => state?.billing?.create);

  useEffect(() => {
    if (serverErrors) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const getBillData = () => {
    setLoading(true);
    dispatch(getBill({ uuid: params?.uuid }))
      .then((res) => setDetails(res?.payload?.data?.[0]))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getBillData();
  }, [params?.uuid]);

  const onSubmit = (value) => {
    const bodyData = {
      customer_uuid: value?.customer?.value,
      billing_name: value?.billing_name,
      contact_name: value?.contact_name,
      abn_number: value?.abn_number,
      email: value?.email,
      phone: value?.phone,
      address: value?.address,
      city: value?.city?.value,
      state: value?.state?.value,
      postcode: value?.post_code,
    };

    dispatch(updateBillingCard({ uuid: params?.uuid, data: bodyData }));
  };

  const onDelete = () =>
    dispatch(deleteBillingCard({ uuid: details?.uuid })).then((res) => {
      dispatch(
        snackbarToggle({
          snackbarOpen: true,
          snackbarMessage: res?.payload?.message,
          isErrorMsg: false,
        })
      );
      setOpenConfirmationModal(false);
      navigate(`/${ROUTENAME.COMMERCIAL}/${ROUTENAME.BILLING}`);
    });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    if (!createLoading) {
      if (error) {
        setServerErrors(error?.errorDetails);
      }
      if (!error && bill) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: bill?.message,
          })
        );
        setOpen(false);
      }
    }
  }, [error, createLoading]);

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.billingCard.billingInfoText')}
          variant="body1"
          isStep={true}
          step={details?.id}
          handleDefaultStep={() =>
            navigate(`/${ROUTENAME.COMMERCIAL}/${ROUTENAME.BILLING}`)
          }
        ></MainWrapper>
      </Box>
      <Box sx={{ overflow: 'auto', height: 'calc(100vh - 310px)' }}>
        <Box>
          <StyledHeader
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">
              {t('attributes.billingCard.billingInfoText')}
            </Typography>
            <EditIcon onClick={() => setOpen(true)} />
          </StyledHeader>
          <Divider />
          <StyledHeader>
            <Typography variant="body1">
              {t('attributes.billingCard.accountDetails')}
            </Typography>
          </StyledHeader>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <SideWrapper>
              <KeyValueComponent
                name={t('attributes.customer.customer')}
                value={details?.customer_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.billingCard.abn')}
                value={details?.abn_number || '-'}
              />
            </SideWrapper>
            <SideWrapper>
              <KeyValueComponent
                name={t('attributes.billingCard.billName')}
                value={details?.billing_name || '-'}
              />
            </SideWrapper>
          </Box>
          <Divider />
        </Box>
        <Box>
          <StyledHeader>
            <Typography variant="body1">
              {t('attributes.billingCard.contactDetails')}
            </Typography>
          </StyledHeader>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <SideWrapper>
              <KeyValueComponent
                name={t('attributes.supplier.contact_name')}
                value={details?.contact_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.supplier.contact_phone')}
                value={details?.phone || '-'}
              />
            </SideWrapper>
            <SideWrapper>
              <KeyValueComponent
                name={t('attributes.customer.contact_email')}
                value={details?.email || '-'}
                isCapitalize={false}
              />
              <KeyValueComponent
                name={t('attributes.technician.contact_address')}
                value={details?.address || '-'}
              />
            </SideWrapper>
          </Box>
          <Divider />
        </Box>
        <Box>
          <StyledHeader>
            <Typography variant="body1">
              {t('attributes.billingCard.accountingIntegration')}
            </Typography>
          </StyledHeader>
          <Divider />
          {!details?.account_partner_uuid && (
            <Box sx={{ padding: '16px' }}>
              <Typography variant="body2">
                {Validation.billingCard.info}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  text={t('attributes.create')}
                  sx={{ height: '52%' }}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          )}
          {details?.account_partner_uuid && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <SideWrapper>
                  <KeyValueComponent
                    name={t('attributes.name')}
                    value={details?.billing_name || '-'}
                  />
                  <Box sx={{ display: 'flex' }}>
                    <KeyValueComponent
                      name={t('attributes.billingCard.partnerId')}
                      value={details?.account_partner_uuid || '-'}
                      isCapitalize={false}
                    />
                  </Box>
                </SideWrapper>
                <SideWrapper>
                  <KeyValueComponent
                    name={t('attributes.billingCard.postalAddress')}
                    value={details?.address || '-'}
                  />
                </SideWrapper>
              </Box>
              <Box sx={{ padding: '8px', display: 'flex', columnGap: '8px' }}>
                <CustomButton
                  text={t('attributes.billingCard.sync')}
                  color="inherit"
                  sx={{ height: '52%' }}
                  startIcon={<CachedIcon />}
                  onClick={() => getBillData()}
                />
                <CustomButton
                  text={t('attributes.delete')}
                  sx={{
                    color: 'white',
                    backgroundColor: WARNING,
                    ':hover': {
                      backgroundColor: WARNING,
                    },
                  }}
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => setOpenConfirmationModal(true)}
                />
              </Box>
              <Divider />
            </>
          )}
        </Box>
      </Box>
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.billingCard.editBill')}
          onClose={() => {
            hasFormValues ? setOpenEditConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.save')}
              startIcon={<SaveIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
          width="500px"
        >
          <BillingForm
            id={params?.uuid}
            watch={watch}
            trigger={trigger}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
            stateVal={details?.state?.value}
            reset={reset}
          />
        </SwipeableDrawer>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('attributes.billingCard.deleteTitle')}
          description={t('attributes.billingCard.deleteDescription')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={onDelete}
        />
      )}
      {openEditConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openEditConfirmationModal}
          setOpen={setOpenEditConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenEditConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default ViewBillingCard;
