export const ROUTENAME = {
  DEFAULT_ROUTE: '/',
  DEEP_LINKING_ROUTE: '/download-mobile-app',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_SERVICE: '/terms-of-service',
  ABOUT_APP: '/about-app',

  PROFILE: '/profile',

  // Global and Super admin
  DASHBOARD: 'dashboard',

  // Global Admin
  COMPANIES: 'companies',
  SUPER_ADMINS: 'super-admins',
  EMAIL_TEMPLATE: 'email-template',
  NOTIFICATION_TEMPLATE: 'notification-template',
  FORMS: 'forms',
  MONITORING_ALERT: 'monitoring_alert',

  // Super admin
  USERS: 'users',
  CUSTOMERS: 'customers',
  BILLING_CONTRACTS: 'billing-contracts',
  BILLING_CONTRACTS_EDIT: ':uuid',
  SUPER_ADMIN: 'super-admin',
  OFFICE_STAFF: 'office-staff',
  STAFF_MANAGEMENT: 'staff-management',
  TECHNICIAN: 'technician',
  CUSTOMER: 'customer',
  PROPERTIES: 'properties',
  PROPERTIES_DETAILS: ':uuid',
  SCHEDULING: 'scheduling',
  ROUTINE_SERVICES: 'routine-services',
  SERVICE_REQUESTS: 'service-requests',
  SCHEDULE_BOARD: 'schedule-board',
  GLOBAL_ADMIN_INTEGRATIONS: 'integrations',
  SUPER_ADMIN_REPORTING: 'reporting',

  // Work Order Start
  WORK_ORDERS: 'work-orders',
  WORK_ORDERS_VIEW: ':uuid',
  // Work Order End
  QUOTES: 'quotes',
  // Commercial Start
  COMMERCIAL: 'commercial',
  PURCHASE_ORDER: 'purchase-order',
  TIMESHEET: 'timesheet',
  // Billing
  BILLING: 'billing',
  BILLING_DETAIL: ':uuid',

  // Commercial End
  DEFECTS_QUOTES: 'defects-quotes',
  DEFECTS: 'defects',
  QUOTES_SERVICES: 'quotes-services',
  WAREHOUSE: 'warehouse',
  PRODUCTS: 'products',
  ASSETS: 'assets',
  INVOICES: 'invoices',
  INVOICE: 'invoice',
  CONTROL_PANEL: 'control-panel',
  REPORTS: 'reports',
  FIRE_COMPANY: 'fire-company',
  GLOBAL_ADMIN: 'global-admins',
  TEMPLATES: 'templates',
  PURCHASE_ORDERS: 'purchase-orders',
  HELP_AND_SUPPORT: 'help-support',

  // Customer
  MAKE_REQUEST: 'make-request',
  ROUTINE_ACTIVITIES: 'routine-activities',
  REACTIVE_TASK: 'reactive-task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product-catalogue',
  QUOTES_STATUS: ':status',
  INVOICES_STATUS: ':status',
  DEFECT_STATUS: ':status',
  DEFECT_LIST: 'list',
  DATA_MANAGEMENT: 'data-management',
  IMPORT_DATA: 'import-data',
  SAMPLE_TEMPLATES: 'templates',
  REFERENCE_DATA: 'reference-data',
  EXPORT_DATA: 'export-data',
  TBC_DATA: 'tbc-data',

  MASTER: 'master',

  // Office-Staff
  REPORTING: 'reporting',
  INTEGRATIONS: 'integrations',
  XERO_INTEGRATION: 'xero-integration',
  USERS_MANAGEMENT: 'user-management',
  ROUTINE_SERVICE_FREQUENCIES: 'routine-service-frequinces',
  PROFILE_DETAIL: ':role/:uuid',
  SYSTEM_SETTING: 'system-setting',
  BELL_NOTIFICATION: 'bell-notification',
};

// Using at routes.js and Sidebar.jsx
export const AUTH_ROUTES = {
  ADMIN_DASHBOARD: 'admin_dashboard',
  FIRE_COMPANIES: 'fire_companies',
  COMPANY_LIST: 'company_list',
  SUPER_ADMINS: 'super_admins',
  GLOBAL_ADMIN_USERS: 'global_admin_users',
  GLOBAL_ADMINS: 'global_admins',
  TEMPLATES: 'templates',
  EMAIL_TEMPLATE: 'email_template',
  GLOBAL_ADMIN_REPORT: 'global_admin_report',
  QUOTE: 'quote',
  INVOICES: 'invoices',
  PURCHASE_ORDERS: 'purchase_orders',
  FORMS: 'forms',
  GLOBAL_ADMIN_ROUTINE_SERVICES: 'global_admin_routine_services',
  GLOBAL_ADMIN_ASSETS: 'global_admin_assets',
  GLOBAL_ADMIN_INVOICES: 'global_admin_invoices',
  GLOBAL_ADMIN_CONTROL_PANEL: 'global_admin_control_panel',
  GLOBAL_ADMIN_INTEGRATIONS: 'global_admin_integrations',
  MONITORING_ALERT: 'monitoring_alert',
  // Super Admin Customer Start
  CUSTOMERS: 'customers',
  NAME: 'name',
  BILLING_CONTRACTS: 'billing_contracts',
  BILLING_CONTRACTS_EDIT: 'billing_contracts_edit',
  // Super Admin Customer End
  SUPER_ADMIN_PROPERTIES: 'super_admin_properties',
  PROPERTIES_DETAILS: 'properties_details',
  SCHEDULING: 'scheduling',
  SUPER_ADMIN_ROUTINE_SERVICES: 'super_admin_routine_services',
  SERVICE_REQUESTS: 'service_requests',
  SUPER_ADMIN_REPORTING: 'super_admin_reporting',
  // Work Order Start
  WORK_ORDERS: 'work_orders',
  WORK_ORDERS_VIEW: 'work_orders_view',
  // Work Order End
  SCHEDULE_BOARD: 'schedule_board',
  SUPER_ADMIN_USERS: 'super_admin_users',
  SUPER_ADMIN: 'super_admin',
  OFFICE_STAFFS: 'office_staffs',
  STAFF_MANAGEMENT: 'staff_management',
  TECHNICIANS: 'technicians',
  SUPER_ADMIN_QUOTES: 'super_admin_quotes',
  DEFECTS_QUOTES: 'defects_quotes',
  QUOTES_SERVICES: 'quotes_services',
  WAREHOUSE: 'warehouse',
  PRODUCTS: 'products',
  SUPER_ADMIN_ASSETS: 'super_admin_assets',
  SUPER_ADMIN_INVOICES: 'super_admin_invoices',
  SUPER_ADMIN_CONTROL_PANEL: 'super_admin_control_panel',
  SUPER_ADMIN_REPORTS: 'super_admin_reports',
  CUSTOMER_DASHBOARD: 'customer_dashboard',
  CUSTOMER_DASHBOARD_PROPERTIES: 'customer_dashboard_properties',
  CUSTOMER_PROPERTIES: 'customer_properties',
  CUSTOMER_QUOTES: 'customer_quotes',
  CUSTOMER_QUOTES_STATUS: 'customer_quotes_status',
  // Customer Invoice Start
  CUSTOMER_INVOICES: 'customer_invoices',
  CUSTOMER_INVOICE_STATUS: 'customer_invoice_status',

  // Customer Defects Dashboard Start
  CUSTOMER_QUOTES_DEFECTS_STATUS: 'customer_quotes_defects_status',
  // Customer Defects Dashboard End

  // Customer Invoice End
  DEFECTS: 'defects',
  CUSTOMER_REPORTS: 'customer_reports',
  MAKE_A_REQUEST: 'make_a_request',
  CUSTOMER_ASSETS: 'customer_assets',
  ROUTINE_ACTIVITIES: 'routine_activities',
  REACTIVE_TASK: 'reactive_task',
  DOCUMENTS: 'documents',
  SETTINGS: 'settings',
  SUPPLIERS: 'suppliers',
  PRODUCT_CATALOGUE: 'product_catalogue',
  CUSTOMER_DASHBOARD_DEFECT_LIST: 'customer_defect_dashboard',

  // Commercial Start
  SUPER_ADMIN_COMMERCIAL: 'super_admin_commercial',
  SUPER_ADMIN_TIMESHEET: 'super_admin_timesheet',
  SUPER_ADMIN_BILLING_INFO: 'super_admin_billing_info',
  SUPER_ADMIN_BILLING_VIEW: 'super_admin_billing_view',
  SUPER_ADMIN_COMMERCIAL_INVOICES: 'super_admin_commercial_invoices',
  SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER:
    'super_admin_commercial_purchase_order',
  // Commercial End

  DATA_MANAGEMENT: 'data_management',
  IMPORT_DATA: 'import_data',
  SAMPLE_TEMPLATES: 'sample_templates',
  EXPORT_DATA: 'export_data',
  REPORTING: 'reporting',

  MASTER: 'master',

  HELP_AND_SUPPORT: 'help_support',
  PROFILE: 'profile',
  INTEGRATIONS: 'integrations',
  XERO_INTEGRATION: 'xero_integration',
  USERS_MANAGEMENT: 'user_management',
  ROUTINE_SERVICE_FREQUENCIES: 'routine_service_frequencies',
  PROFILE_DETAIL_VIEW: 'profile_detail_view',
  REFERENCE_DATA: 'reference_data',
  TBC_DATA: 'tbc_data',
  SYSTEM_SETTING: 'system_setting',
  BELL_NOTIFICATION: 'bell_notification',
};
