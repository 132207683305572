import * as React from 'react';

import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { FONT_SIZE } from '../../../constants/Constants';

const StackedBarChart = ({
  colors,
  data = [],
  xLabels = [],
  height = 240,
  legendPosition = { vertical: 'top', horizontal: 'left' },
  isLegendBelowToGraph = false,
  isYAxis = true,
  markHeight = 12,
  markWidth = 12,
  ...rest
}) => {
  // Legend Config
  let defaultLegendConfig = {};

  // Legend
  if (!isLegendBelowToGraph) {
    defaultLegendConfig = {
      position: legendPosition,
      labelStyle: {
        fontSize: FONT_SIZE,
        fontWeight: 400,
      },
      itemMarkWidth: markWidth,
      itemMarkHeight: markHeight,
      markGap: 5,
      itemGap: 10,
    };
  } else {
    defaultLegendConfig = { hidden: true };
  }

  return isLegendBelowToGraph ? (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1 }}>
        <BarChart
          series={data}
          colors={colors}
          xAxis={[
            {
              data: xLabels,
              scaleType: 'band',
            },
          ]}
          slotProps={{
            legend: defaultLegendConfig,
          }}
          {...rest}
        />
      </Box>
      {data?.map((d) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${DIVIDER_COLOR}`,
            padding: '8px',
          }}
        >
          <Typography variant="body2">{d.label}</Typography>
          <Box
            sx={{
              height: '20px',
              width: '19px',
              borderRadius: '4px',
              background: d.color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="10px" fontWeight={600} color="secondary">
              {d?.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <BarChart
      series={data}
      colors={colors}
      xAxis={[
        {
          data: xLabels,
          scaleType: 'band',
          categoryGapRatio: 0.6,
          tickPlacement: 'middle',
        },
      ]}
      slotProps={{
        legend: defaultLegendConfig,
      }}
      leftAxis={!isYAxis ? null : { min: 0 }}
      {...rest}
    />
  );
};

export default StackedBarChart;
