import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { FlexEnd, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  CHART_COLOR,
  CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART,
  CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART,
  CUSTOMER_DEFECTS,
  DASHBOARD,
  MONTHS_DROPDOWN,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  getDefectByEquipementType,
  getMonthlyDefectChartData,
} from '../../store/defects/api';
import { getCustomerDefectCount } from '../../store/users/customer/api';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import Autocomplete from '../CommonComponents/AutoComplete';
import DashboardCard from '../CommonComponents/DashboardCard';

const ChartWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  background: 'white',
  borderRadius: '4px',
  marginTop: '16px',
});

const CustomAutocomplete = styled(Autocomplete)({
  paddingRight: 0,
  height: '26px',
  '.MuiAutocomplete-inputRoot': {
    paddingRight: '0 !important',
    height: '26px',
    width: '115px',
  },
  '.MuiOutlinedInput-root': { padding: '0 !important' },
  '& .MuiAutocomplete-endAdornment': {
    right: '0 !important',
  },
});

const defaultRangeData = {
  defectTypeByStatus: CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART[0],
  defectType: CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART[0],
  defectFrequency: MONTHS_DROPDOWN[0],
};

const DefectDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CRITICAL, NON_CONFORMANCE, NON_CRITICAL } = CHART_COLOR;

  const colors = [NON_CRITICAL, NON_CONFORMANCE, CRITICAL];

  const [ranges, setRanges] = useState(defaultRangeData);

  const { isLoading, data } = useSelector(
    (state) => state.customer.defectStatusCount
  );

  const defectCard = [
    {
      title: 'Critical',
      status: CUSTOMER_DEFECTS.CRITICAL,
      value: 0,
      click: true,
    },
    {
      title: 'Non-critical',
      status: CUSTOMER_DEFECTS.NON_CRITICAL,
      value: 0,
      click: true,
    },
    {
      title: 'Non-Conformance',
      status: CUSTOMER_DEFECTS.NON_CONFORMANCE,
      value: 0,
      click: true,
    },
  ];

  data &&
    data?.forEach((item) => {
      const card = defectCard.find((card) =>
        card.status.includes(item.severity)
      );

      if (card) {
        card.value = item.count;
      }
    });

  useEffect(() => {
    const req = {
      start_date: moment().subtract(6, 'months'),
      end_date: moment().add(1, 'days'),
    };

    dispatch(getCustomerDefectCount({ ...req }));
  }, []);

  // Vertical Chart APIs
  useEffect(() => {
    dispatch(
      getMonthlyDefectChartData({
        start_date: moment().subtract(ranges.defectFrequency?.value, 'months'),
        end_date: moment(),
      })
    ).then((res) => console.log('---VERTICAL------', res));
  }, []);

  // Horizontal Chart API
  useEffect(() => {
    dispatch(
      getDefectByEquipementType({
        start_date: moment().subtract(ranges.defectFrequency?.value, 'months'),
        end_date: moment(),
      })
    ).then((res) => console.log('---HORIZONTAL------', res));
  }, []);

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replace(' ', '-');
      navigate(
        `/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}/${formattedStatus}`
      );
    } else {
      navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.DEFECTS}`);
    }
  };

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {defectCard?.map((item) => (
            <Grid xs={12} sm={6} md={4} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                onClick={() => handleCardClick(item.title)}
                isLoading={isLoading}
              />
            </Grid>
          ))}
        </Grid>
        <ChartWrapper>
          <SpaceBetween
            sx={{
              width: '100%',
              padding: '8px',
              borderBottom: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">{'Defects'}</Typography>
            <FlexEnd style={{ columnGap: '8px' }}>
              <CustomAutocomplete
                options={CUSTOMER_DASHBOARD_DEFECT_VERTICAL_CHART}
                value={ranges?.defectTypeByStatus}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectTypeByStatus: newVal }))
                }
                disableClearable
              />
              <CustomAutocomplete
                options={MONTHS_DROPDOWN}
                value={ranges?.defectFrequency}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectFrequency: newVal }))
                }
                disableClearable
              />
            </FlexEnd>
          </SpaceBetween>
          <Box sx={{ width: '100%', position: 'relative', height: '300px' }}>
            <StackedBarChart
              colors={colors}
              data={[]}
              xLabels={['Jan', 'Feb']}
            />
          </Box>
        </ChartWrapper>
        <ChartWrapper>
          <SpaceBetween
            sx={{
              width: '100%',
              padding: '8px',
              borderBottom: `1px solid ${DIVIDER_COLOR}`,
            }}
          >
            <Typography variant="body1">{'Defects'}</Typography>
            <FlexEnd style={{ columnGap: '8px' }}>
              <CustomAutocomplete
                options={CUSTOMER_DASHBOARD_DEFECT_HORIZONTAL_CHART}
                value={ranges?.defectType}
                onChange={(e, newVal) =>
                  setRanges((prev) => ({ ...prev, defectType: newVal }))
                }
                disableClearable
              />
            </FlexEnd>
          </SpaceBetween>
          <Box sx={{ width: '100%', position: 'relative', height: '300px' }}>
            <StackedBarChart
              colors={colors}
              data={[]}
              xLabels={['Jan', 'Feb']}
              layout="horizontal"
            />
          </Box>
        </ChartWrapper>
      </Box>
    </Box>
  );
};

export default DefectDashboard;
