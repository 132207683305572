import { Box, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../../constants/Roles';
import { getUserRole } from '../../hoc/Permission';
import GlobalAdminDashboard from './GlobalAdminDashboard';
import SuperAdminDashboard from './SuperAdminDashboard';
const CardBox = ({ color, icon, title, count }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      bgcolor: 'white',
      borderRadius: '8px',
      padding: '16px',
      height: '100%',
    }}
  >
    <Box
      sx={{
        height: '64px',
        width: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: color,
        borderRadius: '8px',
      }}
    >
      {icon}
    </Box>
    <Box sx={{ marginLeft: '16px' }}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body3">{count}</Typography>
    </Box>
  </Box>
);

const Dashboard = () => {
  const userRole = getUserRole();

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR, height: '100%' }}>
      {userRole === GLOBAL_ADMIN && <GlobalAdminDashboard />}
      {userRole === SUPER_ADMIN && <SuperAdminDashboard />}
      {userRole === OFFICE_STAFF && <SuperAdminDashboard />}
      {userRole === OFFICE_MANAGEMENT && <SuperAdminDashboard />}
    </Box>
  );
};

export default Dashboard;
