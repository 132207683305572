import { createSlice } from '@reduxjs/toolkit';

import { addAccountPartnerConfiguration } from './api';

const initialData = {
  isLoading: false,
  error: false,
  data: false,
};

const accountConfigurationSlice = createSlice({
  name: 'accountConfiguration',
  initialState: {
    add: { ...initialData },
  },
  reducers: {
    resetAddAccountPartnerConfiguration(state) {
      state.add = { ...initialData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Add account partner configuration
      .addCase(addAccountPartnerConfiguration.pending, (state) => {
        state.add.isLoading = true;
      })
      .addCase(addAccountPartnerConfiguration.fulfilled, (state, action) => {
        state.add.isLoading = false;
        state.add.error = false;
        state.add.data = action.payload;
      })
      .addCase(addAccountPartnerConfiguration.rejected, (state, action) => {
        state.add.isLoading = false;
        state.add.error = action.payload;
      });
  },
});

export const { resetAddAccountPartnerConfiguration } =
  accountConfigurationSlice.actions;

export default accountConfigurationSlice.reducer;
