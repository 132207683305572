import { createSlice } from '@reduxjs/toolkit';

import {
  createAsset,
  createProperty,
  getAssetDetails,
  getAssetStatus,
  getBuildingClassList,
  getBuildingEraList,
  getBuildingStandardList,
  getBuildingTypeList,
  getConstructionClassList,
  getEquipmentTypes,
  getLocalGovernmentAreaList,
  getParentPropertyList,
  getProducts,
  getPropertyAccessProcedureList,
  getPropertyAccessScheduleList,
  getPropertyAccountManagerList,
  getPropertyAssetsList,
  getPropertyBillingCardList,
  getPropertyCustomerList,
  getPropertyList,
  getPropertyStatusList,
  getPropertyTagsList,
  getRoutineList,
  getRoutineStandardFrequencyList,
  updateAsset,
  updateProperty,
  updateRoutine,
} from './api';

const initialObjectData = {
  loading: false,
  data: false,
  error: false,
};

const propertySlice = createSlice({
  name: 'property',
  initialState: {
    propertyList: {
      loading: false,
      error: false,
      data: false,
      totalCount: 0,
      total: 0,
    },
    updatePropertyList: {
      loading: null,
      error: false,
      data: false,
    },
    createPropertyList: {
      loading: null,
      error: false,
      data: false,
    },
    propertyStatusList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyTagList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccessScheduleList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccessProcedureList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingEraList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingClassList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyConstructionClassList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingTypeList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBuildingStandardList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyLocalGovernmentAreaList: {
      loading: false,
      error: false,
      data: false,
    },
    parentPropertyList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyAccountManagerList: { loading: false, error: false, data: false },
    propertyCustomerList: {
      loading: false,
      error: false,
      data: false,
    },
    propertyBillingCardList: {
      loading: true,
      error: false,
      data: [],
    },
    assetsList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    routinesList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    routineStandardFrequencyList: {
      loading: true,
      data: [],
      error: false,
      totalCount: 0,
      total: 0,
    },
    equipmentTypesList: initialObjectData,
    assetStatusList: initialObjectData,
    products: initialObjectData,
    createAsset: initialObjectData,
    updatedAsset: initialObjectData,
    updatedRoutine: initialObjectData,
    assetDetails: initialObjectData,
  },
  reducers: {
    resetPropertyList(state) {
      state.propertyList = {
        loading: false,
        error: false,
        data: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetAssetsList(state) {
      state.assetsList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetRoutineList(state) {
      state.routinesList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },
    resetRoutineStandardFrequencyList(state) {
      state.routineStandardFrequencyList = {
        loading: true,
        data: [],
        error: false,
        totalCount: 0,
        total: 0,
      };
    },

    resetUpdatePropertyList(state) {
      state.updatePropertyList = { ...initialObjectData };
    },
    resetCreatePropertyList(state) {
      state.createPropertyList = { ...initialObjectData };
    },

    resetUpdateAsset(state) {
      state.updatedAsset = { ...initialObjectData };
    },
    resetAssetDetails(state) {
      state.assetDetails = { ...initialObjectData };
    },
    resetcreateAsset(state) {
      state.createAsset = { ...initialObjectData };
    },

    resetUpdateRoutine(state) {
      state.updatedRoutine = { ...initialObjectData };
    },
    resetPropertyBillingCardList(state) {
      state.propertyBillingCardList = {
        loading: true,
        error: false,
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Property List
      .addCase(getPropertyList.pending, (state) => {
        state.propertyList.loading = true;
      })
      .addCase(getPropertyList.fulfilled, (state, action) => {
        state.propertyList.loading = false;
        state.propertyList.data = action.payload;
        state.propertyList.totalCount = action.payload.total_records;
        state.propertyList.total = action.payload.total;
      })
      .addCase(getPropertyList.rejected, (state, action) => {
        state.propertyList.loading = false;
        state.propertyList.error = action.error.message;
      })

      // Update Property
      .addCase(updateProperty.pending, (state) => {
        state.updatePropertyList.loading = true;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.updatePropertyList.loading = false;
        state.updatePropertyList.error = false;
        state.updatePropertyList.data = action.payload;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.updatePropertyList.loading = false;
        state.updatePropertyList.error = action.payload;
      })

      // Create Property
      .addCase(createProperty.pending, (state) => {
        state.createPropertyList.loading = true;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        state.createPropertyList.loading = false;
        state.createPropertyList.error = false;
        state.createPropertyList.data = action.payload;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.createPropertyList.loading = false;
        state.createPropertyList.error = action.payload;
      })
      // get property status list
      .addCase(getPropertyStatusList.pending, (state) => {
        state.propertyStatusList.loading = true;
      })
      .addCase(getPropertyStatusList.fulfilled, (state, action) => {
        state.propertyStatusList.loading = false;
        state.propertyStatusList.data = action.payload;
      })
      .addCase(getPropertyStatusList.rejected, (state, action) => {
        state.propertyStatusList.loading = false;
        state.propertyStatusList.error = action.error.message;
      })
      // get property tag list
      .addCase(getPropertyTagsList.pending, (state) => {
        state.propertyTagList.loading = true;
      })
      .addCase(getPropertyTagsList.fulfilled, (state, action) => {
        state.propertyTagList.loading = false;
        state.propertyTagList.data = action.payload;
      })
      .addCase(getPropertyTagsList.rejected, (state, action) => {
        state.propertyTagList.loading = false;
        state.propertyTagList.error = action.error.message;
      })
      // Property access schedule list
      .addCase(getPropertyAccessScheduleList.pending, (state) => {
        state.propertyAccessScheduleList.loading = true;
      })
      .addCase(getPropertyAccessScheduleList.fulfilled, (state, action) => {
        state.propertyAccessScheduleList.loading = false;
        state.propertyAccessScheduleList.data = action.payload;
      })
      .addCase(getPropertyAccessScheduleList.rejected, (state, action) => {
        state.propertyAccessScheduleList.loading = false;
        state.propertyAccessScheduleList.error = action.error.message;
      })
      // Property access procedure list
      .addCase(getPropertyAccessProcedureList.pending, (state) => {
        state.propertyAccessProcedureList.loading = true;
      })
      .addCase(getPropertyAccessProcedureList.fulfilled, (state, action) => {
        state.propertyAccessProcedureList.loading = false;
        state.propertyAccessProcedureList.data = action.payload;
      })
      .addCase(getPropertyAccessProcedureList.rejected, (state, action) => {
        state.propertyAccessProcedureList.loading = false;
        state.propertyAccessProcedureList.error = action.error.message;
      })
      // Building era list
      .addCase(getBuildingEraList.pending, (state) => {
        state.propertyBuildingEraList.loading = true;
      })
      .addCase(getBuildingEraList.fulfilled, (state, action) => {
        state.propertyBuildingEraList.loading = false;
        state.propertyBuildingEraList.data = action.payload;
      })
      .addCase(getBuildingEraList.rejected, (state, action) => {
        state.propertyBuildingEraList.loading = false;
        state.propertyBuildingEraList.error = action.error.message;
      })
      // Building class list
      .addCase(getBuildingClassList.pending, (state) => {
        state.propertyBuildingClassList.loading = true;
      })
      .addCase(getBuildingClassList.fulfilled, (state, action) => {
        state.propertyBuildingClassList.loading = false;
        state.propertyBuildingClassList.data = action.payload;
      })
      .addCase(getBuildingClassList.rejected, (state, action) => {
        state.propertyBuildingClassList.loading = false;
        state.propertyBuildingClassList.error = action.error.message;
      })
      // Construction class list
      .addCase(getConstructionClassList.pending, (state) => {
        state.propertyConstructionClassList.loading = true;
      })
      .addCase(getConstructionClassList.fulfilled, (state, action) => {
        state.propertyConstructionClassList.loading = false;
        state.propertyConstructionClassList.data = action.payload;
      })
      .addCase(getConstructionClassList.rejected, (state, action) => {
        state.propertyConstructionClassList.loading = false;
        state.propertyConstructionClassList.error = action.error.message;
      })

      // Building type list
      .addCase(getBuildingTypeList.pending, (state) => {
        state.propertyBuildingTypeList.loading = true;
      })
      .addCase(getBuildingTypeList.fulfilled, (state, action) => {
        state.propertyBuildingTypeList.loading = false;
        state.propertyBuildingTypeList.data = action.payload;
      })
      .addCase(getBuildingTypeList.rejected, (state, action) => {
        state.propertyBuildingTypeList.loading = false;
        state.propertyBuildingTypeList.error = action.error.message;
      })

      // Building standard list
      .addCase(getBuildingStandardList.pending, (state) => {
        state.propertyBuildingStandardList.loading = true;
      })
      .addCase(getBuildingStandardList.fulfilled, (state, action) => {
        state.propertyBuildingStandardList.loading = false;
        state.propertyBuildingStandardList.data = action.payload;
      })
      .addCase(getBuildingStandardList.rejected, (state, action) => {
        state.propertyBuildingStandardList.loading = false;
        state.propertyBuildingStandardList.error = action.error.message;
      })

      // Local government area list
      .addCase(getLocalGovernmentAreaList.pending, (state) => {
        state.propertyLocalGovernmentAreaList.loading = true;
      })
      .addCase(getLocalGovernmentAreaList.fulfilled, (state, action) => {
        state.propertyLocalGovernmentAreaList.loading = false;
        state.propertyLocalGovernmentAreaList.data = action.payload;
      })
      .addCase(getLocalGovernmentAreaList.rejected, (state, action) => {
        state.propertyLocalGovernmentAreaList.loading = false;
        state.propertyLocalGovernmentAreaList.error = action.error.message;
      })

      // Parent property list
      .addCase(getParentPropertyList.pending, (state) => {
        state.parentPropertyList.loading = true;
      })
      .addCase(getParentPropertyList.fulfilled, (state, action) => {
        state.parentPropertyList.loading = false;
        state.parentPropertyList.data = action.payload;
      })
      .addCase(getParentPropertyList.rejected, (state, action) => {
        state.parentPropertyList.loading = false;
        state.parentPropertyList.error = action.error.message;
      })

      // Property account manager list
      .addCase(getPropertyAccountManagerList.pending, (state) => {
        state.propertyAccountManagerList.loading = true;
      })
      .addCase(getPropertyAccountManagerList.fulfilled, (state, action) => {
        state.propertyAccountManagerList.loading = false;
        state.propertyAccountManagerList.data = action.payload;
      })
      .addCase(getPropertyAccountManagerList.rejected, (state, action) => {
        state.propertyAccountManagerList.loading = false;
        state.propertyAccountManagerList.error = action.error.message;
      })

      // Property customer list
      .addCase(getPropertyCustomerList.pending, (state) => {
        state.propertyCustomerList.loading = true;
      })
      .addCase(getPropertyCustomerList.fulfilled, (state, action) => {
        state.propertyCustomerList.loading = false;
        state.propertyCustomerList.data = action.payload;
      })
      .addCase(getPropertyCustomerList.rejected, (state, action) => {
        state.propertyCustomerList.loading = false;
        state.propertyCustomerList.error = action.error.message;
      })

      // Property billing card list
      .addCase(getPropertyBillingCardList.pending, (state) => {
        state.propertyBillingCardList.loading = true;
      })
      .addCase(getPropertyBillingCardList.fulfilled, (state, action) => {
        state.propertyBillingCardList.loading = false;
        state.propertyBillingCardList.data = action.payload;
      })
      .addCase(getPropertyBillingCardList.rejected, (state, action) => {
        state.propertyBillingCardList.loading = false;
        state.propertyBillingCardList.error = action.error.message;
      })

      // Assets List
      .addCase(getPropertyAssetsList.pending, (state) => {
        state.assetsList.loading = true;
      })
      .addCase(getPropertyAssetsList.fulfilled, (state, action) => {
        state.assetsList.loading = false;
        state.assetsList.data = action.payload;
        state.assetsList.totalCount = action.payload.total_records;
        state.assetsList.total = action.payload.total;
      })
      .addCase(getPropertyAssetsList.rejected, (state, action) => {
        state.assetsList.loading = false;
        state.assetsList.error = action.error.message;
      })

      // Routines List
      .addCase(getRoutineList.pending, (state) => {
        state.routinesList.loading = true;
      })
      .addCase(getRoutineList.fulfilled, (state, action) => {
        state.routinesList.loading = false;

        const { data, billingContractDetails } = action.payload || [];

        const routineData = data?.reduce((acc, item) => {
          const routineTypeId = item.routine_types.id;
          const frequencyCode = item.frequencies.code;

          // Check if the routine already exists in the accumulator
          let existingRoutine = acc.find(
            (routine) => routine.routine_types.id === routineTypeId
          );

          if (!existingRoutine) {
            // If routine is not in accumulator, add it with an empty frequencies list
            existingRoutine = { ...item, frequencies_list: [] };
            acc.push(existingRoutine);
          }

          // Add the frequency code if it's unique for this routine
          if (!existingRoutine.frequencies_list.includes(frequencyCode)) {
            existingRoutine.frequencies_list.push(frequencyCode);
          }

          /* This code updates the `contract_valid_till` property of an existing 
          routine with the `contract_end_date` from `billingContractDetails`, 
          but only if `contract_end_date` exists. */
          if (billingContractDetails?.contract_end_date) {
            existingRoutine.contract_valid_till =
              billingContractDetails?.contract_end_date;
          }

          return acc;
        }, []);

        state.routinesList.data = routineData;
        state.routinesList.totalCount = action.payload.total_records;
        state.routinesList.total = action.payload.total;
      })
      .addCase(getRoutineList.rejected, (state, action) => {
        state.routinesList.loading = false;
        state.routinesList.error = action.error.message;
      })

      // Equipement list
      .addCase(getEquipmentTypes.pending, (state) => {
        state.equipmentTypesList.loading = true;
      })
      .addCase(getEquipmentTypes.fulfilled, (state, action) => {
        state.equipmentTypesList.loading = false;
        state.equipmentTypesList.data = action.payload;
      })
      .addCase(getEquipmentTypes.rejected, (state, action) => {
        state.equipmentTypesList.loading = false;
        state.equipmentTypesList.error = action.error.message;
      })

      // Products
      .addCase(getProducts.pending, (state) => {
        state.products.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products.loading = false;
        state.products.data = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.products.loading = false;
        state.products.error = action.error.message;
      })

      // Asser status list
      .addCase(getAssetStatus.pending, (state) => {
        state.assetStatusList.loading = true;
      })
      .addCase(getAssetStatus.fulfilled, (state, action) => {
        state.assetStatusList.loading = false;
        state.assetStatusList.data = action.payload;
      })
      .addCase(getAssetStatus.rejected, (state, action) => {
        state.assetStatusList.loading = false;
        state.assetStatusList.error = action.error.message;
      })

      // Create asset
      .addCase(createAsset.pending, (state) => {
        state.createAsset.loading = true;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.createAsset.loading = false;
        state.createAsset.error = false;
        state.createAsset.data = action.payload;
      })
      .addCase(createAsset.rejected, (state, action) => {
        state.createAsset.loading = false;
        state.createAsset.error = action.payload;
      })

      // update asset
      .addCase(updateAsset.pending, (state) => {
        state.updatedAsset.loading = true;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.updatedAsset.loading = false;
        state.updatedAsset.error = false;
        state.updatedAsset.data = action.payload;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.updatedAsset.loading = false;
        state.updatedAsset.error = action.payload;
      })

      // update routine
      .addCase(updateRoutine.pending, (state) => {
        state.updatedRoutine.loading = true;
      })
      .addCase(updateRoutine.fulfilled, (state, action) => {
        state.updatedRoutine.loading = false;
        state.updatedRoutine.error = false;
        state.updatedRoutine.data = action.payload;
      })
      .addCase(updateRoutine.rejected, (state, action) => {
        state.updatedRoutine.loading = false;
        state.updatedRoutine.error = action.payload;
      })

      // asset details
      .addCase(getAssetDetails.pending, (state) => {
        state.assetDetails.loading = true;
      })
      .addCase(getAssetDetails.fulfilled, (state, action) => {
        state.assetDetails.loading = false;
        state.assetDetails.error = false;
        state.assetDetails.data = action.payload.data[0];
      })
      .addCase(getAssetDetails.rejected, (state, action) => {
        state.assetDetails.loading = false;
        state.assetDetails.error = action.payload;
      })

      // Routine Standard Frequency List
      .addCase(getRoutineStandardFrequencyList.pending, (state) => {
        state.routineStandardFrequencyList.loading = true;
      })
      .addCase(getRoutineStandardFrequencyList.fulfilled, (state, action) => {
        state.routineStandardFrequencyList.loading = false;
        console.log(action.payload.data);
        state.routineStandardFrequencyList.data = action.payload.data?.filter(
          (val) => !val.frequencies
        );
      })
      .addCase(getRoutineStandardFrequencyList.rejected, (state, action) => {
        state.routineStandardFrequencyList.loading = false;
        state.routineStandardFrequencyList.error = action.error.message;
      });
  },
});

export const {
  resetPropertyList,
  resetUpdatePropertyList,
  resetCreatePropertyList,
  resetUpdateAsset,
  resetcreateAsset,
  resetUpdateRoutine,
  resetAssetDetails,
  resetAssetsList,
  resetRoutineList,
  resetPropertyBillingCardList,
  resetRoutineStandardFrequencyList,
} = propertySlice.actions;

export default propertySlice.reducer;
