import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Card, Divider, Grid, styled, Typography } from '@mui/material';

import { Validation } from '../../../constants/FieldValidationMsg';
import CustomTextField from '../../CommonComponents/CustomTextField';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const CustomCard = styled(Card)(({ percentage }) => ({
  boxShadow: 'none',
  marginTop: '16px',
  borderRadius: '4px 4px 0 0',
  height: `calc(100vh - ${percentage === null ? '490px' : '430px'})`,
}));

const BillingContactAmortized = ({
  control,
  setValue,
  trigger,
  clearHandler,
  watchedCPIIncreaseValue,
  isEdit,
}) => {
  const { t } = useTranslation();

  // Selector for billing contract by its ID
  const { data: getBillingContractByIdData } = useSelector(
    (state) => state.billingContract.getBillingContractById
  );

  return (
    <CustomCard
      percentage={isEdit ? null : watchedCPIIncreaseValue?.percentage}
    >
      <Box sx={{ padding: '16px' }}>
        <Typography variant="body1">{`${t('attributes.billingContract.pricing')} ${t('common.specifications')}`}</Typography>
      </Box>
      <Divider />
      <Typography variant="body1" sx={{ padding: '16px 0 0 16px' }}>
        {`${t('attributes.billingContract.lineItem')}: ${t('attributes.billingContract.annualContractValue')}`}
      </Typography>
      <FormFieldWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="annual_cost"
              control={control}
              rules={{
                required: `${t('attributes.billingContract.annualCostAUD')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  type="number"
                  isRequired={true}
                  label={t('attributes.billingContract.annualCostAUD')}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    if (e?.target?.value >= 0 || e?.target?.value === '') {
                      onChange(e);
                      trigger('annual_cost');
                      setValue(
                        'monthly_cost',
                        ((e?.target?.value || 0) / 12).toFixed(2),
                        {
                          shouldValidate: false,
                        }
                      );
                    }
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearHandler(name)}
                  disabled={isEdit && !getBillingContractByIdData?.is_active}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="monthly_cost"
              control={control}
              render={({ field: { value } }) => (
                <CustomTextField
                  isRequired={false}
                  disabled={true}
                  label={t('attributes.billingContract.monthlyCostAUD')}
                  fullWidth
                  value={value}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormFieldWrapper>
    </CustomCard>
  );
};

export default BillingContactAmortized;
