import { createSlice } from '@reduxjs/toolkit';

import {
  createRoutineServiceFrequencies,
  getRoutineServiceFrequenciesById,
  getRoutineServiceFrequenciesList,
  updateRoutineServiceFrequencies,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  createdRoutineServiceFrequencies: false,
};

const initialEditData = {
  isLoading: false,
  error: false,
  editRoutineServiceFrequencies: false,
};

const initialGetData = {
  isLoading: true,
  error: null,
  data: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const routineServiceFrequenciesSlice = createSlice({
  name: 'routineServiceFrequencies',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
  },
  reducers: {
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Super admin List
      .addCase(getRoutineServiceFrequenciesList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getRoutineServiceFrequenciesList.fulfilled, (state, action) => {
        const { data, total, total_records } = action.payload;

        state.get.isLoading = false;
        state.get.data = data;
        state.get.total = total;
        state.get.totalCount = total_records;
      })
      .addCase(getRoutineServiceFrequenciesList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create super admin
      .addCase(createRoutineServiceFrequencies.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createRoutineServiceFrequencies.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdRoutineServiceFrequencies = action.payload.data[0];
      })
      .addCase(createRoutineServiceFrequencies.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update super admin
      .addCase(updateRoutineServiceFrequencies.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateRoutineServiceFrequencies.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.createdRoutineServiceFrequencies = action.payload.data[0];
      })
      .addCase(updateRoutineServiceFrequencies.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit
      .addCase(getRoutineServiceFrequenciesById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getRoutineServiceFrequenciesById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.editRoutineServiceFrequencies = action.payload.data[0];
      })
      .addCase(getRoutineServiceFrequenciesById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      });
  },
});

export const { resetCreate, resetEdit, resetPagination } =
  routineServiceFrequenciesSlice.actions;

export default routineServiceFrequenciesSlice.reducer;
