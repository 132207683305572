import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { PROPERTY_DETAIL_STEP } from '../../constants/Constants';
import {
  CUSTOMER,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../../constants/Roles';
import { resetUpdateInvoice } from '../../store/invoices/reducer';
import { resetGetBillingContractById } from '../../store/users/billingContract/reducer';
import ComingSoon from '../CommonComponents/ComingSoon';
import MainWrapper from '../CommonComponents/MainWrapper';
import TabView from '../CommonComponents/TabView';
import BillingContract from '../Users/BillingContracts/BillingContracts';
import Invoices from '../WorkOrder/Invoices/Invoices';
import AssetsList from './AssetsList';
import PropertyDetail from './PropertyDetail';
import RoutineList from './RoutineList';

// Constants for tab map and labels
const TAB_HASH_MAP = {
  0: '',
  1: '#assets',
  2: '#routines',
  3: '#billing-contracts',
  4: '#invoices',
  5: '#defect-history',
  6: '#coming-soon',
};

const PropertyDetails = ({
  propertyId,
  selectedProperty,
  propertyDataloading,
  propertyNumId,
  handleEditPropertyForm,
  isEditable = true,
  handleDefaultStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { profileDetails } = useSelector((state) => state.common);

  // Deriving initial tab index from URL hash
  const initialTabIndex =
    Object.keys(TAB_HASH_MAP).find((key) => hash === TAB_HASH_MAP[key]) || 0;

  const [activeTab, setActiveTab] = useState(initialTabIndex);
  const [nextStepTitle, setNextStepTitle] = useState();

  // Reset steps on change
  const handleNextStep = useCallback(() => {
    setNextStepTitle(undefined);
    dispatch(resetUpdateInvoice());
  }, [dispatch]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    if (nextStepTitle?.title !== PROPERTY_DETAIL_STEP.assetDetails) {
      const newHash = TAB_HASH_MAP[index] || '';

      navigate(`${location.pathname}${newHash}`, { replace: true });
    }
  };

  useEffect(() => {
    if (nextStepTitle?.title !== PROPERTY_DETAIL_STEP.assetDetails) {
      const hashIndex = Object.keys(TAB_HASH_MAP).find(
        (key) => hash === TAB_HASH_MAP[key]
      );

      setActiveTab(hashIndex ? parseInt(hashIndex) : 0);
    } else {
      setActiveTab(0);
    }

    return () => {
      dispatch(resetGetBillingContractById());
    };
  }, [hash, nextStepTitle, dispatch]);

  // Tabs definition
  const tabs = [
    {
      label: t('attributes.property.propertyDetails'),
      component: (
        <PropertyDetail
          selectedProperty={selectedProperty}
          propertyDataloading={propertyDataloading}
          handleEditPropertyForm={handleEditPropertyForm}
          isEditable={isEditable}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label:
        nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
          ? t('attributes.property.asset_details')
          : t('attributes.property.assets'),
      component: (
        <AssetsList
          propertyId={propertyId}
          isEditable={isEditable}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
          setActiveTab={setActiveTab}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: 'Routines',
      component: <RoutineList selectedProperty={selectedProperty} />,
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.billingContract.billingContracts'),
      component: (
        <BillingContract
          propertyId={propertyNumId}
          isFromProperty={true}
          setNextStepTitle={setNextStepTitle}
          setActiveTab={setActiveTab}
          selectedProperty={selectedProperty}
        />
      ),
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.invoice.invoices'),
      component: (
        <Invoices
          propertyId={propertyId}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
          isFromWorkOrder={false}
        />
      ),
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: t('attributes.property.defect_history'),
      component: <ComingSoon height="80vh" />,
      access: [SUPER_ADMIN, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: 'Coming Soon...',
      component: <ComingSoon height="80vh" />,
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
  ];

  const detailsTabs = [
    {
      label:
        nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
          ? t('attributes.property.asset_details')
          : t('attributes.property.assets'),
      component: (
        <AssetsList
          propertyId={propertyId}
          isEditable={isEditable}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
          setActiveTab={setActiveTab}
        />
      ),
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
    {
      label: 'Coming Soon...',
      component: <ComingSoon height="80vh" />,
      access: [SUPER_ADMIN, CUSTOMER, OFFICE_STAFF, OFFICE_MANAGEMENT],
    },
  ];

  // Filter tabs based on the user's role
  const filteredTabs = tabs.filter((tab) =>
    tab.access.includes(profileDetails?.role_name)
  );
  const filteredDetailsTabs = detailsTabs.filter((tab) =>
    tab.access.includes(profileDetails?.role_name)
  );

  const renderTabView = () => (
    <Box sx={{ width: '100%', backgroundColor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', backgroundColor: 'white' }}>
        <TabView
          tabs={
            nextStepTitle?.title === PROPERTY_DETAIL_STEP.assetDetails
              ? filteredDetailsTabs
              : filteredTabs
          }
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          isSubDetails={true}
        />
      </Box>
    </Box>
  );

  const renderBillingContract = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <BillingContract
        propertyId={propertyNumId}
        isFromProperty={true}
        nextStepTitle={nextStepTitle}
        setNextStepTitle={setNextStepTitle}
        setActiveTab={setActiveTab}
        selectedProperty={selectedProperty}
      />
    </Box>
  );

  const renderInvoices = () => (
    <Box sx={{ maxHeight: 'calc(100vh - 200px)' }}>
      <Invoices
        nextStepTitle={nextStepTitle}
        setNextStepTitle={setNextStepTitle}
        isFromWorkOrder={false}
      />
    </Box>
  );

  return (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title="Properties"
          variant="body1"
          isStep={true}
          nextStepTitle={nextStepTitle}
          step={propertyDataloading ? '' : selectedProperty?.pid}
          handleDefaultStep={handleDefaultStep}
          handleNextStep={handleNextStep}
        />
      </Box>

      {nextStepTitle?.title !== PROPERTY_DETAIL_STEP.billingContracts &&
        nextStepTitle?.title !== PROPERTY_DETAIL_STEP.invoice &&
        renderTabView()}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.billingContracts &&
        renderBillingContract()}

      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.invoice &&
        renderInvoices()}
    </>
  );
};

export default PropertyDetails;
